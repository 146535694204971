import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Nav } from 'react-bootstrap';
import ButtonX from 'app/components/buttonx';
import GlobalCrud from "app/crud/global.crud";
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "app/partials/content/Portlet";

import { toValidDate } from "_metronic/utils/utils";

export default function EmployeeAttendanceCalendar(props) {

  const calendarRequestModes = props.myMode ? ['daily','weekly'] : ['daily','weekly', 'monthly'];

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    }
  }));

  const classes = useStyles();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [calendarRequestMode, setCalendarRequestMode] = useState(calendarRequestModes[1]);
  const [calendarRequest, setCalendarRequest] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hours, setHours] = useState([]);
  
  useEffect(() => {
    let hrs = ["00:00"]
    
    for(let h = 0; h <= 46; h++){
      const isEven = (h % 2) !== 1;
      let hr = Math.ceil(h / 2);
      hrs.push(String(hr).padStart(2, '0') + (!isEven ? ":00" : ":30"));
    }

    setHours(hrs);

    updateFromTo(calendarRequestMode, fromDate, toDate);
    setCalendarRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const myApi = new GlobalCrud(props.apiUrl);
    const fetchCalendarData = async () => {
      const result = await myApi.getRecord("attendance/calendar/", { mode : calendarRequestMode, search : {from_date : toValidDate(fromDate, 'MM/dd/yyyy'), to_date : toValidDate(toDate, 'MM/dd/yyyy')} } );
      if(result.status === 200){
        setEmployeeData(result.data);
      }
      setCalendarRequest(false);
      setIsLoading(false);
    };

    if(calendarRequest){
      setIsLoading(true);
      let cs = [fromDate];
      let dt = new Date(fromDate);
      while(dt < toDate){
        cs.push(new Date(dt.setDate(dt.getDate()+1)));
      }
      setColumns(cs);

      // console.log(cs);
      fetchCalendarData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRequest, calendarRequestMode, toDate, fromDate]);
  
  const sendCalendarRequest = (mode) => {
    setCalendarRequestMode(mode);
    setIsLoading(true);
    updateFromTo(mode, fromDate, toDate);
    setCalendarRequest(true);
  }

  const updateFromTo = (mode, prev_Date, next_Date) =>{
    switch(mode){
      case 'weekly': 
        prev_Date = getMonday(prev_Date);
        next_Date = new Date(prev_Date.getFullYear(), prev_Date.getMonth(), prev_Date.getDate() + 6);
        break;
      case 'daily':  
        next_Date = new Date(prev_Date);
        break;
      case 'monthly':
        prev_Date = new Date(prev_Date.getFullYear(), prev_Date.getMonth(), 1);        
        next_Date = new Date(prev_Date.getFullYear(), prev_Date.getMonth() + 1, 0);
        break;
      default:
        break;
    }

    setFromDate(prev_Date);
    setToDate(next_Date);
  }

  const getMonday = (d) =>{
    var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  const updateDates = (right, left) => {
    let add = 0;
    if(right){
      add = 1;
    }else if(left){
      add = -1;
    }

    let prevDate = new Date();

    switch(calendarRequestMode){
      case 'weekly':
        prevDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + (7 * add));        
        break;
      case 'daily':
        prevDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + add);     
        break;
      case 'monthly':
        prevDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + add, 1);        
        break;
      default:
        break;
    }

    updateFromTo(calendarRequestMode, prevDate, toDate);
    
    setCalendarRequest(true);
  }

  const prev = () => {
    setIsLoading(true);
    updateDates(false, true);
  }
  const next = () => {
    setIsLoading(true);
    updateDates(true, false);
  }

  // const { path } = props.match;
  // const { access, baseUrl } = props;
  
  const displayCell = (e, v, dt_str, i) => {
    let cls = ["spanevent", "spanevent-primary"], 
    spankey = e ? e.id : "",
    add_label = false;

    if(dt_str === e.schedule){
      add_label = true;
      // cls.push("spanstart");
    }
    return (
      <TableCell className="blgray" colSpan={e.days} key={"cell-"+e.id+"-"+v.getDate()+"-"+i} style={{ paddingRight: 0, paddingLeft: 0 }}>
        <span className={cls.join(" ")} key={"span-"+spankey+"-"+v.getDate()}>
          {add_label ? e.actual_time : ""}
        </span>
      </TableCell>
    )
  }
      
  console.log(hours);
  return (
    <Portlet fluidHeight={true}>
      <PortletHeader title={(
          <>
            <ButtonX  className='btn btn-xs btn-icon'
                variant='primary'
                title="Prev"
                icon="flaticon2-back"
                onClick={() => prev()}
                disabled={isLoading}
            />
            <label className="m0 mr10 ml10 vmiddle">{ toValidDate(fromDate, 'MMMM dd, yyyy') } - { toValidDate(toDate, 'MMMM dd, yyyy') }</label>
            <ButtonX  className='btn btn-xs btn-icon'
                variant='primary'
                title="Next"
                icon="flaticon2-next"
                onClick={() => next()}
                disabled={isLoading}
            />
          </>
        )} toolbar={(
        <PortletHeaderToolbar>
          
          <Nav variant="pills" defaultActiveKey="/home">
            { calendarRequestModes.map((mode, i)=> (
              <Nav.Item key={i}>
                <ButtonX disabled={isLoading} className={'btn-sm'} variant={calendarRequestMode === mode ? 'dark' : ''} text={mode.charAt(0).toUpperCase() + mode.slice(1)} onClick={() => sendCalendarRequest(mode)}/>
              </Nav.Item>
            ))}
          </Nav>
        </PortletHeaderToolbar>)
      }/>
      <PortletBody fluid={false}>
        <TableContainer component={Paper}>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'small'}
            >
            { employeeData.single ? (
              <TableHead>
                <TableRow>
                  <TableCell key={"head-hour"}>

                  </TableCell>
                  { columns.map(v => {
                    let col = "";
                    if(calendarRequestMode === 'monthly'){
                      col = v.getDate();
                    }else if(calendarRequestMode === 'weekly'){
                      col = v.getDate() + " - " + v.toLocaleDateString('en-Us', { weekday: 'long' });
                    }else{
                      col = toValidDate(v, 'MMMM dd, yyyy');                  
                    }
                    return (
                      <TableCell key={"head"+col}>{col}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ):(
              <TableHead>
                <TableRow>
                  <TableCell key={"head-employee"}>
                    Employee
                  </TableCell>
                  <TableCell key={"head-total"}>
                    Time Worked
                  </TableCell>
                  { columns.map(v => {
                    let col = "";
                    if(calendarRequestMode === 'monthly'){
                      col = v.getDate();
                    }else if(calendarRequestMode === 'weekly'){
                      col = v.getDate() + " - " + v.toLocaleDateString('en-Us', { weekday: 'long' });
                    }else{
                      col = toValidDate(v, 'MMMM dd, yyyy');                  
                    }
                    return (
                      <TableCell key={"head"+col}>{col}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            )}
            { employeeData.single ? (
              <TableBody>
                {hours.map((hour, h) => {
                  const isEven = (h % 2) !== 1;
                  return (
                    <TableRow key={"row-hour-"+h} className="h40">
                      { isEven &&
                        <TableCell rowSpan="2" >{hour}</TableCell>
                      }
                      {
                        columns.map(v => {
                          let dt_str = toValidDate(v, 'MM/dd/yyyy'),
                          day_data = employeeData['data'][dt_str],
                          kd = 0;
                          return (
                            <TableCell key={"span-"+dt_str} className="p0 blgray bb0">
                              <div className="event-hour-container">
                              { day_data &&                 
                                day_data[hour].map( d => {
                                  kd++;
                                  if(d === 0){
                                    return <div className="event-hour-box" key={"span-"+dt_str+kd}></div>
                                  }else{
                                    return <div className="event-hour-box" key={"span-"+dt_str+kd} style={{backgroundColor: d.background, color: d.color}}></div>
                                  }
                                })
                              }
                              </div>
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
            ):(
              <TableBody>
                { Object.keys(employeeData).map(empkey => {
                  const emp_data = employeeData[empkey];
                  return (
                    <React.Fragment key={"row-"+empkey}>
                    {[...Array(emp_data.row)].map((x, i) =>
                      <TableRow key={"row-"+empkey+"-"+i} className="h40">
                        { i === 0 &&
                          <>
                            <TableCell key={"row-name-"+empkey+"-"+i} rowSpan={emp_data.row}>
                              {emp_data.info ? emp_data.info.name : 'N/A'}
                            </TableCell>
                            <TableCell key={"row-total-"+empkey+"-"+i} rowSpan={emp_data.row} className="blgray fw600">
                              {emp_data.info ? emp_data.info.actual : 'N/A'}
                            </TableCell>
                          </>
                        }                      
                        { columns.map(v => {
                          let dt_str = toValidDate(v, 'MM/dd/yyyy');
                          if(emp_data.data[dt_str] !== undefined){
                            let cell_data = emp_data.data[dt_str];
                            if(cell_data[i] !== undefined){
                              if(cell_data[i] !== 0){
                                return displayCell(cell_data[i], v, dt_str, i);
                              }
                            }else{
                              return (<TableCell key={"cell-"+empkey+"-"+v.getDate()+"-"+i} className="blgray"></TableCell>)  
                            }
                          }else{
                            return (<TableCell key={"cell-"+empkey+"-"+v.getDate()+"-"+i} className="blgray"></TableCell>)
                          }
                          return true;
                        })}
                      </TableRow>
                    )}
                    </React.Fragment>
                  )
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </PortletBody>
    </Portlet>
  )
}