import React from "react";
import { Route} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../../components/crudpageX";

export default function SettingsEmployeeStatus(props) {

  const fields = (props) => {
    let fields = [
      [
        [
          { 
            name : 'name', 
            type : 'text', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['name'] ? props.initialValues['name'] : ''
          },
          { 
            name : 'description', 
            type : 'textarea', 
            required : false, 
            size : 12,
            value : props.initialValues && props.initialValues['description'] ? props.initialValues['description'] : ''
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) => {
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;
  return (
    <Route 
      path={path}
      children={<CrudPageX 
        List={list}
        path={path} 
        form_size='6'
        fields={fields}
        title='Employment Status'
        {...access}
        />} 
    />
  )
}