import React from "react";

import { withRouter } from "react-router-dom";

import Listx from "app/components/listx";

import GlobalCrud from "app/crud/global.crud";

class Schedules extends Listx {

  constructor(props) {
    super(props);

    this.refresh_on_update = true;

    this.headings = [
      { id: 'shift', label: 'Shift' },
      { id: 'created', label: 'Date/Time' },
    ];

    this.admin_api = new GlobalCrud("/hr/employee/records");
    this.useModal();
  }

  renderRowData(data){

    return [
      this.renderCell(data.shift_name),
      this.renderCell(data.created),
      this.renderCell(
        <>
          {this.defaultActions(data.id, data, true)}
        </>
      , null, true),
    ];
  }

  renderSubRowData(data){
    return [
      this.renderCell(
        <strong>{ data.day_str }</strong>
      ),
      this.renderCell(
        <>{ data.start }</>
      ),
      this.renderCell(
        <>{ data.duration_str }</>
      ),
    ];
  }

  fields(values){    
    const { selections } = this.props;

    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          { 
            name : 'shift', 
            type : 'select', 
            required : true, 
            size : 12,
            value : values && values['shift'] ? values['shift'] : '',
            selections : selections && selections['shifts'] ? selections['shifts'] : [],
            idField : 'id',
            valueField : 'name',
          },
        ]
      ]
    ];
  }

}

export default withRouter(Schedules);