/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import MenuConfig from "../../_metronic/layout/MenuConfig";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import { accessToProps, setMenu } from "../../_metronic/utils/utils";
//import { getUserByToken } from '../crud/auth.crud'
import WebsocketMiddleWare from '../websocket';

export const Routes = withRouter(({ history }) => {

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { token, currentUser, isAuthorized, userLastLocation, access } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      access : auth.user && auth.user.access ? auth.user.access : {},
      currentUser : auth.user,
      token : auth.authToken,
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );
  
  const [currentMenu, setCurrentMenu] = useState(MenuConfig);

  let new_access = {};
  if(isAuthorized){
    //console.log(access);
    if(Object.keys(access).length > 0){
      Object.keys(access).map( (k, i) => new_access[k] = accessToProps(access[k]));
    }
  }else{
    //destroy menu
    //setCurrentMenu(menuConfig);
  }

  //set menu
  useEffect(() => {
    if(currentMenu){
      let exclude = ['dashboard', 'logout'];
      let newMenu = MenuConfig;

      newMenu.aside.items.map((menu, index) => {
        if(!(exclude.includes(menu.page))){
          newMenu.aside.items[index] = setMenu(MenuConfig, menu, index, new_access);
        }
        return <></>;
      });
      setCurrentMenu(newMenu);
    }

  }, [new_access, currentMenu]);

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={currentMenu}>
      <WebsocketMiddleWare token={token} />
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout menuConfig={currentMenu}>
            <HomePage userLastLocation={userLastLocation} access={new_access} currentUser={currentUser}/>
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
