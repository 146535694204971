/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as CompilingIcon } from "../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg";
import GlobalCrud from 'app/crud/global.crud';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

export default class UserNotifications extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      key: "Events",
      events : [],
      logs : [],
      total : 0,
    }

    this.tick = this.tick.bind(this);
    this.api = new GlobalCrud("account");
  }
  componentDidMount(){
    this.tick();
    this.timer = setInterval(this.tick, 60000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  tick(){
    const api = new GlobalCrud("account");
    api.getRecord("notifications/").then(({ data }) => {
      this.setState({ events : data.list, total : data.total });
    })
    .catch((error) => {
      console.log(error);
    });

    api.getRecord("logs/").then(({ data }) => {
      this.setState({ logs : data.list });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  getHeaderTopBarCssClassList = () => {
    let result = "kt-header__topbar-icon ";
    if (this.props.pulse) {
      result += "kt-pulse kt-pulse--brand ";
    }

    const { iconType } = this.props;
    if (iconType) {
      result += `kt-header__topbar-icon--${iconType}`;
    }

    return result;
  };

  getSvgCssClassList = () => {
    let result = "kt-svg-icon ";
    const { iconType } = this.props;
    if (iconType) {
      result += `kt-svg-icon--${iconType}`;
    }

    return result;
  };

  getHetBackGroundCssClassList = () => {
    let result = "kt-head ";
    if (this.props.skin) {
      result += `kt-head--skin-${this.props.skin} `;
    }

    result += "kt-head--fit-x kt-head--fit-b";
    return result;
  };

  backGroundStyle = () => {
    if (!this.props.bgImage) {
      return "none";
    }

    return "url(" + this.props.bgImage + ")";
  };

  userNotificationsButtonCssClassList = () => {
    let result = "btn ";
    if (this.props.type) {
      result += `btn-${this.props.type} `;
    }

    result += "btn-sm btn-bold btn-font-md";
    return result;
  };

  ulTabsClassList = () => {
    let result = "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ";
    if (this.props.type) {
      result += `nav-tabs-line-${this.props.type} `;
    }

    result += "kt-notification-item-padding-x";
    return result;
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { key, events, logs, total } = this.state;
    const { useSVG, icon, pulse } = this.props;
    return (
      <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-notifications"
        >
          <span className={this.getHeaderTopBarCssClassList()}>
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <span className={this.getSvgCssClassList()}>
                <CompilingIcon />
              </span>
            )}

            <span className="kt-pulse__ring" hidden={!pulse} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className={this.getHetBackGroundCssClassList()}
              style={{ backgroundImage: this.backGroundStyle() }}
            >
              <h3 className="kt-head__title">
                User Notifications&nbsp;
                <span className={this.userNotificationsButtonCssClassList()}>
                  { total } new
                </span>
              </h3>

              <Tab.Container
                defaultActiveKey={key}
                className={this.ulTabsClassList()}
              >
                <Nav
                  className={this.ulTabsClassList()}
                  onSelect={_key => this.setState({ key: _key })}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="Events" className="nav-link show">
                      Events
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Logs" className="nav-link show">
                      Logs
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="Events">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10"
                        style={{ maxHeight: "40vh", position: "relative" }}
                      >
                        { events.length > 0 ? (
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {events.map((v, k) => (
                              <a className="kt-notification__item" key={"event_"+k}>
                                <div className="kt-notification__item-icon">
                                  <i className="flaticon2-pie-chart kt-font-success" />
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    { ((v.message).length > 30) ? (((v.message).substring(0,27)) + '...') : v.message }
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {v.from_username}
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                        ) : (
                          <div
                            className="kt-grid kt-grid--ver"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                              <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                                All caught up!
                                <br />
                                No new event.
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </PerfectScrollbar>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Logs">
                  <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10"
                        style={{ maxHeight: "40vh", position: "relative" }}
                      >
                        { logs.length > 0 ? (
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {logs.map((v, k) => (
                              <a className="kt-notification__item" key={"event_"+k}>
                                <div className="kt-notification__item-icon">
                                  <i className="flaticon2-user kt-font-success" />
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    { ((v.action).length > 30) ? (((v.action).substring(0,27)) + '...') : v.action }
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {v.time}
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                        ) : (
                          <div
                            className="kt-grid kt-grid--ver"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                              <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                                All caught up!
                                <br />
                                No new log.
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </PerfectScrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
