import React, { useState, useEffect } from "react";

import { Switch, Route, withRouter } from "react-router-dom";

import Crud from "app/components/crud";
import GlobalCrud from "app/crud/global.crud";
import List from "./list";
import Create from "./create";

function Records(props){

  const baseUrl = "/payroll/records";
  const listCrud = new GlobalCrud(baseUrl);
  const { path } = props.match;
  const { isAdmin } = props;

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("/payroll");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        const { data, ...selections } = result.data;
        setSelections(selections);
      }
    };

    fetchSelectionData();
  }, []);

  return (
    <Switch>
      <Route 
        path={path + "/create"} exact
        children={
          <Create 
            selections={selections}
            {...props}
          />
        } 
      />
      <Route 
        path={path + "/view/:id"} exact title="View" description="information"
        children={
          <Create 
            selections={selections}
            {...props}
          />
        } 
      />
      <Route 
        path={path + "/update/:id"} exact title="Update" description="refresh"
        children={
          <Create 
            selections={selections}
            {...props}
          />
        } 
      />
      <Route 
        path={path + "/delete/:id"} exact title="Delete" description="remove"
        children={
          <Crud 
          isDelete={true}
          baseUrl={path}
          crudUrl={listCrud} 
          isAdmin={isAdmin}
          />
        } 
      />
      <Route 
        path={path}
        children={
          <List 
            selections={selections}
            {...props}
            path={path}
            crudUrl={listCrud}
            isPayroll={true}
            baseUrl={path}
          />
        } 
      />
    </Switch>
  )
}

export default withRouter(Records);