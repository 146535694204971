import React from "react";
import {
  withRouter,
} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../../components/crudpageX";

const SettingsShiftsBreaks = (props) =>{

  const fields = (props, state, params = null) => {
    let fields = [
      [
        [
          {
            name : 'shift',
            type : 'hidden',
            value : params ? params.id : ''
          },
          { 
            name : 'title', 
            type : 'text', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['title'] ? props.initialValues['title'] : ''
          },
          { 
            name : 'description', 
            type : 'textarea', 
            required : false, 
            size : 12,
            value : props.initialValues && props.initialValues['description'] ? props.initialValues['description'] : ''
          },
          { 
            name : 'start', 
            type : 'time', 
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['start'] ? props.initialValues['start'] : '09:00:00',
            useNow : true,
          },
          { 
            name : 'limit', 
            type : 'inputmask',
            required : true, 
            size : 6,
            mask : '99', 
            value : props.initialValues && props.initialValues['limit'] ? props.initialValues['limit'] : '1',
          },
          { 
            name : 'duration', 
            label : 'Duration (mins)',
            type : 'slider', 
            min : 300,
            step : 300,
            max : 3600,
            unit : 'minutes',
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['duration'] ? props.initialValues['duration'] : 300,
            useNow : true,
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props} backLink="/hr/settings/shifts"/>
  }

  const { url } = props.match;
  const { access } = props;
  
  return (
    <CrudPageX 
    List={list}
    path={url} 
    apiPath={url} 
    form_size='6'
    fields={fields}
    title='Shift Schedule'
    params={props.match.params}
    {...access}
    />
  )
}

export default withRouter(SettingsShiftsBreaks);