import axios from "axios";

let global = require('../../global');
let apiversion = 'v'+global.default.apiversion;
let BASE_URL = "api/"+apiversion;

export default class GlobalCrud{

  BASEURL = '';
  DATA = '';
  REQUEST = '';

  constructor(_api){
    if(!_api.startsWith('/')){
      _api = '/'+_api;
    }
    _api = _api.replace('/mg/', '/');
    this.BASEURL = _api;
    if(this.BASEURL.endsWith('/')){
      this.BASEURL = this.BASEURL.slice(0, -1);
    }
    this.REQUEST = BASE_URL + this.BASEURL + '/';
    this.DATA = this.REQUEST + 'data/';

    this.get = this.get.bind(this);
    this.create = this.create.bind(this);
    this.read = this.read.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.getData = this.getData.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.postRecord = this.postRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  getBaseUrl(){
    return this.BASEURL;
  }

  getRequestUrl(){
    return this.REQUEST;
  }
  
  get(page = 1, search = null, per_page = 20) {
    return axios.get(this.REQUEST, {params : { page : page, search : search, page_size : per_page }});
  }

  create(data) {
    return axios.post(this.REQUEST, data);
  }
  
  read(id) {
    return axios.get(this.REQUEST+id+"/");
  }
  
  update(id, data) {
    return axios.post(this.REQUEST+id, data);
  }
  
  delete(id) {
    return axios.delete(this.REQUEST+id+"/");
  }
  
  getData() {
    return axios.get(this.DATA);
  }

  getRecord(type, params = {}, responseType = "") {
    type = type || "";
    responseType = responseType || "";
    type = String(type);

    if(type.startsWith('/') && this.REQUEST.endsWith('/')){
      type = type.slice(1);
    }

    let config = {params : params};
    if(responseType !== ""){
      config.responseType = responseType;
    }
    return axios.get(this.REQUEST+type+(type === '' || type.endsWith('/') ? '' : '/'), config);
  }
  
  postRecord(type, data) {
    type = String(type);
    if(type.startsWith('/') && this.REQUEST.endsWith('/')){
      type = type.slice(1);
    }
    return axios.post(this.REQUEST+type+(type === '' || type.endsWith('/') ? '' : '/'), data);
  }
  
  deleteRecord(type, id) {
    type = String(type);
    if(type.startsWith('/') && this.REQUEST.endsWith('/')){
      type = type.slice(1);
    }
    return axios.delete(this.REQUEST+type+(type === '' || type.endsWith('/') ? '' : '/')+id+'/');
  }
}