import React from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../components/crudpageX";

export default function AccountNotifications(props) {
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <Switch>
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='6'
        title='Notifications'
        {...access}
        canDelete={false}
        canUpdate={false}
        canCreate={false}
        />} 
      />
    </Switch>
  )
}