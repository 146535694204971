import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../../../../store/ducks/auth.duck";
import { Image, ListGroup, Spinner } from "react-bootstrap";
import {Portlet, PortletHeader, PortletBody } from "../../../../partials/content/Portlet";
import { toAbsoluteUrl } from "../../../../../_metronic/utils/utils";
import GlobalCrud from "../../../../crud/global.crud";

import Personal from "./info/personal";
import Employement from "./info/employement";
import Contacts from "./info/contacts";
import Qualifications from "./info/qualifications";
import Account from "./info/account";
import Timekeeping from "./info/timekeeping";
import Logs from "./info/logs";
import History from "./info/history";
import Notifications from "./info/notifications";
import Dependents from "./info/dependents";
import Salaries from "./info/salaries";

class Info extends React.Component {

  constructor(props) {
    super(props);

    const { url } = this.props.match;

    this.state = {
      page_title : 'Personal Info',
      menu : [
        {'title' : 'Personal', 'link' : url, 'page_title' : 'Personal Information', 'data' : 'user'},
        {'title' : 'Employment', 'link' : url+'/employement', 'page_title' : 'Employment Information', 'data' : 'info'},
        {'title' : 'Salary', 'link' : url+'/salary', 'page_title' : 'Salary Packages', 'data' : 'info'},
        {'title' : 'Emergency Contacts', 'link' : url+'/contacts', 'page_title' : 'Contact Information', 'data' : 'info'},
        {'title' : 'Qualifications', 'link' : url+'/qualifications', 'page_title' : 'Qualifications', 'data' : 'info'},
        {'title' : 'Dependents', 'link' : url+'/dependents', 'page_title' : 'Dependents', 'data' : 'info'},
        {'title' : 'Timekeeping', 'link' : url+'/timekeeping', 'page_title' : 'Timekeeping Records', 'data' : 'info'},
        {'title' : 'Employment History', 'link' : url+'/history', 'page_title' : 'Employment History', 'data' : 'info'},
        {'title' : 'Account Logs', 'link' : url+'/logs', 'page_title' : 'Account Logs', 'data' : 'user'},
        {'title' : 'Notifications', 'link' : url+'/notifications', 'page_title' : 'Notifications', 'data' : 'user'},
        {'title' : 'Account', 'link' : url+'/account', 'page_title' : 'Account Information', 'data' : 'user'},
      ],
      info : null,
      user : null,
      selections : null,
      user_loaded : false,
      user_data_loaded : false,
      employee_loaded : false,
      employee_data_loaded : false,
    }

    const { id } = this.props.match.params;
    this.id = id;
    this.user_id = null;

    this.api_path = props.path;
    this.api = new GlobalCrud(this.api_path);

    this.account_api_path = "/account";
    this.account_api = new GlobalCrud(this.account_api_path);

    this.account_api_record_path = (this.api_path === '/hr/employee/records' ? '' : '/my/profile');
    this.account_api_record_full_path = this.account_api_path + this.account_api_record_path;

    this.api_record_path = (this.id ? '/'+this.id : 'profile');

    this.log_api_path = (this.api_path === '/hr/employee/records' ? '' : '/my/logs');
    this.log_api_full_path = this.account_api_path + this.log_api_path;
    this.log_api = null;

    this.base_api_path = this.api_path + (this.id ? '/'+this.id : '');
    this.history_api = new GlobalCrud(this.base_api_path + '/logs');
    this.salary_api = new GlobalCrud(this.base_api_path + '/salaries');

    this.notification_api_path = (this.api_path === '/hr/employee/records' ? '' : '/my/notifications');
    this.notification_api_full_path = this.account_api_path + this.notification_api_path;
    this.notification_api = null;
  }

  getData(){
    this.account_api.getData().then(({ data }) => {
      this.setState(prevState =>({
        selections : {...prevState.selections, ...data},
        user_data_loaded : true,
      }));
    });
    this.api.getData().then(({ data }) => {
      this.setState(prevState =>({
        selections : {...prevState.selections, ...data},
        employee_data_loaded : true,
      }));
    })
  }

  getInfo(){
    if(this.account_api_record_path !== ''){
      this.account_api.getRecord(this.account_api_record_path).then(({ data }) => {
        this.setState({
          user : data,
          user_loaded : true,
        });
      });
    }

    this.api.getRecord(this.api_record_path).then(({ data }) => {
      this.setState({
        info : data,
        employee_loaded : true,
      });      
      if(this.account_api_record_path === ''){
        this.user_id = data.user;
        this.account_api_record_path = '/profile';
        this.account_api_record_full_path = this.account_api_path + this.account_api_record_path;

        this.log_api_path = '/profile';
        this.log_api_full_path = this.account_api_path + this.log_api_path +'/'+this.user_id + '/logs';

        this.notification_api_path = '/profile';
        this.notification_api_full_path = this.account_api_path + this.notification_api_path +'/'+this.user_id + '/notifications';

        this.account_api.getRecord(this.account_api_record_path+'/'+this.user_id).then(({ data }) => {
          this.setState({
            user : data,
            user_loaded : true,
          });
        });
      }
    });

  }

  componentDidMount(){
    this.getData();
    this.getInfo();
  }
  
  // componentDidUpdate(prevProps, prevState) {
  //   if(prevState.info_loaded !== this.state.info_loaded || prevState.data_loaded !== this.state.data_loaded){
  //     if (this.state.info_loaded && this.state.data_loaded) {
  //       this.setState({ loading : false });
  //     }
  //   }
  // }

  updateParent(state_name, state){
    this.setState({[state_name] : state});
    if(state_name === 'user'){
      if(this.props.user.id === state.id){
        this.props.requestUser();
      }
    }
  }

  render(){ 
    const { access } = this.props;
    const { path } = this.props.match;
    const { pathname } = this.props.location;
    const { info, user, user_loaded, user_data_loaded, employee_loaded, employee_data_loaded } = this.state;

    let employee_loading = true,
    user_loading = true;
    if(user_loaded && user_data_loaded){
      user_loading = false;
      this.log_api = new GlobalCrud(this.log_api_full_path);
      this.notification_api = new GlobalCrud(this.notification_api_full_path);
    }
    if(employee_loaded && employee_data_loaded){
      employee_loading = false;
    }

    const { state } = this.props.location;

    let page_title = this.state.menu[0].page_title;
    if(state && "page_title" in state){
      page_title = state.page_title;
    }

    let default_img = (user && user.gender === 'male' ? 21 : 11);

    return (
      <div className="row">
        <div className="col-md-3">
          <Portlet>
            <PortletHeader title={ user ? <h4  style={{fontSize : '1rem', wordBreak: 'break-word'}} className='kt-portlet__head-title'>{user.email}</h4> : ''}/>
            <PortletBody>
              { !user_loaded ? (
                <div className="text-center">
                  <Spinner animation="border"/>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <div className="text-center">
                      <Image width={150} height={150} src={ user && user.avatar ? user.avatar : toAbsoluteUrl("/media/users/300_"+default_img+".jpg")} roundedCircle fluid/>
                    </div>
                    <div className="kt-separator kt-separator--dashed"></div>
                    <h6>{ user ? (user.fullname ? user.fullname : user.email) : ''}</h6>
                    <p>@{ user ? user.nick_name : ''}</p>
                    <ListGroup defaultActiveKey={this.state.menu ? this.state.menu[0].link : ''}>
                      { this.state.menu.map((menu, index) => {
                        if(!this.state[menu.data]){
                          return <div key={index}></div>;
                        }
                        return (
                          <Link key={index} to={{pathname:menu.link, state : { page_title : menu.page_title} }} className={pathname === menu.link ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}>
                            {menu.title}
                          </Link>
                        )
                      })}
                    </ListGroup>
                  </div>
                </div>
              )}
            </PortletBody>
          </Portlet>
        </div>
        <div className="col-md-9">
          <Switch>
            <Route path={path + "/employement"} exact children={
              <Employement 
              baseUrl={path} 
              selections={this.state.selections} 
              initialValues={info}
              updateParentState="info"
              updateParent={this.updateParent.bind(this)}
              loading={employee_loading}
              page_title={page_title}
              access={this.props.myaccount ? null : access}
              path={this.api_path}
              />
            }/>
            <Route path={path + "/salary"} exact children={
              <Salaries 
                {...access}
                selections={this.state.selections}
                title={page_title}
                crudUrl={this.salary_api}
                path={this.api_path}
              />
            }/>
            <Route path={path + "/contacts"} exact children={
              <Contacts 
              baseUrl={path} 
              selections={this.state.selections} 
              loading={employee_loading}
              page_title={page_title}
              access={access}
              path={this.api_path}
              />
            }/>
            <Route path={path + "/qualifications"} exact children={
              <Qualifications 
              baseUrl={path} 
              selections={this.state.selections} 
              loading={employee_loading}
              page_title={page_title}
              access={access}
              path={this.api_path}
              />
            }/>
            <Route path={path + "/dependents"} exact children={
              <Dependents 
                baseUrl={path} 
                selections={this.state.selections} 
                loading={employee_loading}
                page_title={page_title}
                access={access}
                path={this.api_path}
              />
            }/>
            <Route path={path + "/timekeeping"} exact children={
              <Timekeeping 
              baseUrl={path} 
              page_title={page_title}
              path={this.api_path}
              />
            }/>
            <Route path={path + "/history"} exact children={
              <>
              { user_loading ? (
                <div className="text-center">
                  <Spinner animation="border"/>
                </div>
              ) : (
                <History 
                  baseUrl={path} 
                  page_title={page_title}
                  crudUrl={this.history_api}
                />
              )}
              </>
            }/>
            <Route path={path + "/logs"} exact children={
              <>
              { user_loading ? (
                <div className="text-center">
                  <Spinner animation="border"/>
                </div>
              ) : (
                <Logs 
                baseUrl={path} 
                page_title={page_title}
                crudUrl={this.log_api}
                />
              )}
              </>
            }/>
            <Route path={path + "/notifications"} exact children={<>
              { user_loading ? (
                <div className="text-center">
                  <Spinner animation="border"/>
                </div>
              ) : (
                <Notifications 
                baseUrl={path} 
                crudUrl={this.notification_api}
                page_title={page_title}
                access={access}
                />
              )}
              </>
            }/>
            <Route path={path + "/account"} exact children={
              <Account 
              crudUrl={this.account_api} 
              baseUrl={this.account_api_record_path+(this.user_id ? '/'+this.user_id : '')}
              loading={user_loading}
              page_title={page_title}
              user={user}
              access={access}
              />
            }/>
            <Route path="/" children={
              <Personal 
              baseUrl={this.account_api_record_full_path} 
              selections={this.state.selections} 
              initialValues={user}
              updateParentState="user"
              updateParent={this.updateParent.bind(this)}
              loading={user_loading}
              page_title={page_title}
              access={access}/>
            }/>
          </Switch>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default withRouter(injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(Info)
));
