import React from "react";

import { withRouter } from "react-router-dom";
import Listx from "app/components/listx";
import GlobalCrud from "app/crud/global.crud";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'customer_name', label: 'Customer' },
      { id: 'plan_name', label: 'Plan' },
      { id: 'description', label: 'Description' },
      { id: 'reference', label: 'Reference' },
    ];

    this.api = new GlobalCrud("/todo/projects");

    this.setFilterBar(this.filter);

    this.state = {
      ...this.state,
      action_loading : false,
    }
  }

  filter(props, handlers){

    return [
      [
        [
          { 
            name : 'wildcard', 
            label : 'Type in keyword',
            type : 'text', 
            size : 3,
            value : props.search && props.search['wildcard'] ? props.search['wildcard'] : '',
            onChange : handlers.handleChange
          },
          { 
            name : 'roles', 
            label : 'Roles',
            type : 'select', 
            size : 2,
            selections : props.selections ? props.selections['roles'] : [],
            value : props.search && props.search['roles'] ? props.search['roles'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'gender', 
            label : 'Gender',
            type : 'select', 
            size : 2,
            selections : props.selections ? props.selections['genders'] : [],
            value : props.search && props.search['gender'] ? props.search['gender'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'maritalstatus', 
            label : 'Marital Status',
            type : 'select', 
            size : 2,
            selections : props.selections ? props.selections['maritalstatuses'] : [],
            value : props.search && props.search['maritalstatus'] ? props.search['maritalstatus'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'status', 
            label : 'Status',
            type : 'select', 
            size : 2,
            selections : [{ key : 1, value : 'Active' }, { key : 0, value : 'Inactive' }],
            value : props.search && !isNaN(parseInt(props.search['status'])) ? props.search['status'] : 3,
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            size : 1,
          },
        ],
      ],
    ];
  }

  renderRowData(data){

    return [
      this.renderCell(data.customer_name),
      this.renderCell(data.plan_name),
      this.renderCell(data.reference),
      this.renderCell(data.description),
      this.renderCell(
        <>
          {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);