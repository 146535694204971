import React from "react";
import {
  Link,
} from "react-router-dom";

import { withRouter } from "react-router-dom";

import { Badge } from 'react-bootstrap';
import Chip from '@material-ui/core/Chip';

import Listx from "app/components/listx";
import ButtonX from "app/components/buttonx";
import GlobalCrud from "app/crud/global.crud";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'title', label: 'Title' },
      { id: 'owner', label: 'Owner' },
      { id: 'startdate', label: 'Start' },
      { id: 'duedate', label: 'Due' },
      { id: 'members', label: 'Members' },
      { id: 'progress', label: 'Progress' },
      { id: 'active', label: 'Active' },
      { id: 'approved', label: 'Approved' },
    ];

    this.api = new GlobalCrud("/todo/projects");

    this.setFilterBar(this.filter, this.advance_filter);

    this.state = {
      ...this.state,
      action_loading : false,
    }
  }

  advance_filter(props, handlers){
    return [
      [
        [
          { 
            name : 'startdate', 
            label_start : 'Start Date From',
            label_end : 'To',
            type : 'daterange', 
            size : 6,
            required: false,
            clearable : true,
            value : props.search && props.search['from_startdate'] ? [props.search['from_startdate'], props.search['to_startdate']] : [],
            onChange : handlers.handleDateRangeChange,
          },
          { 
            name : 'duedate', 
            label_start : 'Due Date From',
            label_end : 'To',
            type : 'daterange', 
            size : 6,
            required: false,
            clearable : true,
            value : props.search && props.search['duedate'] ? [props.search['duedate'], props.search['duedate']] : [],
            onChange : handlers.handleDateRangeChange,
          },
        ]
      ]
    ]
  }

  filter(props, handlers){

    return [
      [
        [
          { 
            name : 'wildcard', 
            label : 'Type in keyword',
            type : 'text', 
            size : 3,
            value : props.search && props.search['wildcard'] ? props.search['wildcard'] : '',
            onChange : handlers.handleChange
          },
          { 
            name : 'in_user|members', 
            label : 'User',
            type : 'select', 
            size : 3,
            idField: 'id',
            valueField: 'name',
            ignore: !props.isAdmin,
            selections : props.selections ? props.selections['members'] : [],
            value : props.search && props.search['in_user|members'] ? parseInt(props.search['in_user|members']) : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'active', 
            label : 'Status',
            type : 'select', 
            size : 2,
            selections : [{ key : 1, value : 'Active' }, { key : 0, value : 'Inactive' }],
            value : props.search && !isNaN(parseInt(props.search['active'])) ? parseInt(props.search['active']) : '',
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'approved', 
            label : 'Approval Status',
            type : 'select', 
            size : 2,
            selections : [{ key : 1, value : 'Approved' }, { key : 0, value : 'Pending Approval' }],
            value : props.search && !isNaN(parseInt(props.search['approved'])) ? parseInt(props.search['approved']) : '',
            onChange : handlers.handleSelectChange
          },
          {
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            },
            size: 1,
          },
          { 
            name : 'advance_filter', 
            label : 'Advance',
            type : 'button',
            icon : (props.search && (props.search.show_advance_filter !== undefined && parseInt(props.search.show_advance_filter) !== 0)) ? 'flaticon2-cross' : 'flaticon2-soft-icons',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center"
            },
            onClick : () => handlers.handleAdvanceFilter(),
            size: 1,
          },
        ],
      ],
    ];
  }

  updateStatus(id, mode, bool){
    this.setState({ action_loading : true });
    this.api.postRecord(id+"/update", { field : mode, value : bool }).then(({ data }) => {
      //this.updatePage();
      let newData = [...this.state.data];
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      prevData[mode] = bool;
      //reset
      newData[index] = prevData;
      this.setState({ data : newData});
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      this.setState({ action_loading : false });
    });
  }

  renderRowData(data){
    let owner = false;

    const { access } = data;
    if(access){
      owner = access.owner;
    }

    return [
      this.renderCell(
        <>
        {data.title}
        {data.description ? 
        (<><br/><strong>Note : </strong>{data.description}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(data.owner),
      this.renderCell(data.startdate ? data.startdate : '----'),
      this.renderCell(data.duedate ? data.duedate : '----'),
      this.renderCell(
        <>
        {data.members_list.map( (member, index) => (
          <Chip key={index} color="default" variant="outlined" label={member.name} size="small" style={{margin: 1}}/>
        ))}
        </>
      ),
      this.renderCell(data.totals ? data.totals.progress+'%' : '0%'),
      this.renderCell(<Badge variant={data.active ? 'success' : 'danger'}>{data.active ? 'Active' : 'Inactive'}</Badge>),
      this.renderCell(
        <Badge variant={data.approved ? 'dark' : 'secondary'}>
          {data.approved ? 'Approved' : 'Pending Approval'}
        </Badge>
      ),
      this.renderCell(
        <>
          <span className="pr-2">
            <Link 
            to={{
              pathname : (this.props.baseUrl ? this.props.baseUrl : this.props.crudUrl.getBaseUrl())+'/mg/'+data.id+'/tasks',
              search: '?orderby=duedate&orderdir=asc',
              state : { title : 'Project', description : data.title }
            }}
            className="btn btn-info btn-xs btn-icon" 
            title="View Tasks" >
              <i className="flaticon-list-3"></i>
            </Link>
          </span>
          { owner &&
            <span className="pr-2">
              { data.active ? (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'active', false)} 
                icon="flaticon2-cross" 
                className="btn btn-warning btn-xs btn-icon" 
                title="Deactivate" 
                loading={this.state.action_loading}
                />
              ) : (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'active', true)} 
                icon="flaticon2-checkmark" 
                className="btn btn-success btn-xs btn-icon" 
                title="Activate" 
                loading={this.state.action_loading}
                />
              )}
            </span>
          }
          { owner &&
            <span className="pr-2">
              { data.approved ? (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'approved', false)} 
                icon="fa fa-thumbs-down" 
                className="btn btn-warning btn-xs btn-icon" 
                title="Disapprove" 
                loading={this.state.action_loading}
                />
              ) : (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'approved', true)} 
                icon="fa fa-thumbs-up" 
                className="btn btn-success btn-xs btn-icon" 
                title="Approve" 
                loading={this.state.action_loading}
                />
              )}
            </span>
          }
          {this.defaultActions(data.id, data)}
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);