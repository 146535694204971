import React from "react";
import {
  withRouter
} from "react-router-dom";
import { Spinner, Nav } from "react-bootstrap";

import ButtonX from "app/components/buttonx";
import GlobalCrud from "app/crud/global.crud.js";
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "app/partials/content/Portlet";
import TimesheetStats from "app/widgets/TimesheetStats";

class Timekeeping extends React.Component {

  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    this.id = id;
    this.path_prefix = (this.id ? "/"+this.id+"/" : '');

    let modes = ['daily','weekly', 'monthly', 'yearly']
    this.state = {
      loading : true,
      data : {
        attendance : {
          data : {},
          date_range : [],
        },
        request : {
          count : 0,
        }
      },
      modes : modes,
      mode : modes[0],
    }
    this.crudUrl = new GlobalCrud(props.path);
  }


  componentDidMount(){
    this.fetchTimesheet();
  }

  sendTimesheetRequest(mode){
    this.setState({ mode : mode, loading : true  }, () => { this.fetchTimesheet() })
  }
  fetchTimesheet(){
    this.crudUrl.getRecord(this.path_prefix +"timekeeping/", { mode : this.state.mode }).then(( {data} ) => {
      this.setState({ loading : false, data : data });
    })
    .catch(error => {
      this.setState({ loading : false });
    });
  }

  render(){ 
    const { loading, data } = this.state;
    const { page_title } = this.props;
    return (
      <>
      <Portlet style={{ minHeight: "593px" }}>
        <PortletHeader title={page_title}
        toolbar={
          <PortletHeaderToolbar>
            <Nav variant="pills" defaultActiveKey="/home">
              { this.state.modes.map((mode, i)=> (
                <Nav.Item key={i}>
                  <ButtonX variant={this.state.mode === mode ? 'dark' : ''} text={mode.charAt(0).toUpperCase() + mode.slice(1)} onClick={() => this.sendTimesheetRequest(mode)}/>
                </Nav.Item>
              ))}
            </Nav>
          </PortletHeaderToolbar>}/>
        <PortletBody>
          { loading ? (
            <div className="text-center">
              <Spinner animation="border"/>
            </div>
          ) : (
            <TimesheetStats data={data} mode={this.state.mode}/>
          )}
        </PortletBody>
      </Portlet>
      </>
    )
  }
}

export default withRouter(Timekeeping);