import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../components/crudpageX";
import GlobalCrud from "app/crud/global.crud";

export default function EmployeeReports(props) {

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("hr/employee/attendance");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);
  
  const fields = (props) => {
    let fields = [
      [
        [
          { 
            name : 'employee', 
            type : 'select', 
            required : true, 
            size : 6,
            idField : 'id',
            valueField : 'name',
            value : props.initialValues && props.initialValues['employee'] ? props.initialValues['employee'] : '',
            selections : props.selections && props.selections['employees'] ? props.selections['employees'] : [],
            ignore : !props.isAdmin
          },
          { 
            name : 'request', 
            label : 'Request Type',
            type : 'select', 
            required : true, 
            size : (!props.isAdmin ? 12 : 6),
            idField : 'id',
            valueField : 'name',
            value : props.initialValues && props.initialValues['request'] ? props.initialValues['request'] : '',
            selections : props.selections && props.selections['request_types'] ? props.selections['request_types'] : [],
          },
          { 
            name : 'start', 
            required : true, 
            type : 'date', 
            size : 6,
            value : props.initialValues && props.initialValues['start'] ? props.initialValues['start'] : '',
            useNow : true
          },
          { 
            name : 'end', 
            required : true, 
            type : 'date', 
            size : 6,
            value : props.initialValues && props.initialValues['end'] ? props.initialValues['end'] : '',
            useNow : true
          },
          { 
            name : 'note', 
            type : 'textarea', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['note'] ? props.initialValues['note'] : ''
          },
          { 
            name : 'admin_note', 
            label : 'Admin Note',
            type : 'textarea', 
            size : 12,
            value : props.initialValues && props.initialValues['admin_note'] ? props.initialValues['admin_note'] : '',
            ignore : !props.isAdmin
          },
          { 
            name : 'status', 
            type : 'select', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['status'] ? props.initialValues['status'] : '',
            selections : props.selections && props.selections['request_status'] ? props.selections['request_status'] : [],
            ignore : !props.isAdmin
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) => {
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <>
    { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
          List={list}
          path={path} 
          form_size='6'
          fields={fields}
          title='Reports'
          selections={selections}
          getData={false}
          {...access}
          />} 
      />
    }
    </>
  )
}