import React, { useState, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "app/components/crudpageX";
import GlobalCrud from "app/crud/global.crud";

export default function Plans(props) {

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("/billing/plans");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  const fields = (props) => {
    const { selections } = props;
    let values = props.initialValues;

    let fields = [
      [
        [
          { 
            name : 'product_code', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['product_code'] ? values['product_code'] : [],
            selections : selections && selections['product_codes'] ? selections['product_codes'] : [],
            idField : 'value',
            valueField : 'value',
          },
          { 
            name : 'provider', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['provider'] ? values['provider'] : [],
            selections : selections && selections['providers'] ? selections['providers'] : [],
            idField : 'url',
            valueField : 'name',
          },
          { 
            name : 'name', 
            label : 'Name',
            type : 'text', 
            required : true,
            size : 6,
            value : values && values['name'] ? values['name'] : ''
          },
          { 
            name : 'interval', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['interval'] ? values['interval'] : [],
            selections : selections && selections['intervals'] ? selections['intervals'] : [],
          },
          { 
            name : 'interval_count', 
            label : 'Interval Count',
            type : 'number', 
            required : true,
            size : 6,
            value : values && values['interval_count'] ? values['interval_count'] : ''
          },
          { 
            name : 'amount', 
            label : 'Amount',
            type : 'number', 
            required : true,
            size : 6,
            value : values && values['amount'] ? values['amount'] : ''
          },
          { 
            name : 'trial_period_days', 
            label : 'Trial Period Days',
            type : 'number', 
            size : 6,
            value : values && values['trial_period_days'] ? values['trial_period_days'] : ''
          },
          { 
            name : 'currency', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['currency'] ? values['currency'] : [],
            selections : selections && selections['currencies'] ? selections['currencies'] : [],
          },
          { 
            name : 'generate_after', 
            label : 'Generate After',
            type : 'number', 
            required : true,
            size : 12,
            value : values && values['generate_after'] ? values['generate_after'] : ''
          },
          { 
            name : 'enabled', 
            type : 'checkbox', 
            required : false, 
            size : 4,
            value : values ? values['enabled'] : true
          },
          { 
            name : 'private', 
            type : 'checkbox', 
            required : false, 
            size : 4,
            value : values ? values['private'] : false
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <Switch>
      { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='8'
        fields={fields}
        selections={selections}
        title='User Accounts'
        {...access}
        />} 
      />}
    </Switch>
  )
}