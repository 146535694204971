import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Nav } from 'react-bootstrap';
import { metronic } from "../../../_metronic";
import UserStatus from "../../widgets/UserStatus";
import TodoActivities from "../../widgets/TodoActivities";
import RecentActivities from "../../widgets/RecentActivities";
import TimesheetStats from "../../widgets/TimesheetStats";

import GlobalCrud from "../../crud/global.crud";
import TimesheetQuickStats from "../../widgets/TimesheetQuickStats";
import ButtonX from '../../components/buttonx';

export default function Dashboard() {

  const { primaryColor, warningColor, brandColor } = useSelector(
    state => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      ),
      warningColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.warning"
      )
    })
  );
  
  const timesheetRequestModes = [
    'daily','weekly', 'monthly', 'yearly'
  ];

  const [timesheetRequestMode, setTimesheetRequestMode] = useState(timesheetRequestModes[0]);
  const [timesheetRequest, setTimesheetRequest] = useState(true);
  const [timesheetData, setTimesheetData] = useState();

  useEffect(() => {
    const myApi = new GlobalCrud("/hr/my/account");
    const fetchTimesheetData = async () => {
      const result = await myApi.getRecord("timekeeping/", { mode : timesheetRequestMode});
      if(result.status === 200){
        setTimesheetData(result.data);
      }
      setTimesheetRequest(false);
    };

    if(timesheetRequest){
      fetchTimesheetData();
    }
  }, [timesheetRequest, timesheetRequestMode]);

  const sendTimesheetRequest = (mode) => {
    setTimesheetData(null);
    setTimesheetRequestMode(mode);
    setTimesheetRequest(true);
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <div className="row row-full-height">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <UserStatus />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand" style={{ minHeight: "286px" }}>
                <PortletBody fluid={true}>
                  { (timesheetData && timesheetData.timesheets.data) && 
                    <TimesheetQuickStats 
                      data={timesheetData.timesheets.data}
                      field="actual"
                      title="Total Attendance Hours"
                      color={primaryColor}
                    />
                  }
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />

              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand" style={{ minHeight: "286px" }}>
                <PortletBody fluid={true}>
                  { (timesheetData && timesheetData.timesheets.data) && 
                    <TimesheetQuickStats 
                      data={timesheetData.timesheets.data}
                      field="work"
                      title="Total Work Hours"
                      color={warningColor}
                    />
                  }
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <Portlet fluidHeight={true} style={{ minHeight: "593px" }}>
            <PortletHeader title="Timekeeping Statistics" toolbar={
              <PortletHeaderToolbar>
                <Nav variant="pills" defaultActiveKey="/home">
                  { timesheetRequestModes.map((mode, i)=> (
                    <Nav.Item key={i}>
                      <ButtonX className={'btn-sm'} variant={timesheetRequestMode === mode ? 'dark' : ''} text={mode.charAt(0).toUpperCase() + mode.slice(1)} onClick={() => sendTimesheetRequest(mode)}/>
                    </Nav.Item>
                  ))}
                </Nav>
              </PortletHeaderToolbar>}/>
            <PortletBody>
              { timesheetData && 
                <TimesheetStats data={timesheetData} mode={timesheetRequestMode}/>
              }
            </PortletBody>
          </Portlet>
        </div>
      </div>
      <Portlet>
        <PortletBody fit={true}>
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-4">
              
            </div>
            <div className="col-xl-4">
              
            </div>
            <div className="col-xl-4">
              { (timesheetData && timesheetData.timesheets.data) && 
                <TimesheetQuickStats 
                  data={timesheetData.timesheets.data}
                  field="efficiency"
                  title="Efficiency"
                  color={brandColor}
                  type="bar"
                  suffix="%"
                  conversion="percent"
                  total_override={timesheetData.timesheets.average_efficiency}
                />
              }
            </div>
          </div>
        </PortletBody>
      </Portlet>
      {/* <Portlet>
        <PortletBody fit={true}>
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-4">
              <OrdersWidget />
            </div>
            <div className="col-xl-4">
              <SalesBarChart
                title="Daily Sales"
                desc="Check out each column for more details"
              />
            </div>
            <div className="col-xl-4">
              <SalesBarChart
                title="Revenue Change"
                desc="Revenue change breakdown by cities"
              />
            </div>
          </div>
        </PortletBody>
      </Portlet>

      <div className="row">
        <div className="col-xl-4">
          <DownloadFiles />
        </div>
        <div className="col-xl-4">
          <NewUsers />
        </div>
        <div className="col-xl-4">
          <LatestUpdates />
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-xl-8"></div>
        <div className="col-xl-4">
          <AuthorsProfit />
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-8">
          <TodoActivities />
        </div>
        <div className="col-xl-4">
          <RecentActivities />
        </div>
      </div>
    </>
  );
}
