import React from "react";
import { withRouter, Link } from "react-router-dom";
import Tasks from "../../todo/projects/tasks";
import GlobalCrud from "app/crud/global.crud";

function Todo(props) {

  const { access, currentUser, match } = props;
  let tasks_access = access['todo/projects/tasks'] ? access['todo/projects/tasks'] : null;
  let logs_access = access['todo/projects/tasks/logs'] ? access['todo/projects/tasks/logs'] : null;
  let task_crud = new GlobalCrud('todo/my/tasks');
  
  return (
    <div>
      <Link to={match.path + '?orderby=duedate&orderdir=asc'} className={"btn btn-primary btn-square"} title="Tasks">
        Tasks
      </Link>
      <Link to={"/my/todo/projects"} className={"btn btn-outline-primary btn-square"} title="Projects">
        Projects
      </Link>
      <Tasks 
        myMode={true} 
        currentUser={currentUser} 
        key={props.location.key} 
        {...tasks_access} 
        logs_access={logs_access} 
        crudUrl={task_crud} 
        parent="todo.project.task" 
        title='Tasks'/>
    </div>
  )
}
export default withRouter(Todo);