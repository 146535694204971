import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Nav } from 'react-bootstrap';
import ButtonX from 'app/components/buttonx';
import GlobalCrud from "app/crud/global.crud";
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "app/partials/content/Portlet";

import { toValidDate } from "_metronic/utils/utils";

export default function EmployeeAbsence(props) {

  const calendarRequestModes = [
    'daily','weekly', 'monthly'
  ];

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    }
  }));

  const classes = useStyles();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [calendarRequestMode, setCalendarRequestMode] = useState(calendarRequestModes[1]);
  const [calendarRequest, setCalendarRequest] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const [columns, setColumns] = useState([]);

  const setCalendarData = (calendarData) => {
    let emp_data = {};
    if(calendarData){
      calendarData.data.map(v => {
        if(emp_data[v.employee] === undefined){
          emp_data[v.employee] = {
            'info' : v.employee_info ? v.employee_info : null,
            'data' : {}
          };
        }
        
        if(emp_data[v.employee].data[[v.start]] === undefined){
          emp_data[v.employee].data[[v.start]] = [];          
        }
        emp_data[v.employee].data[[v.start]].push(v);
        if(v.days){
          let newdt = new Date(v.start);
          for(let i = 0; i <= v.days - 1; i++){
            let newdtstr = toValidDate(new Date(newdt.setDate(newdt.getDate() + 1)), 'MM/dd/yyyy');
            if(newdt > toDate){
              break;
            }
            if(emp_data[v.employee].data[[newdtstr]] === undefined){
              emp_data[v.employee].data[[newdtstr]] = [];          
            }
            emp_data[v.employee].data[[newdtstr]].push(v);
          }
        }
        return true;
      })
    }
    
    setEmployeeData(emp_data);
  }

  useEffect(() => {
    updateFromTo(calendarRequestMode, fromDate, toDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const myApi = new GlobalCrud("hr/employee");
    const fetchCalendarData = async () => {
      const result = await myApi.getRecord("requests/", { mode : calendarRequestMode, search : {from_date : toValidDate(fromDate, 'MM/dd/yyyy'), to_date : toValidDate(toDate, 'MM/dd/yyyy')} } );
      if(result.status === 200){
        setCalendarData(result.data);
      }
      setCalendarRequest(false);
    };

    if(calendarRequest){
      let cs = [fromDate];
      let dt = new Date(fromDate);
      while(dt < toDate){
        cs.push(new Date(dt.setDate(dt.getDate()+1)));
      }
      setColumns(cs);

      // console.log(cs);
      fetchCalendarData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRequest, calendarRequestMode, toDate, fromDate]);
  
  const sendCalendarRequest = (mode) => {
    setCalendarData(null);
    setCalendarRequestMode(mode);
    updateFromTo(mode, fromDate, toDate);
    setCalendarRequest(true);
  }

  // const fields = (props) => {
  //   let fields = [
  //     [
  //       [
  //         { 
  //           name : 'employee', 
  //           type : 'select', 
  //           required : true, 
  //           size : 6,
  //           idField : 'id',
  //           valueField : 'name',
  //           value : props.initialValues && props.initialValues['employee'] ? props.initialValues['employee'] : '',
  //           selections : props.selections && props.selections['employees'] ? props.selections['employees'] : [],
  //           ignore : !props.isAdmin
  //         },
  //         { 
  //           name : 'request', 
  //           label : 'Request Type',
  //           type : 'select', 
  //           required : true, 
  //           size : (!props.isAdmin ? 12 : 6),
  //           idField : 'id',
  //           valueField : 'name',
  //           value : props.initialValues && props.initialValues['request'] ? props.initialValues['request'] : '',
  //           selections : props.selections && props.selections['request_types'] ? props.selections['request_types'] : [],
  //         },
  //         { 
  //           name : 'start', 
  //           required : true, 
  //           type : 'date', 
  //           size : 6,
  //           value : props.initialValues && props.initialValues['start'] ? props.initialValues['start'] : '',
  //           useNow : true
  //         },
  //         { 
  //           name : 'end', 
  //           required : true, 
  //           type : 'date', 
  //           size : 6,
  //           value : props.initialValues && props.initialValues['end'] ? props.initialValues['end'] : '',
  //           useNow : true
  //         },
  //         { 
  //           name : 'note', 
  //           type : 'textarea', 
  //           required : true, 
  //           size : 12,
  //           value : props.initialValues && props.initialValues['note'] ? props.initialValues['note'] : ''
  //         },
  //         { 
  //           name : 'admin_note', 
  //           label : 'Admin Note',
  //           type : 'textarea', 
  //           size : 12,
  //           value : props.initialValues && props.initialValues['admin_note'] ? props.initialValues['admin_note'] : '',
  //           ignore : !props.isAdmin
  //         },
  //         { 
  //           name : 'status', 
  //           type : 'select', 
  //           required : true, 
  //           size : 12,
  //           value : props.initialValues && props.initialValues['status'] ? props.initialValues['status'] : '',
  //           selections : props.selections && props.selections['request_status'] ? props.selections['request_status'] : [],
  //           ignore : !props.isAdmin
  //         },
  //       ]
  //     ]
  //   ]
  //   return fields;
  // }

  const updateFromTo = (mode, prev_Date, next_Date) =>{
    switch(mode){
      case 'weekly': 
        prev_Date = getMonday(prev_Date);
        next_Date = new Date(prev_Date.getFullYear(), prev_Date.getMonth(), prev_Date.getDate() + 6);
        break;
      case 'daily':  
        next_Date = new Date(prev_Date);
        break;
      case 'monthly':
        prev_Date = new Date(prev_Date.getFullYear(), prev_Date.getMonth(), 1);        
        next_Date = new Date(prev_Date.getFullYear(), prev_Date.getMonth() + 1, 0);
        break;
      default:
        break;
    }

    setFromDate(prev_Date);
    setToDate(next_Date);
  }

  const getMonday = (d) =>{
    var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  const updateDates = (right, left) => {
    let add = 0;
    if(right){
      add = 1;
    }else if(left){
      add = -1;
    }

    let prevDate = new Date();

    switch(calendarRequestMode){
      case 'weekly':
        prevDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + (7 * add));        
        break;
      case 'daily':
        prevDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + add);     
        break;
      case 'monthly':
        prevDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + add, 1);        
        break;
      default:
        break;
    }

    updateFromTo(calendarRequestMode, prevDate, toDate);
    
    setCalendarRequest(true);
  }

  const prev = () => {
    updateDates(false, true);
  }
  const next = () => {
    updateDates(true, false);
  }

  // const { path } = props.match;
  // const { access, baseUrl } = props;
  
  return (
    <Portlet>
      <PortletHeader title={(
          <>
            <ButtonX  className='btn btn-xs btn-icon'
                variant='primary'
                title="Prev"
                icon="flaticon2-back"
                onClick={() => prev()}
            />
            <label className="m0 mr10 ml10 vmiddle">{ toValidDate(fromDate, 'MMMM dd, yyyy') } - { toValidDate(toDate, 'MMMM dd, yyyy') }</label>
            <ButtonX  className='btn btn-xs btn-icon'
                variant='primary'
                title="Next"
                icon="flaticon2-next"
                onClick={() => next()}
            />
          </>
        )} toolbar={(
        <PortletHeaderToolbar>
          
          <Nav variant="pills" defaultActiveKey="/home">
            { calendarRequestModes.map((mode, i)=> (
              <Nav.Item key={i}>
                <ButtonX className={'btn-sm'} variant={calendarRequestMode === mode ? 'dark' : ''} text={mode.charAt(0).toUpperCase() + mode.slice(1)} onClick={() => sendCalendarRequest(mode)}/>
              </Nav.Item>
            ))}
          </Nav>
        </PortletHeaderToolbar>)
      }/>
      <PortletBody>
        <TableContainer component={Paper}>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'small'}
            >
            <TableHead>
              <TableRow>
                <TableCell key={"head-employee"}>
                  Employee
                </TableCell>
                { columns.map(v => {
                  let col = "";
                  if(calendarRequestMode === 'monthly'){
                    col = v.getDate();
                  }else if(calendarRequestMode === 'weekly'){
                    col = v.getDate() + " - " + v.toLocaleDateString('en-Us', { weekday: 'long' });
                  }else{
                    col = toValidDate(v, 'MMMM dd, yyyy');                  
                  }
                  return (
                    <TableCell key={"head"+col}>{col}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              { Object.keys(employeeData).map(empkey => {
                const emp_data = employeeData[empkey];
                return (
                  <TableRow key={"row-"+empkey}>
                    <TableCell>
                      {emp_data.info ? emp_data.info.name : 'N/A' }
                    </TableCell>
                    { columns.map(v => {
                      let dt_str = toValidDate(v, 'MM/dd/yyyy');
                      if(emp_data.data[dt_str] !== undefined){
                        let cell_data = emp_data.data[dt_str];
                        return (
                          <TableCell key={"cell-"+cell_data.id+"-"+v.getDate()} style={{ paddingRight: 0, paddingLeft: 0 }}>
                            { cell_data.map(e => {
                                let cls = ["spanevent"], 
                                spankey = e ? e.id : "",
                                add_label = false;

                                if(e){
                                  cls.push(e.status);
                                }else{
                                  cls.push("spanfiller");
                                }
                                
                                if(dt_str === e.start){
                                  add_label = true;
                                  cls.push("spanstart");
                                }
                                if(dt_str === e.end){
                                  cls.push("spanend");
                                }
                                return (
                                  <span className={cls.join(" ")} key={"span-"+spankey+"-"+v.getDate()}>
                                    {add_label ? e.request_info ? e.request_info.name : "N/A" : ""}
                                  </span>
                                )
                            })}
                          </TableCell>
                        )
                      }else{
                        return (<TableCell key={"cell-"+empkey+"-"+v.getDate()}></TableCell>)
                      }
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </PortletBody>
    </Portlet>
  )
}