import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Button, Form } from "react-bootstrap";
import {
  useHistory,
} from "react-router-dom";

import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "../../../../../partials/content/Portlet";
import FormX from "../../../../../components/formx";
import GlobalCrud from "../../../../../crud/global.crud.js";
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  icon: {
    fontSize: '2.2em',
    color: '#fff',
    fontWeight: '500',
    "&.MuiStepIcon-active": {
      padding: "3px",
      borderRadius: "50%",
      border: "1px solid ",
      borderColor: theme.palette.primary.main,
      marginY: "-3px"
    },
    "&.MuiStepIcon-active .MuiStepIcon-text": {
      fill: "#fff",
      fontSize: '1rem',
    },
  },
  form: {
    width: '60%',
    margin: 'auto'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  skipButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  connector:{
    top: '12px',
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: lighten(theme.palette.secondary.main, 0.8),
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderWidth: '5px',
  },
}));

function getSteps() {
  return ['User Information', 'Employee Information', 'Position', 'Employment Status', 'Shift Schedule', 'Salary Package', 'Review'];
}

function getStepContent(stepIndex) {
  if(typeof stepIndex !== 'number'){
    stepIndex = parseInt(stepIndex);
  }
  switch (stepIndex) {
    case 1:
      return 'Setup Employee Information';
    case 2:
      return 'Setup Position';
    case 3:
      return 'Setup Employment Status';
    case 4:
      return 'Setup Shift Schedule';
    case 5:
      return 'Set Salary Package';
    case 6:
      return 'Review Data';
    default:
      return 'Setup User Information';
  }
}

export default function Create(props) {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [validated, setValidated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [form, setForm] = React.useState(null);
  const [skipPosition, setSkipPosition] = React.useState(false);
  const [skipEmployement, setSkipEmployement] = React.useState(false);
  const [skipShift, setSkipShift] = React.useState(false);
  const [skipSalary, setSkipSalary] = React.useState(false);
  
  const [infoValues, setInfoValues] = React.useState(null);
  
  const [dataSelections, setDataSelections] = React.useState(null);

  React.useEffect(() => {
    const myApi = new GlobalCrud("/hr/employee/records");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setDataSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  // React.useEffect(() => {
  //   setForm(renderStep(activeStep, false));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeStep]);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setSkip(activeStep - 1, false);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleReset() {
    setSkipPosition(false);
    setSkipEmployement(false);
    setSkipShift(false);
    setSkipSalary(false);
    setActiveStep(0);
  }

  function setSkip(step, bool){
    // console.log(step, bool)
    switch(step){
      case 2:
        setSkipPosition(bool);
        break;
      case 3:
        setSkipEmployement(bool);
        break;
      case 4:
        setSkipShift(bool);
        break;
      case 5:
        setSkipSalary(bool);
        break;
      default:
        break;
    }
  }
  function handleSkip(){
    setSkip(activeStep, true);
    handleNext();
  }

  const connector = (
    <StepConnector
      classes={{
        root: classes.connector,
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  const onEmailChange = (e) => {
    setInfoValues({...infoValues, email : e.target.value});
  }

  const infofields = () => {
    let fields = [
      [
        [
          { 
            name : 'user_first_name', 
            label : 'First Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : infoValues && infoValues['user_first_name'] ? infoValues['user_first_name'] : ''
          },
          { 
            name : 'user_last_name', 
            label : 'Last Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : infoValues && infoValues['user_last_name'] ? infoValues['user_last_name'] : ''
          },
          { 
            name : 'user_nick_name', 
            label : 'Nick Name',
            type : 'text', 
            required : true,
            size : 4,
            value : infoValues && infoValues['user_nick_name'] ? infoValues['user_nick_name'] : ''
          },
          { 
            name : 'user_email', 
            label : 'Email',
            type : 'email', 
            required : true, 
            size : 3,
            onChange : onEmailChange,
            value : infoValues && infoValues['user_email'] ? infoValues['user_email'] : ''
          },
          { 
            name : 'user_gender', 
            label : 'Gender',
            type : 'radio', 
            required : true, 
            size : 3,
            selections : props.selections ? props.selections['genders'] : [],
            value : infoValues && infoValues['user_gender'] ? infoValues['user_gender'] : ''
          },
          { 
            name : 'user_birthdate', 
            label : 'Date of Birth',
            type : 'date', 
            required : true, 
            size : 3,
            value : infoValues && infoValues['user_birthdate'] ? infoValues['user_birthdate'] : ''
          },
          { 
            name : 'user_maritalstatus', 
            label : 'Marital Status',
            type : 'select', 
            required : true, 
            size : 3,
            selections : props.selections ? props.selections['maritalstatuses'] : [],
            value : infoValues && infoValues['user_maritalstatus'] ? infoValues['user_maritalstatus'] : ''
          },
          { 
            name : 'user_address', 
            label : 'Address',
            type : 'text', 
            required : true, 
            size : 12,
            value : infoValues && infoValues['user_address'] ? infoValues['user_address'] : ''
          },
          { 
            name : 'user_city', 
            label : 'City',
            type : 'text', 
            size : 3,
            value : infoValues && infoValues['city'] ? infoValues['city'] : ''
          },
          { 
            name : 'user_state', 
            type : 'text', 
            size : 3,
            value : infoValues && infoValues['state'] ? infoValues['state'] : ''
          },
          { 
            name : 'user_zipcode', 
            label : 'Zipcode',
            type : 'text', 
            size : 3,
            value : infoValues && infoValues['zipcode'] ? infoValues['zipcode'] : ''
          },
          { 
            name : 'user_country', 
            label : 'Country',
            type : 'select', 
            size : 3,
            selections : props.selections ? props.selections['countries'] : [],
            value : infoValues && infoValues['country'] ? infoValues['country'] : ''
          },
          { 
            name : 'user_personal_email', 
            label : 'Personal Email',
            type : 'email', 
            size : 3,
            value : infoValues && infoValues['user_personal_email'] ? infoValues['user_personal_email'] : ''
          },
          { 
            name : 'user_home_number', 
            label : 'Home Telephone',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : infoValues && infoValues['user_home_number'] ? infoValues['user_home_number'] : ''
          },
          { 
            name : 'user_mobile_number', 
            label : 'Mobile',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : infoValues && infoValues['user_mobile_number'] ? infoValues['user_mobile_number'] : ''
          },
          { 
            name : 'user_work_number', 
            label : 'Work Telephone',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : infoValues && infoValues['user_work_number'] ? infoValues['user_work_number'] : ''
          },
          
        ]
      ]
    ]
    return fields;
  }

  const employeefields = () => {
    let fields = [
      [
        [
          { 
            name : 'companyid', 
            label : 'Employee ID',
            type : 'text', 
            required : true, 
            size : 4,
            value : infoValues && infoValues['companyid'] ? infoValues['companyid'] : ''
          },
          { 
            name : 'email', 
            label : 'Employee Email',
            type : 'email', 
            size : 4,
            value : infoValues && infoValues['email'] ? infoValues['email'] : '',
          },
          { 
            name : 'hire_date', 
            label : 'Hire Date',
            type : 'date', 
            required : false,
            useNow: true, 
            size : 4,
            value : infoValues && infoValues['hire_date'] ? infoValues['hire_date'] : ''
          },
        ]
      ]
    ]
    return fields;
  }

  const positionfields = () => {
    let fields = [
      [
        [
          { 
            name : 'position_effective_date', 
            label : 'Effective Date',
            type : 'date', 
            required : true, 
            size : 6,
            value : infoValues && infoValues['position_effective_date'] ? infoValues['position_effective_date'] : ''
          },
          { 
            name : 'position_position', 
            label : 'Position',
            type : 'select', 
            required : true, 
            size : 6,
            value : infoValues && infoValues['position_position'] ? infoValues['position_position'] : '',
            selections : dataSelections && dataSelections['positions'] ? dataSelections['positions'] : [],
            idField : 'id',
            valueField : 'position_title',
          },
          { 
            name : 'position_note', 
            label : 'Note',
            type : 'text', 
            required : false, 
            size : 12,
            value : infoValues && infoValues['position_note'] ? infoValues['position_note'] : ''
          },
        ]
      ]
    ]
    return fields;
  }

  const statusfields = () => {
    let fields = [
      [
        [
          { 
            name : 'status_effective_date', 
            label : 'Effective Date',
            type : 'date', 
            required : true, 
            size : 6,
            value : infoValues && infoValues['position_effective_date'] ? infoValues['position_effective_date'] : ''
          },
          { 
            name : 'status_status', 
            label : 'Status',
            type : 'select', 
            required : true, 
            size : 6,
            value : infoValues && infoValues['status_status'] ? infoValues['status_status'] : '',
            selections : dataSelections && dataSelections['statuses'] ? dataSelections['statuses'] : [],
            idField : 'id',
            valueField : 'name',
          },
          { 
            name : 'status_note', 
            label : 'Note',
            type : 'text', 
            required : false, 
            size : 12,
            value : infoValues && infoValues['status_note'] ? infoValues['status_note'] : ''
          },
        ]
      ]
    ]
    return fields;
  }

  const shiftfields = () => {
    let fields = [
      [
        [
          { 
            name : 'shift_shift', 
            label : 'Status',
            type : 'select', 
            required : true, 
            size : 12,
            value : infoValues && infoValues['shift_shift'] ? infoValues['shift_shift'] : '',
            selections : dataSelections && dataSelections['shifts'] ? dataSelections['shifts'] : [],
            idField : 'id',
            valueField : 'name',
          },
        ]
      ]
    ]
    return fields;
  }

  const salaryfields = () => {
    let fields = [
      [
        [
          { 
            name : 'salary_effective_date', 
            label : 'Effect Date',
            type : 'date', 
            required : true, 
            size : 4,
            value : infoValues && infoValues['salary_effective_date'] ? infoValues['salary_effective_date'] : ''
          },
          { 
            name : 'salary_salary_type', 
            type : 'select', 
            required : true, 
            size : 4,
            value : infoValues && infoValues['salary_salary_type'] ? infoValues['salary_salary_type'] : '',
            selections : dataSelections && dataSelections['salary_types'] ? dataSelections['salary_types'] : [],
          },
          { 
            name : 'salary_salary_frequency', 
            type : 'select', 
            required : true, 
            size : 4,
            value : infoValues && infoValues['salary_salary_frequency'] ? infoValues['salary_salary_frequency'] : '',
            selections : dataSelections && dataSelections['salary_frequencies'] ? dataSelections['salary_frequencies'] : [],
          },
          { 
            name : 'salary_currency', 
            type : 'select', 
            required : true, 
            size : 6,
            value : infoValues && infoValues['salary_currency'] ? infoValues['salary_currency'] : '',
            selections : dataSelections && dataSelections['currencies'] ? dataSelections['currencies'] : [],
          },
          { 
            name : 'salary_amount', 
            type : 'number', 
            required : true,
            size : 6,
            value : infoValues && infoValues['salary_amount'] ? infoValues['salary_amount'] : ''
          },
          { 
            name : 'salary_note', 
            type : 'textarea', 
            size : 12,
            value : infoValues && infoValues['salary_note'] ? infoValues['salary_note'] : ''
          },
        ]
      ]
    ];
    return fields;
  }

  const reviewfields = () => {
    let content = '';
    if(activeStep !== 6){
      return content;
    }
    let inputform = document.getElementById('employee_form');
    if(inputform){
      const data = Array.from(new FormData(inputform))
      let review_data = {}
      const review_keys = {'user_':0, 'position_':2, 'status_':3, 'shift_':4, 'salary_':5};
      data.map((v) => {
        let tmp_key = 1;
        v[1] = (v[1].length === 0) ? '----' : v[1];
        Object.keys(review_keys).some(function(key) {
          if(v[0].startsWith(key)){
            v[0] = v[0].replace(key, '').replace('_', ' ');
            tmp_key = review_keys[key]
            return true;            
          }
          return false; 
        });
        if(skipSalary && tmp_key === 5){
          return true;
        }
        if(review_data[tmp_key] === undefined){
          review_data[tmp_key] = [];
        }
        review_data[tmp_key].push(v);
        return true;
      })

      console.log(review_data);
      content = Object.keys(review_data).map((rev) => {
        return <div key={'review_'+rev}>
          <Form.Row>
            <h1>{getStepContent(rev)}</h1>
          </Form.Row>
          {review_data[rev].map((r, idx) => {
            return <Form.Row key={'review_'+rev+'_'+idx}>
              <label className="col-form-label">
                <strong>{r[0]}</strong> : {r[1]}
              </label>
            </Form.Row>;
          })}
        </div>
      })
    }
    return content;
  }

  const handleSubmit = (event, activeStep) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    setIsLoading(true);
    setSuccess('');
    setError('');

    if(activeStep === steps.length - 1){
      if (form.checkValidity() === false) {  
        //not valid
        setIsLoading(false);
      }else{
        const data = new FormData(form);
        const recordApi = new GlobalCrud('/hr/employee/records');
        recordApi.create(data).then(({ data }) => {
          history.push(props.path);
        }).catch((error) => {
          console.log(error);
        }).then(() => {
          setIsLoading(false);
        });
      }
    }else{
      if(form.checkValidity()){
        setValidated(false);
        handleNext();
      }
    }
  }

  // function renderStep(activeStep, reset){
  //   return (
  //     <>
  //       <div className={activeStep !== 0 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={infofields()} />
  //       </div>
  //       <div className={activeStep !== 1 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={employeefields()} />
  //       </div>
  //       <div className={activeStep !== 2 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={positionfields()} />
  //       </div>
  //       <div className={activeStep !== 3 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={statusfields()} />
  //       </div>
  //       <div className={activeStep !== 4 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={shiftfields()} />
  //       </div>
  //       <div className={activeStep !== 5 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={salaryfields()} />
  //       </div>
  //       <div className={activeStep !== 6 ? 'hidden' : ''}>
  //         <FormX {...props} validated={reset ? false : validated} fields={reviewfields()} />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <Portlet fluidHeight={true}>
      <PortletHeader title={'Create New Employee'} toolbar={(
        <PortletHeaderToolbar>
          <Button
            onClick={()=> history.goBack()}
            variant="outline-dark"
            className='btn-sm'>
            <i className="fa fa-arrow-left" /> Back
          </Button>
        </PortletHeaderToolbar>)}/>
      <PortletBody fluid={true}>
        <div className={classes.root}>
          <Box className='stepperWrapper'>
          <Stepper activeStep={activeStep} connector={connector} alternativeLabel style={{ backgroundColor: "transparent" }}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel 
                  StepIconProps={{ 
                    classes: { root: classes.icon } 
                  }}
                >{label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          </Box>
          <div >
            <Form
                noValidate
                validated={validated}
                onSubmit={e => handleSubmit(e, activeStep)}
                id="employee_form"
                >
              <>
                <div className={activeStep !== 0 ? 'hidden' : ''}>
                  <FormX {...props} validated={validated} fields={infofields()} />
                </div>
                { activeStep >= 1 &&
                  <div className={activeStep !== 1 ? 'hidden' : ''}>
                    <FormX {...props} validated={validated} fields={employeefields()} />
                  </div>
                }
                { activeStep >= 2 && !skipPosition &&
                  <div className={activeStep !== 2 ? 'hidden' : ''}>
                    <FormX {...props} validated={validated} fields={positionfields()} />
                  </div>
                }
                { activeStep >= 3 && !skipEmployement &&
                  <div className={activeStep !== 3 ? 'hidden' : ''}>
                    <FormX {...props} validated={validated} fields={statusfields()} />
                  </div>
                }
                { activeStep >= 4 && !skipShift &&
                  <div className={activeStep !== 4 ? 'hidden' : ''}>
                    <FormX {...props} validated={validated} fields={shiftfields()} />
                  </div>
                }
                { activeStep >= 5 && !skipSalary &&
                  <div className={activeStep !== 5 ? 'hidden' : ''}>
                    <FormX {...props} validated={validated} fields={salaryfields()} />
                  </div>
                }
                { activeStep >= 5 &&
                  <div className={activeStep !== 6 ? 'hidden' : ''}>
                    {reviewfields()}
                  </div>
                }
              </>
              { activeStep === steps.length ? (
                <div style={{textAlign:'center'}}>
                  <Typography className={classes.instructions}>All steps completed</Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div style={{textAlign:'center'}}>
                  <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                      sx={{ width: 300, padding: 1 }}
                    >
                      Back
                    </Button>
                    { activeStep >= 2 && activeStep < 6 &&
                      <Button onClick={handleSkip} variant="default" className={classes.backButton} sx={{ width: 300, padding: 1 }}>
                        Skip
                      </Button>
                    }
                    <Button type='submit' variant="success" sx={{ width: 300 }}>
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
}