import React from "react";
import {
  withRouter,
} from "react-router-dom";
import CrudPageX from "../../../../../../components/crudpageX";
import { connect } from "react-redux";
import * as page from "../../../../../../store/ducks/page.duck";


const EmployeeAttendanceBreaks = (props) => {

  const fields = (props, state, params = null) => {
    let values = props.initialValues;
    let data = state.selections;
    let fields = [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          { 
            name : 'shift_break', 
            label : 'Type', 
            type : 'select', 
            required : true, 
            size : 12,
            idField : 'id',
            valueField : 'title',
            value : values && values['shift_break'] ? values['shift_break'] : '',
            selections : data && data['breaks'] && data['breaks'] ? data['breaks'] : [],
          },
          { 
            name : 'start', 
            type : 'datetime', 
            required : true, 
            size : 6,
            value : values && values['start'] ? values['start'] : '',
            useNow : true,
          },
          { 
            name : 'end', 
            type : 'datetime', 
            required : true, 
            size : 6,
            value : values && values['end'] ? values['end'] : '',
            useNow : true,
          },
          { 
            name : 'start_note', 
            label : 'Start Note',
            type : 'textarea', 
            size : 6,
            value : values && values['start_note'] ? values['start_note'] : ''
          },
          { 
            name : 'end_note', 
            label : 'End Note',
            type : 'textarea', 
            size : 6,
            value : values && values['end_note'] ? values['end_note'] : ''
          },
        ]
      ]
    ]
    return fields;
  }

  const { url } = props.match;

  const { access, baseUrl, path } = props;
  
  let getData = access.isAdmin || access.canCreate || access.canUpdate;
  let apiDataUrl = baseUrl ? url.replace(path, baseUrl) : url;

  if(apiDataUrl.includes('hr/my/timekeeping/attendance')){
    apiDataUrl = apiDataUrl.replace('hr/my/timekeeping/attendance', '/hr/employee/attendance');
  }
  
  return (
    <CrudPageX 
    path={url} 
    baseUrl={path}
    apiPath={apiDataUrl} 
    form_size='6'
    fields={fields}
    title='Breaks'
    params={props.match.params}
    getData={getData}
    successRedirect={url.replace(path, "")+"/update"}
    {...access}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(EmployeeAttendanceBreaks));
