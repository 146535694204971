
import React from "react";
import { Alert } from "react-bootstrap";

export default class AlertX extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true
    };
  }

  componentDidMount(){
    if(this.props.timeout && this.props.timeout > 0){
      this.timerHandle = setTimeout(() => {
        this.setState({ show: false });
        this.timerHandle = 0;
      }, this.props.timeout);
    }
  }
  componentWillUnmount(){
    if(this.timerHandle){
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }
  render() {
    const handleDismiss = () => this.setState({ show: false });
    
    return (
      <>
        { this.props.message.includes("\r\n") ? (
          this.props.message.split("\r\n").map( (item, i) => (
            <Alert key={i} show={this.state.show} variant={this.props.variant} onClose={handleDismiss} dismissible>
              {item}
            </Alert>
          ))
        ) : (
          <Alert show={this.state.show} variant={this.props.variant} onClose={handleDismiss} dismissible>
            {this.props.message}
          </Alert>
        )}
      </>
    );
  }
}
// Set default props
AlertX.defaultProps = {
  timeout : 5000,
  variant : 'success',
  message : '',
};