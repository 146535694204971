
import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { Alert } from "react-bootstrap";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword } from "../../crud/auth.crud";
import queryString from 'query-string';
import { passwordTest } from "_metronic/utils/utils";

class ResetPassword extends Component {
  state = { 
    isRequested: false,
    error : '',
    success : '',
  };
  
  send(){
  }
  
  render() {
    const { intl } = this.props;
    const { isRequested, success, error } = this.state;
    
    return (
      <div className="center-div">
        { (isRequested && success) ? (
          <div className="kt-section text-center">
          { success &&
          <Alert show={true} variant="success">
            {success}
          </Alert>
          }
          <div className="kt-separator kt-separator--dashed">
          </div>
          <Link to="/auth" className="btn btn-primary">
            Go to Login
          </Link>
          </div>
        ):(
          <div className="kt-login__body">
            <div className="kt-login__form">
              <div className="kt-login__title">
                <h3>
                  Reset Password
                </h3>
              </div>
              { error &&
                <>
                { error.map( (e, i) => (
                  <Alert key={i} show={true} variant="danger">
                    {e}
                  </Alert>
                ))}
                </>
              }
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: ""
                }}
                validate={values => {
                  const errors = {};

                  if (!values.password) {
                    errors.password = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                  }

                  if (!values.confirmPassword) {
                    errors.confirmPassword = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                  } else if (values.password !== values.confirmPassword) {
                    errors.confirmPassword =
                      "Password and Confirm Password didn't match.";
                  }else if(values.password === values.confirmPassword){
                    let test = passwordTest(values.password);
                    if(test !== true){
                      errors.password = test;
                      errors.confirmPassword = test;
                    }
                  }

                  return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                  this.setState({ isRequested: false, success : '', error : '' });
                  let params = queryString.parse(this.props.location.search);
                  if(Object.keys(params).length > 0){
                    resetPassword(values.password, params.user_id, params.timestamp, params.signature)
                    .then(( {data} ) => {
                      this.setState({ isRequested: true, success : data.detail });
                    })
                    .catch(error => {
                      let msg = [];
                      if(error.status === 404){
                        msg.push("Error 404");
                      }else{
                        Object.keys(error.data).map( (da, i) => {
                          if(typeof error.data[da] === 'object'){
                            Object.keys(error.data[da]).map((d, i) => msg.push(error.data[da][d].toUpperCase()))
                          }else{
                            error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d));
                          }
                          return <></>;
                        });
                      }
                      this.setState({ isRequested: true, error: msg });
                      setSubmitting(false);
                    });
                  }else{
                    this.setState({ isRequested: true, error: ['Invalid Access'] });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}

                    <div className="form-group mb-0">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Password"
                        className="kt-width-full"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Confirm Password"
                        className="kt-width-full"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                      />
                    </div>

                    <div className="kt-login__actions">

                      <button
                        disabled={isSubmitting}
                        className="btn btn-primary btn-elevate kt-login__btn-primary"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));