import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from 'query-string'

import Listx from "../../../../../../components/listx";
import * as page from "../../../../../../store/ducks/page.duck";

class List extends Listx {

  constructor(props) {
    super(props);

    this.currentPage = queryString.parse(this.props.location.search);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'name', label: 'Status' },
      { id: 'description', label: 'Description', sort : false },
    ];
  }

  renderRowData(data){
    return [
      this.renderCell(data.name),
      this.renderCell(data.description),
      this.renderCell(
        <>
        {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

// Set default props
List.defaultProps = {
  canUpdate: true,
  canDelete: true,
  canCreate : true,
  CustomFilter : null,
};

const mapStateToProps = (state) => {
  return {
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(List));