/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import Dropdown from "react-bootstrap/Dropdown";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as CompilingIcon } from "../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg";
import { ReactComponent as Layout4BlocksIcon } from "../../../_metronic/layout/assets/layout-svg-icons/Layout-4-blocks.svg";
import { Link } from "react-router-dom";
import GlobalCrud from 'app/crud/global.crud';

export default class QuickActionsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total : 0,
    }

    this.tick = this.tick.bind(this);
    this.api = new GlobalCrud("todo");
  }

  componentDidMount(){
    this.tick();
    this.timer = setInterval(this.tick, 60000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  tick(){
    const api = new GlobalCrud("todo");
    api.getRecord("tasks/").then(({ data }) => {
      this.setState({ total : data.total });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    const { total } = this.state;
    const { icon, useSVG, bgImage, iconType, gridNavSkin } = this.props;
    return (
      <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-quick-actions-panel-toggle"
        >
          <span
            className={clsx(`kt-header__topbar-icon`, {
              "kt-header__topbar-icon--warning": iconType === "warning"
            })}
          >
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <span
                className={clsx("kt-svg-icon", {
                  "kt-svg-icon--warning": iconType === "warning"
                })}
              >
                <Layout4BlocksIcon className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg" />
              </span>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <form className="flex-column p-0">
            {/* begin: Head */}
            <div
              className={`kt-head kt-head--skin-${{ gridNavSkin }}`}
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h3 className="kt-head__title text-white">
                User Quick Actions
                <span className="kt-space-15" />
                <Link to="/my/todo" className="btn btn-success btn-sm btn-bold btn-font-md">
                  {total} tasks pending
                </Link>
              </h3>
            </div>
            {/* end: Head */}

            <div className={`kt-grid-nav kt-grid-nav--skin-${gridNavSkin}`}>
              <div className="kt-grid-nav__row">
                <Link to="/hr/my/timekeeping/attendance" className="kt-grid-nav__item">
                  <i className="flaticon-clock-1 font25"></i>
                  <span className="kt-grid-nav__title">Time Keeping</span>
                  <span className="kt-grid-nav__desc">Management</span>
                </Link>

                <Link to="/hr/my/timekeeping/requests" className="kt-grid-nav__item">
                  <i className="flaticon-open-box font25"></i>
                  <span className="kt-grid-nav__title">Requests</span>
                  <span className="kt-grid-nav__desc">Management</span>
                </Link>
              </div>

              <div className="kt-grid-nav__row">
                <Link to="/my/todo/projects" className="kt-grid-nav__item">
                  <i className="flaticon-list font25"></i>
                  <span className="kt-grid-nav__title">Todo</span>
                  <span className="kt-grid-nav__desc">Management</span>
                </Link>

                <a className="kt-grid-nav__item">
                  <div className="d-flex align-items-center flex-column">
                    <span className="kt-grid-nav__icon">
                      <CompilingIcon />
                    </span>
                    <span className="kt-grid-nav__title">Coming</span>
                    <span className="kt-grid-nav__desc">Soon</span>
                  </div>
                </a>
              </div>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
