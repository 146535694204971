import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import EmployeeRecords from "./employee/records";
import EmployeeRequests from "./employee/requests";
import EmployeeAbsence from "./employee/absence";
import EmployeeAttendance from "./employee/attendance";
import SettingsShifts from "./settings/shifts";
import SettingsDepartments from "./settings/departments";
import SettingsEmployeeStatus from "./settings/employee/status";
import SettingsRequests from "./settings/requests";
import EmployeeReports from "./employee/reports";

function Hr(props) {

  const routes = [
  {
    parent : 'hr/employee',
    path : 'hr/employee/records',
    component : (props, access) => <EmployeeRecords {...props} access={access}/>,
  },
  {
    parent : 'hr/employee',
    path : 'hr/employee/attendance',
    component : (props, access) => <EmployeeAttendance {...props} access={access}/>,
  },
  {
    parent : 'hr/employee',
    path : 'hr/employee/requests',
    component : (props, access) => <EmployeeRequests {...props} access={access}/>,
  },
  {
    parent : 'hr/employee',
    path : 'hr/employee/absence',
    component : (props, access) => <EmployeeAbsence {...props} access={access}/>,
  },
  {
    parent : 'hr/employee',
    path : 'hr/employee/reports',
    component : (props, access) => <EmployeeReports {...props} access={access}/>,
  },
  {
    parent : 'hr/settings',
    path : 'hr/settings/shifts',
    component : (props, access) => <SettingsShifts {...props} access={access}/>,
  },
  {
    parent : 'hr/settings',
    path : 'hr/settings/departments',
    component : (props, access) => <SettingsDepartments {...props} access={access}/>,
  },
  {
    parent : 'hr/settings',
    path : 'hr/settings/requests',
    component : (props, access) => <SettingsRequests {...props} access={access}/>,
  },
  {
    parent : 'hr/settings',
    path : 'hr/settings/employee/status',
    component : (props, access) => <SettingsEmployeeStatus {...props} access={access}/>,
  },
  ];

  let first_route = null;
  let redirect_route = null;
  const { pathname } = props.location;

  return (
    <Switch>
      { routes.map( (route, index) => {
        let crud = props.access[route.path];
        if((route.path in props.access) && crud && crud.canRead){
          if(!first_route){
            first_route = '/'+route.path;
          }
          if('/'+(route.parent ? route.parent : route.path) === pathname && !redirect_route){
            redirect_route = '/'+route.path;
          }
          return (
            <Route key={index}
              path={"/"+route.path}
              render={(props) => route.component({...props}, crud)} 
            />
          )
        }
        return <></>;
      })}

      <Redirect to={
        {
          pathname: (redirect_route ? redirect_route : ( first_route ? first_route : "/dashboard")), 
          state: (!redirect_route && !first_route ? { message : 'Invalid Page Access ', message_variant : 'danger' } : {})
        }
      } />
    </Switch>
  );
}
export default withRouter(Hr);