import React from "react";
import { ProgressBar } from "react-bootstrap";
import Chip from '@material-ui/core/Chip';
import AccessTime from '@material-ui/icons/AccessTime';
import Avatar from '@material-ui/core/Avatar';

class TimerX extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      elapsed : 0,
    }

    this.tick = this.tick.bind(this);
  }
  componentDidMount(){
      this.timer = setInterval(this.tick, 1000);
  }

  componentWillUnmount(){
      clearInterval(this.timer);
  }

  tick(){
    this.setState({elapsed: new Date() - new Date(this.props.start)});
  }

  sec2time(timeInSeconds) {
    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
  }


  render(){
    var elapsed = Math.round(this.state.elapsed / 100);
    var seconds = (elapsed / 10);    

    if(this.props.duration){
      var percent = (seconds > 0) ? (seconds / this.props.duration) * 100 : 0;
      return (
        <ProgressBar now={percent} label={`${this.sec2time(seconds)}`} style={{minWidth: 300, display: 'inline-flex', height: 32}}/>
      );
    }

    let label = this.sec2time(seconds);
    if(this.props.label){
      label = this.props.label + ' - ' + label;
    }
    return (
      <Chip 
      variant={this.props.variant}
      size={this.props.size}
      avatar={
        <Avatar>
          <AccessTime />
        </Avatar>
      }
      label={label} 
      color={this.props.color ? this.props.color : 'primary'} 
      style={this.props.apply_default_style ? this.props.apply_default_style : {}}
      onClick={this.props.onClick}
      onDelete={this.props.onDelete} />
    );
  }
};
TimerX.defaultProps = {
  apply_default_style : {fontSize:14, minWidth: 110},
  onClick: null,
  onDelete: null,
  label: null,
};

export default TimerX;