import React from "react";

import { Form, Col, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { 
  ButtonGroup, TextField, Checkbox, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel,
  Select, MenuItem, Input, Chip, Avatar, InputLabel, Slider, Typography, FormHelperText
 } from '@material-ui/core';
import {
  TimePicker,
  DatePicker,
  DateRangePicker, 
  DateRangeDelimiter,
  LocalizationProvider 
} from '@material-ui/pickers';
import InputMask from "react-input-mask";

import SelectX from "./selectx";
import AlertX from "./alertx";
import ButtonX from "./buttonx";
import { formatSlider, toValidDate, toValidTime } from "../../_metronic/utils/utils";

import { withStyles } from '@material-ui/core/styles';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { DropzoneArea } from 'material-ui-dropzone';

const _now = new Date();
const now = toValidDate(_now);
const now_time = toValidTime(_now);

const styles = {
  root: {
    //marginTop: '24px !important',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 1,
  },
  formControl: {
    margin: 1,
  },
};

class InputX extends React.Component {

  constructor(props) {
    super(props);

    let input = null;

    switch(props.type){
      case 'date':
        input = (props.value ? toValidDate(props.value) : (props.useNow ? now : null));
        break;
      default:
        break;
    }
    
    this.state = {
      error : false,
      message : '',
      input : input,
      updated : false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.validated = this.validated.bind(this);
    this.handleDropzoneDelete = this.handleDropzoneDelete.bind(this);

  }

  componentDidUpdate(prevProps, prevState){
    const { validated, value, updateValue } = this.props;
    const { input, updated } = this.state;


    if(!prevProps.validated && validated){
      this.validated(input !== null ? input : value);
    }

    if(!updated){
      if(updateValue !== null && updateValue !== input){
        this.setState({input : updateValue !== null ? updateValue : value});
      }
    }
  }

  handleDropzoneDelete(id, files){
    let newFiles = files.filter((d) => d.id !== id);
    this.setState({
      input : newFiles,
      updated: true,
    });
  }

  handleChange(e){
    const { onChange, type } = this.props;
    let value = '';

    switch(type){
      case 'date': {
        value = e ? e.toString() : '';
        break;
      }
      case 'daterange': {
        value = e ? e.map(v => toValidDate(v)) : '';
        break;
      }
      case 'time': {
        value = e ? e.toString() : '';
        break;
      }
      case 'checkbox': {
        value = e.target.checked;
        break;
      }
      case 'radio': {
        value = e.target.value;
        break;
      }
      case 'select': {
        if(e){
          value = e.value;
        }
        break;
      }
      case 'radiobutton': {
        value = e;
        break;
      }
      default : {
        value = e.target.value;
      }
    }

    this.validated(value);

    if(onChange){
      switch(type){
        case 'date': {
          onChange(toValidDate(e), this.props);
          break;
        }
        case 'daterange': {
          onChange(value, this.props);
          break;
        }
        case 'time': {
          onChange(toValidTime(e), this.props);
          break;
        }
        case 'radiobutton': {
          onChange(e, this.props);
          break;
        }
        case 'select': {
          onChange(e, this.props);
          break;
        }
        default : {
          onChange(e);
        }
      }
    }
  }

  getRequiredText(){
    const { required, required_text, name } = this.props;

    let txt = required_text;
    if(required && !required_text){
      txt = 'Please provide a '+name.replace('_', ' ').split(/ /g).map(word => `${word.substring(0,1).toUpperCase()}${word.substring(1)}`).join(' ');
    }
    return txt;
  }

  validated(value){
    const { required, type, selections, idField } = this.props;
    let error = true, 
    message = '';

    if(required){
      if(value === null){
        message = this.getRequiredText();
      }else if(type === 'daterange' && value.filter(Boolean).length === 0){
        message = 'Please complete the date range';
      }else if(!Array.isArray(value) && value.toString().trim().length === 0){
        message = this.getRequiredText();
      }else if(Array.isArray(value) && value.length === 0){
        message = this.getRequiredText();
      }else if(type === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)){
        message = 'Please type in a valid email';
      }else{
        error = false;
      }
    }else{
      if(type === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) && value !== ''){
        message = 'Please type in a valid email';
      }else{
        error = false;
      }
    }

    if(error){
      switch(type){
        case 'radio' :{
          value = (selections && selections.length > 0 ? selections[0][idField] : '');
          break;
        }
        default:
          break;
      }
    }

    this.setState({
      error : error, 
      message : message,
      input : value,
      updated : true,
    });

    
  }

  input(){
    const {
      label, name, value, required, useNow, idField, valueField, selections, disabled, readonly, accept, filterMode
    } = this.props;

    const { error, message, input, updated } = this.state;

    let input_label = label ? label : name.toUpperCase().replace(/_/g, ' ');
    let type = this.props.type;
    if(this.props.hide){
      type = 'hidden';
    }
    switch(type){
      case 'hidden': {
        return (<input type="hidden" name={name} value={value} />);
      }
      case 'file': {
        return (
          <>
          { value.src && 
          <Chip 
            label={value.value}
            onDelete={this.props.onDelete}
            avatar={
              <Avatar src={value.src} alt={value.value}/>
            }/>
          }
          { value.value &&
          <input type="hidden" name={name+"_value"} value={value.value} />
          }
          <Form.Control
            name={name}
            required={required}
            type="file"
            accept={accept}
            style={{ marginTop : 10 }}
          />
          </>
        )
      }
      case 'select' : {
        return (
          <SelectX
            required={required}
            value={value}
            selections={selections}
            idField={idField}
            valueField={valueField}
            isDisabled={disabled}
            name={name}
            label={input_label}
            onChange={this.handleChange}
            helperText={error ? message : ''}
            error={error}
          />
        )
      }
      case 'daterange': {
        let dv = value && value.filter(Boolean).length > 0 ? value.map(v => toValidDate(v)) : ( input ? input : (useNow ? now : null));        
        if(updated){
          dv = input;
        }
        if(!Array.isArray(dv)){
          if(dv){
            if(dv.includes(",")){
              dv = dv.split(",");
            }else{
              dv = [dv, dv];
            }
          }else{
            dv = ["", ""];
          }
        }
        const { label_start, label_end, name_start, name_end } = this.props;
        return (
          <>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DateRangePicker
              startText={label_start ? label_start : "From " + input_label}
              endText={label_end ? label_end : "To " + input_label}
              value={dv}
              onChange={this.handleChange}
              clearable={this.props.clearable}
              required={required} 
              disabled={disabled} 
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField fullWidth autoComplete='off' name={name_start ? name_start : "from_"+name} {...startProps} variant={filterMode && type === 'text' ? 'standard' : (this.props.variant ? this.props.variant : "standard")} error={error} helperText={message}/>
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField fullWidth autoComplete='off' name={name_end ? name_end : "to_"+name} {...endProps} variant={filterMode && type === 'text' ? 'standard' : (this.props.variant ? this.props.variant : "standard")} error={error} helperText={message}/>
                </React.Fragment>
              )}
            />
            </LocalizationProvider>
          </>
        )
      }
      case 'date': {
        // const dv = input ? (value !== input ? input : toValidDate(value) ) : (value ? toValidDate(value) : (useNow ? now : null));
        // console.log(input, dv, value);
        //const dv = input ? input : (value ? toValidDate(value) : (useNow ? now : null));
        let dv = value ? toValidDate(value) : ( input ? input : (useNow ? now : null));
        if(updated){
          dv = input;
        }
        return (
          <>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DatePicker
              fullWidth
              format="MM/dd/yyyy"
              required={required}
              label={input_label}
              autoOk
              clearable
              value={dv}
              onChange={this.handleChange}
              disabled={disabled}
              renderInput={(props) => <TextField name={name} fullWidth {...props} error={error} helperText={message}/>}
            />
          </LocalizationProvider>
          </>
        )
      }
      case 'time': {
        return (
          <>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <TimePicker
              renderInput={(props) => <TextField name={name} fullWidth {...props} error={error} helperText={message}/>}
              fullWidth
              format="HH:mm"
              required={required}
              label={input_label}
              clearable
              ampm={false}
              value={input ? input : (value ? new Date(now + ' ' + value) : (useNow ? Date(now + ' ' + now_time) : null))}
              onChange={this.handleChange}
            />
          </LocalizationProvider>
          </>
        )
      }
      case 'datetime': {
        let new_value = null;
        if(value){
          new_value = Date.parse(value);
          if(!isNaN(new_value)){
            new_value = toValidDate(new_value, 'yyyy-MM-dd') + 'T' + toValidDate(new_value, 'HH:mm');
          }
        }
        
        return (
          <TextField
            fullWidth
            name={name}
            required={required}
            type="datetime-local"
            defaultValue={new_value ? new_value : (useNow ? toValidDate(_now, 'yyyy-MM-dd') + 'T' + toValidDate(_now, 'HH:mm') : '')}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
      }
      case 'checkbox': {
        return (
          <>
          <FormControlLabel
            label={input_label}
            control={
              <Checkbox 
              name={name}
              checked={input !== null ? input : value}
              onChange={this.handleChange} 
              value={input !== null ? input : value}
              inputProps={this.props.inputProps}
              />
            }
          />
          { error && 
          <FormHelperText error={error}>{message}</FormHelperText>
          }
          </>
        );
      }
      case 'radio': {
        let default_value = (input !== null && input !== '' ? input : ((value !== null && value !== '') ? value : (selections && selections.length > 0 ? selections[0][idField] : '')));
        return (
          <>
          <FormControl component="fieldset">
            <FormLabel component="legend">{input_label}</FormLabel>
            <RadioGroup row name={name} value={default_value} onChange={this.handleChange}>
            {selections.map( (option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={option[idField]}
                  control={<Radio color="primary" />}
                  label={option[valueField]}
                  labelPlacement="start"
                />
              );
            })}
            </RadioGroup>
          </FormControl>
          { (error && (required && (default_value === null || default_value === ''))) && 
          <FormHelperText error={error}>{message}</FormHelperText>
          }
          </>
        );
      }
      case 'radiobutton': {
        return (
          <>
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name={name} onChange={this.handleChange} defaultValue={(value !== null && value !== '') ? value : (selections ? selections[0][idField] : '')}>
              {selections.map( (option, index) => {
                return (
                  <ToggleButton key={index} value={option[idField]} variant="warning">
                    {option[valueField]}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </ButtonToolbar>
          { error && 
          <FormHelperText error={error}>{message}</FormHelperText>
          }
          </>
        )
      }
      case 'multiselect': {
        let default_value = (input !== null && input !== '' ? input : ((value !== null && value !== '') ? value : ''));
        return (
          <>
          <FormControl fullWidth className={this.props.classes.formControl}>
            <InputLabel htmlFor={"select-multiple-chip-"+name}>{input_label}</InputLabel>
            <Select
              fullWidth
              multiple
              name={name}
              defaultValue={default_value}
              onChange={this.handleChange}
              input={<Input id={"select-multiple-chip-"+name} />}
              renderValue={input => (
                <div className={this.props.classes.chips}>
                  {input &&
                  <>
                    {input.map(option => {
                        let selected = selections.find((n) => n[idField] === option);
                        if(selected){
                          return <Chip key={'multiselect_selected_'+name+selected[idField]} label={selected[valueField]} color="primary" className={this.props.classes.chip} />
                        }
                        return <></>;
                    })}
                  </>
                  }
                </div>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
            {selections.map(option => (
              <MenuItem key={'multiselect_'+name+option[idField]} label={option[valueField]} value={option[idField]}>
                {option[valueField]}
              </MenuItem>
            ))}
            </Select>
          </FormControl>
          { error && 
          <FormHelperText error={error}>{message}</FormHelperText>
          }
          </>
        )
      }
      case 'slider':{
        return (
          <>
          <Typography id="discrete-slider-small-steps" gutterBottom>
            {input_label}
          </Typography>
          <Slider
            name={name}
            defaultValue={value}
            step={this.props.step}
            min={this.props.min}
            max={this.props.max}
            aria-labelledby="discrete-slider-small-steps"
            marks
            valueLabelDisplay="auto"
            { ...formatSlider(this.props)}
          />
          </>
        );
      }
      case 'button': {
        return (
          <ButtonX 
          title={label}
          text={label}
          onClick={this.props.onClick}
          icon={this.props.icon}
          type={name === 'submit' ? 'submit' : this.props.type}
          variant={this.props.variant}
          className={this.props.className}
          loading={this.props.loading}
          disabled={disabled}
          />
        )
      }
      case 'button_group': {
        return (
          <ButtonGroup style={{height: '100%'}}>
            {this.props.buttons.map(button => 
              <ButtonX 
                title={button.label}
                text={button.label}
                onClick={button.onClick}
                icon={button.icon}
                type={name === 'submit' ? 'submit' : button.type}
                variant={button.variant}
                className={button.className}
                loading={button.loading}
                disabled={button.disabled}
              />
            )}
          </ButtonGroup>
        )
      }
      case 'alert' : {
        return <AlertX message={value} variant={this.props.variant} timeout={false}/>
      }
      case 'label' : {
        return (
          <Typography gutterBottom>
            {input_label}
          </Typography>
        )
      }
      case 'inputmask' : {
        return (
          <InputMask
              mask={this.props.mask}
              maskChar=" "
              disabled={disabled}
              readOnly={readonly}
              onChange={this.handleChange}
              value={!updated ? (input ? input : value) : input}
            >
            {() => <TextField 
              required={required}
              label={input_label}
              name={name}
              variant={filterMode && type === 'text' ? 'standard' : (this.props.variant ? this.props.variant : "standard")}
              fullWidth
              helperText={error ? message : ''}
              error={error}
            />}
          </InputMask>
        )
      }
      case 'dropzone' : {
        let files = updated ? input : value;
        console.log(files);
        return <>
          {files &&
            <Form.Group as={Col}>
            {
              files.map(f => {
                return (
                  <span key={"chip_"+f.id} >
                    <Chip label={f.name} onDelete={() => this.handleDropzoneDelete(f.id, files)}/>
                    <input type="hidden" name={name} value={f.id} />
                  </span>
                )
              })
            }            
            </Form.Group>
          }
          <DropzoneArea
            showPreviews={this.props.showPreviews ? this.props.showPreviews : false}
            showPreviewsInDropzone={this.props.showPreviewsInDropzone ? this.props.showPreviewsInDropzone : false}
            useChipsForPreview
            onChange={this.props.handleDropzoneChange}
          />
        </>
      }
      case 'mobile' : {
        break;
      }
      default:
        return (
          <>
          <TextField 
            label={input_label}
            name={name}
            type={filterMode && type === 'text' ? 'search' : type}
            multiline={type === 'textarea' ? true : false}
            disabled={disabled}
            readOnly={readonly}
            minRows="3" 
            required={required}
            //defaultValue={input ? input : value}
            value={!updated ? (input ? input : value) : input}
            onChange={this.handleChange}
            variant={filterMode && type === 'text' ? 'standard' : (this.props.variant ? this.props.variant : "standard")}
            fullWidth
            helperText={error ? message : ''}
            error={error}
          />
          </>
        );
    }
  }
  render(){ 
    const { size, ignore, offset } = this.props;

    if(ignore){
      return (<></>);
    }

    let type = this.props.type;
    if(this.props.hide){
      type = 'hidden';
    }

    return (
      <>
        { type !== 'hidden' ? (
        <Form.Group as={Col} sm={(offset && offset > 0) ? { span : size, offset : offset } : size} style={this.props.containerStyle}>
          { this.input() }
        </Form.Group>
        ) : (
          this.input()
        )}
      </>
    )
  }
}

// Set default props
InputX.defaultProps = {
  label : false,
  type : 'text',
  name : '', 
  value : null, 
  required : false, 
  size : 12,
  required_text : false,
  initialValues : {},
  idField : 'key', 
  valueField : 'value',
  useNow : false,
  selections : [],
  disabled : false,
  readonly : false,
  accept : "image/png, image/jpeg",
  ignore : false,
  onChange : null,
  filterMode : false,
  hide : false,
  loading : false,
};

export default withStyles(styles)(InputX);