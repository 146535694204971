export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
      // {
      //   title: "Attendance",
      //   root: true,
      //   alignment: "left",
      //   page: "my/attendance",
      //   translate: "MENU.TODO"
      // },
      // {
      //   title: "Requests",
      //   root: true,
      //   alignment: "left",
      //   page: "my/requests",
      //   translate: "MENU.TODO"
      // },
      // {
      //   title: "Todo",
      //   root: true,
      //   alignment: "left",
      //   page: "my/todo",
      //   translate: "MENU.TODO"
      // },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "My Attendance",
        root: true,
        icon: "flaticon-clock-1",
        alignment: "left",
        page: "my/attendance",
        translate: "MENU.TODO"
      },
      {
        title: "My Requests",
        root: true,
        icon: "flaticon-open-box",
        alignment: "left",
        page: "my/requests",
        translate: "MENU.TODO"
      },
      {
        title: "My Todo",
        root: true,
        icon: "flaticon-list",
        alignment: "left",
        page: "my/todo",
        translate: "MENU.TODO"
      },
      {
        title: "CRM",
        root: true,
        icon: "flaticon-layer",
        page: "crm",
        translate: "MENU.CRM",
        bullet: "dot",
        submenu: [
          {
            title: "Customers",
            bullet: "dot",
            page: "crm/customers"
          },
        ]
      },
      {
        title: "Billing",
        root: true,
        icon: "flaticon-layer",
        page: "billing",
        translate: "MENU.CRM",
        bullet: "dot",
        submenu: [
          {
            title: "Plans",
            bullet: "dot",
            page: "billing/plans"
          },
          {
            title: "Subscriptions",
            bullet: "dot",
            page: "billing/subscriptions"
          },
        ]
      },
      {
        title: "Todo",
        root: true,
        icon: "flaticon-layer",
        page: "todo",
        translate: "MENU.TODO",
        bullet: "dot",
        submenu: [
          {
            title: "Projects",
            bullet: "dot",
            page: "todo/projects"
          },
          {
            title: "Teams",
            bullet: "dot",
            page: "todo/teams"
          },
        ]
      },
      {
        title: "Human Resource",
        root: true,
        icon: "flaticon-users",
        page: "hr",
        translate: "MENU.HR",
        bullet: "dot",
        submenu: [
          {
            title: "Employees",
            bullet: "line",
            page: "hr/employee",
            submenu: [
              {
                title: "Employee Records",
                bullet: "line",
                page: "hr/employee/records"
              },
              {
                title: "Attendance",
                bullet: "line",
                page: "hr/employee/attendance"
              },
              {
                title: "Requests",
                bullet: "line",
                page: "hr/employee/requests"
              },
              {
                title: "Absence Calendar",
                bullet: "line",
                page: "hr/employee/absence"
              },
              {
                title: "Reports",
                bullet: "line",
                page: "hr/employee/reports"
              },
            ]
          },
          {
            title: "Settings",
            bullet: "dot",
            page: "hr/settings",
            submenu: [
              {
                title: "Shift Groups",
                bullet: "line",
                page: "hr/settings/shifts"
              },
              {
                title: "Departments",
                bullet: "line",
                page: "hr/settings/departments"
              },
              {
                title: "Request Types",
                bullet: "line",
                page: "hr/settings/requests"
              },
              {
                title: "Employment Status",
                bullet: "line",
                page: "hr/settings/employee/status"
              },
            ]
          },
        ]
      },
      {
        title: "Payroll",
        root: true,
        icon: "flaticon-layer",
        page: "payroll",
        translate: "MENU.PAYROLL",
        bullet: "dot",
        submenu: [
          {
            title: "Employees",
            bullet: "dot",
            page: "payroll/employees"
          },
          {
            title: "Records",
            bullet: "dot",
            page: "payroll/records"
          },
          // {
          //   title: "Settings",
          //   bullet: "dot",
          //   page: "payroll/settings"
          // },
        ]
      },
      {
        title: "Account",
        root: true,
        icon: "flaticon-safe-shield-protection",
        page: "account",
        bullet: "dot",
        submenu: [
          {
            title: "Users",
            bullet: "dot",
            page: "account/users",
            submenu: [
              {
                title: "Records",
                bullet: "line",
                page: "account/users/records"
              },
              {
                title: "Logs",
                bullet: "line",
                page: "account/users/logs"
              },
              {
                title: "Notifications",
                bullet: "line",
                page: "account/users/notifications"
              },
            ]
          },
          {
            title: "Subscriptions",
            bullet: "dot",
            page: "account/subscriptions"
          },
          {
            title: "Settings",
            bullet: "dot",
            submenu: [
            ]
          },
        ]
      },
      {
        title: "System",
        root: true,
        icon: "flaticon-layer",
        page: "todo",
        translate: "MENU.TODO",
        bullet: "dot",
        submenu: [
          {
            title: "Company",
            bullet: "dot",
            page: "system/company"
          },
          {
            title: "Email",
            bullet: "dot",
            page: "system/email"
          },
        ]
      },
      // {
      //   title: "CRM",
      //   root: true,
      //   icon: "flaticon2-group",
      //   page: "crm",
      //   translate: "MENU.CRM",
      //   bullet: "dot",
      //   submenu: [
      //     {
      //       title: "Customers",
      //       bullet: "line",
      //       submenu: [
      //         {
      //           title: "Companies",
      //           desc: 'Some my description goes here',
      //           bullet: "line",
      //           page: "crm/customer/company"
      //         },
      //         {
      //           title: "Contacts",
      //           desc: 'Some my description goes here',
      //           bullet: "line",
      //           page: "crm/customer/contact"
      //         },
      //         {
      //           title: "Projects",
      //           desc: 'Some my description goes here',
      //           bullet: "line",
      //           page: "crm/customer/projects"
      //         },
      //       ]
      //     },
      //     {
      //       title: "Settings",
      //       root: true,
      //       page: "hr/settings",
      //       bullet: "dot"
      //     },
      //   ]
      // },
      // {
      //   title: "Phone Console",
      //   root: true,
      //   icon: "flaticon2-phone",
      //   page: "phoneconsole",
      //   translate: "MENU.PHONECONSOLE",
      //   bullet: "dot"
      // },
      {
        title: "Logout",
        root: true,
        icon: "flaticon-logout",
        page: "logout",
        bullet: "dot"
      },
    ]
  }
};
