import { withRouter } from "react-router-dom";

import Listx from "../../../../../components/listx";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'username', label: 'User' },
      { id: 'action', label: 'Action' },
      { id: 'meta', label: 'Meta' },
      { id: 'identifier', label: 'Identifier' },
      { id: 'obj', label: 'Object' },
      { id: 'created', label: 'Date/Time' },
    ];
  }


  renderRowData(data){
    return [
      this.renderCell(data.username),
      this.renderCell(data.action),
      this.renderCell(data.meta),
      this.renderCell(data.identifier),
      this.renderCell(data.obj),
      this.renderCell(data.created),
    ];
  }
}

export default withRouter(List);