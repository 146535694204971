import React from "react";

import { Form } from 'react-bootstrap';
import FormX from './formx';

class ListFilterX extends React.Component {

  constructor(props) {
    super();
    
    this.state = {};
    if(props.search.show_advance_filter){
      this.state.show_advance_filter = (props.search.show_advance_filter === undefined || parseInt(props.search.show_advance_filter) === 0 ? 0 : 1)
    }

    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleAdvanceFilter = this.handleAdvanceFilter.bind(this);
    this.handlers = {
      handleDateTimeChange : this.handleDateTimeChange,
      handleChange : this.handleChange,
      handleSelectChange : this.handleSelectChange,
      handleDateRangeChange : this.handleDateRangeChange,
      handleAdvanceFilter : this.handleAdvanceFilter,
    }
  }

  handleDateTimeChange(e, props){
    this.setState({[props.name]: e});
  }
  
  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(e, object) {
    let value = '';
    if(e){
      value = e.value;
    }
    this.setState({[object.name]: value});
  }

  handleDateRangeChange(e, object){
    this.setState({["from_"+object.name]: e[0], ["to_"+object.name]: e[1]});
  }

  handleSubmit(event){
    event.preventDefault();
    event.stopPropagation();
    
    const { onChange } = this.props;
    onChange(this.state);

    return false;
  }

  handleAdvanceFilter(){
    this.setState(prevState => ({
      show_advance_filter: (prevState.show_advance_filter === undefined || parseInt(prevState.show_advance_filter) === 0 ? 1 : 0)
    }));
  }

  render(){ 
    const { fields, advance_fields } = this.props;
    const { show_advance_filter } = this.state;

    let display = "none";
    
    if(advance_fields){
      if(show_advance_filter !== undefined && parseInt(show_advance_filter) !== 0){
        display = "block";
      }
    }

    return (
      <Form
        noValidate
        onSubmit={e => this.handleSubmit(e)}
      >
      <FormX fields={fields(this.props, this.handlers)} filterMode={true}/>
      { advance_fields && 
        <div style={{ display: display }}>
          <FormX fields={advance_fields((display === "block" ? this.props : {}), this.handlers)} filterMode={true}/>
        </div>
      }
      </Form>
    )
  }
}
// Set default props
ListFilterX.defaultProps = {
  onChange : null,
  isAdmin : false,
  search : null,
  selections : null,
  fields : null,
};
export default ListFilterX;