import React from "react";

import { Form } from "react-bootstrap";

import InputX from "./inputx";

class FormX extends React.Component {

  render(){ 
    const { initialValues, fields, validated, filterMode, updateValues } = this.props;
    
    if(fields.length === 0){
      return <>No Field specified</>;
    }
    return (
      <>
        {initialValues && 
          <>
          {initialValues.id > 0 &&
            <input type="hidden" name="id" value={initialValues.id} />
          }
          </>
        }
        { 
        fields.map((row, rowidx) => (
          row.map((col, colidx) => (
            <Form.Row key={"formx-row-"+rowidx+"-"+colidx}>
              {
                col.map((field, fieldidx) => (
                  <InputX {...field} updateValue={updateValues && updateValues[field.name] ? updateValues[field.name] : null} key={rowidx+"_"+colidx+"_"+fieldidx} validated={validated} filterMode={filterMode}/>
                ))
              }
            </Form.Row>
          ))
        ))
        }
      </>
    )
  }
}

// Set default props
FormX.defaultProps = {
  isAdmin: true,
  fields : [],
  validated : false,
  filterMode : false,
};

export default FormX;