import React from "react";
import { Link, Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../components/crudpageX";
import EmployeeAttendanceBreaks from "./breaks";
import EmployeeAttendanceCalendar from "./calendar";

export default function EmployeeAttendance(props) {
  
  const fields = (props, state, params, parentprops) => {

    let fields = [
      [
        [
          {
            name : 'employee_timesheet',
            type : 'hidden',
            value : props.initialValues && props.initialValues['employee_timesheet'] ? props.initialValues['employee_timesheet'] : 0,
          },
          { 
            name : 'employee', 
            type : 'select', 
            required : true, 
            size : 12,
            idField : 'id',
            valueField : 'name',
            value : props.initialValues && props.initialValues['employee'] ? props.initialValues['employee'] : '',
            selections : state.selections && state.selections['employees'] ? state.selections['employees'] : [],
            ignore : !parentprops.isAdmin,
          },
          { 
            name : 'start', 
            label : 'Punch In',
            type : 'datetime', 
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['start'] ? props.initialValues['start'] : '',
            useNow : true,
          },
          { 
            name : 'end', 
            label : 'Punch Out',
            type : 'datetime', 
            required : false, 
            size : 6,
            value : props.initialValues && props.initialValues['end'] ? props.initialValues['end'] : '',
          },
          { 
            name : 'start_note', 
            label : 'Punch In Note',
            type : 'textarea', 
            required : false, 
            size : 12,
            value : props.initialValues && props.initialValues['start_note'] ? props.initialValues['start_note'] : ''
          },
          { 
            name : 'end_note', 
            label : 'Punch Out Note',
            type : 'textarea', 
            required : false, 
            size : 12,
            value : props.initialValues && props.initialValues['end_note'] ? props.initialValues['end_note'] : ''
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List 
    {...props} 
    parent="timekeeping.attendance"
    />
  }

  const { path } = props.match;
  const { pathname } = props.location;
  const { access, baseUrl, canCreateBreak, canUpdateBreak } = props;

  let overrideBreakAccess = access;
  overrideBreakAccess = {...access, canCreate : canCreateBreak, canUpdate : canUpdateBreak}
  
  if(overrideBreakAccess.canCreate === undefined){
    overrideBreakAccess.canCreate = access.canCreate;
  }
  if(overrideBreakAccess.canUpdate === undefined){
    overrideBreakAccess.canUpdate = access.canUpdate;
  }
  
  return (
    <>
      <Link to={path} className={ pathname === path ?  "btn btn-primary btn-square" : "btn btn-outline-primary btn-square" } title="List">
        List
      </Link>
      <Link to={path + "/calendar"} className={ pathname === path + "/calendar" ? "btn btn-primary btn-square" : "btn btn-outline-primary btn-square" } title="Calendar">
        Calendar
      </Link>
      <Switch>
        <Route 
          path={path + "/mg/:id/breaks"}
          children={
          <EmployeeAttendanceBreaks
            access={overrideBreakAccess}
            path={path}
            baseUrl={baseUrl ? baseUrl : path}
          />} 
        />
        <Route 
          path={path + "/calendar"}
          children={<EmployeeAttendanceCalendar
            apiUrl={ props.myMode ? 'hr/my' : 'hr/employee'}
            myMode={ props.myMode }
          />} 
        />
        <Route 
          from='/hr/my/timekeeping'
          path={path}
          children={<CrudPageX 
            List={list}
            path={path}
            baseUrl={baseUrl ? baseUrl : path}
            form_size='6'
            fields={fields}
            title='Attendance'
            getData={access.isAdmin}
            {...access}
            successRedirect={path}
          />} 
        />
      </Switch>
    </>
  )
}