import React from "react";
import {
  Modal,
} from "react-bootstrap";

import ButtonX from "./buttonx";

export default class ModalX extends React.Component {

  render() {
    const { title, body, submit, action_loading, ...props } = this.props;
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            { title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { body }
        </Modal.Body>
        <Modal.Footer>
          { this.props.submit && 
            <>
            { typeof this.props.submit === 'function' ? (
              <ButtonX type="button" variant={this.props.submit_variant} onClick={this.props.submit} loading={action_loading} text={this.props.submit_title}/>
            ) : (
              <ButtonX type="submit" variant={this.props.submit_variant} form={this.props.submit} loading={action_loading} text={this.props.submit_title}/>
            )}
            </>
          }
          <ButtonX type="button" className="btn btn-default" onClick={this.props.onHide} text="Close" />
        </Modal.Footer>
      </Modal>
    );
  }
}
// Set default props
ModalX.defaultProps = {
  size : 'lg',
  title : '',
  body : null,
  submit_form : null,
  submit_title : 'Submit',
  submit_variant : 'btn-primary',
  action_loading : false,
};