import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AccountRecords from "./users/records";
import AccountLogs from "./users/logs";
import AccountNotifications from "./users/notifications";
import AccountSubscriptions from "./subscriptions";

function Account(props) {

  const routes = [
    {
      path : 'account/users/records',
      component : (props, access) => <AccountRecords {...props} access={access}/>,
    },
    {
      path : 'account/users/logs',
      component : (props, access) => <AccountLogs {...props} access={access}/>,
    },
    {
      path : 'account/users/notifications',
      component : (props, access) => <AccountNotifications {...props} access={access}/>,
    },
    {
      path : 'account/subscriptions/:selectedID?',
      permission : 'account/subscriptions',
      component : (props, access) => <AccountSubscriptions {...props} access={access}/>,
    },
  ];

  let first_route = null;
  let redirect_route = null;
  const { pathname } = props.location;

  return (
    <Switch>
      { routes.map( (route, index) => {        
        let perm = route.permission ? route.permission : route.path;
        let crud = props.access[perm];
        if((perm in props.access) && crud && crud.canRead){
          if(!first_route){
            first_route = '/'+route.path;
          }
          if('/'+(route.parent ? route.parent : route.path) === pathname && !redirect_route){
            redirect_route = '/'+route.path;
          }
          return (
            <Route key={index}
              path={"/"+route.path}
              render={(props) => route.component({...props}, crud)} 
            />
          )
        }
        return <></>;
      })}

      <Redirect to={
        {
          pathname: (redirect_route ? redirect_route : ( first_route ? first_route : "/dashboard")), 
          state: (!redirect_route && !first_route ? { message : 'Invalid Page Access ', message_variant : 'danger' } : {})
        }
      } />
    </Switch>
  );
}
export default withRouter(Account);