import React from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../components/crudpageX";
import SettingsShiftsSchedules from "./schedules";
import SettingsShiftsBreaks from "./breaks";

export default function SettingsShifts(props) {

  const fields = (props) => {
    let fields = [
      [
        [
          { 
            name : 'name', 
            type : 'text', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['name'] ? props.initialValues['name'] : ''
          },
          { 
            name : 'description', 
            type : 'textarea', 
            required : false, 
            size : 12,
            value : props.initialValues && props.initialValues['description'] ? props.initialValues['description'] : ''
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <Switch>
      <Route 
        path={path + "/mg/:id/schedules"}
        children={
        <SettingsShiftsSchedules
        access={access}
        path={path}
        />}         
      />
      <Route 
        path={path + "/mg/:id/breaks"}
        children={
        <SettingsShiftsBreaks
        access={access}
        path={path}
        />}         
      />
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='6'
        fields={fields}
        title='Shift Groups'
        {...access}
        />} 
      />
    </Switch>
  )
}