import React, { useState, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "app/components/crudpageX";
import GlobalCrud from "app/crud/global.crud";

export default function Subscriptions(props) {

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("/billing/subscriptions");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  const fields = (props) => {
    const { selections } = props;
    let values = props.initialValues;

    let fields = [
      [
        [
          { 
            name : 'plan', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['plan'] ? values['plan'] : [],
            selections : selections && selections['plans'] ? selections['plans'] : [],
            idField : 'url',
            valueField : 'name',
          },
          { 
            name : 'customer', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['customer'] ? values['customer'] : [],
            selections : selections && selections['customers'] ? selections['customers'] : [],
            idField : 'url',
            valueField : 'fullname',
          },
          { 
            name : 'description', 
            label : 'Description',
            type : 'textarea', 
            size : 12,
            value : values && values['description'] ? values['description'] : ''
          },
          { 
            name : 'trial_end', 
            type : 'date', 
            size : 4,
            value : props.initialValues && props.initialValues['trial_end'] ? props.initialValues['trial_end'] : ''
          },
          { 
            name : 'start_date', 
            type : 'date', 
            size : 4,
            value : props.initialValues && props.initialValues['start_date'] ? props.initialValues['start_date'] : ''
          },
          { 
            name : 'cancel_date', 
            type : 'date', 
            size : 4,
            value : props.initialValues && props.initialValues['cancel_date'] ? props.initialValues['cancel_date'] : ''
          },
          { 
            name : 'ended_at', 
            type : 'date', 
            size : 4,
            value : props.initialValues && props.initialValues['ended_at'] ? props.initialValues['ended_at'] : ''
          },
          { 
            name : 'reference', 
            type : 'text', 
            size : 6,
            value : values && values['reference'] ? values['reference'] : ''
          },
          { 
            name : 'meta', 
            type : 'textarea',
            size : 12,
            value : values && values['meta'] ? values['meta'] : '{}'
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <Switch>
      { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='8'
        fields={fields}
        selections={selections}
        title='User Accounts'
        {...access}
        />} 
      />}
    </Switch>
  )
}