/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
import {Badge} from "react-bootstrap";
import GlobalCrud from "../crud/global.crud";

function UserStatus({ user_status }) {
  
  const [usersData, setUsersData] = useState([]);
  
  useEffect(() => {
    const myApi = new GlobalCrud('account');
    const fetchTimesheetData = async () => {
      const result = await myApi.getRecord("status");
      if(result.status === 200){
        setUsersData(result.data);
      }
    };

    fetchTimesheetData();
  }, []);

  
  useEffect(() => {
    if(user_status){
      let newData = [...usersData];
      let index = newData.findIndex(d => d.id === user_status.id);
      if(index >= 0 && newData[index].current_status !== user_status.current_status){
        newData[index].current_status = user_status.current_status;
        setUsersData(newData);
      }
    }
  }, [user_status, usersData]);
  
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Users</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <Badge variant='warning'>{usersData.length}</Badge>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
            { usersData.map( (user, index) => {
              let default_img = (user.gender === 'male' ? 21 : 11);
              return (
                <div key={index} className="kt-widget4__item ">
                  <div className="kt-widget4__pic kt-widget4__pic--pic ">
                    <img alt="" src={ user.avatar ? user.avatar : toAbsoluteUrl("/media/users/300_"+default_img+".jpg")} />
                  </div>
                  <div className="kt-widget4__info ">
                    <span className="kt-widget4__username">
                      { user.display_name }
                    </span>
                    <p className="kt-widget4__text ">
                      { user.position }
                    </p>
                  </div>
                  <Badge variant={ user.current_status === 'Idle' ? 'danger' : user.current_status === 'Offline' ? 'secondary' : user.current_status === 'Online' ? 'success' : user.current_status === 'On Break' ? 'warning' : 'info' }>
                    { user.current_status }
                  </Badge>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user_status : state.ws.user_status,
  }
}

export default connect(
  mapStateToProps,
)(UserStatus)