import React from "react";
import DateFnsUtils from "@date-io/date-fns";
const dateFns = new DateFnsUtils();

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Token ${authToken}`;
      }

      config.baseURL = process.env.REACT_APP_API_ENDPOINT;
      //config.baseURL = 'http://testing.3wcorner.com:9000/';
      
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(response => {
      return response;
  }, error => {
    if (error.message === "Network Error"){
      error.response = {}
      error.response.status = 599;
      error.response.data = {}
    }else{
      if (error.response.status === 401) {
        console.log(error.response);
        //place your reentry code
        //console.log(store);
        //store.dispatch({type : "[Request User] Action"});
        let trigger_logout = true;
        if(error.response && error.response.config){
          const { config } = error.response;
          trigger_logout = !(config.url && (config.url === 'api/v1/account/auth/logout' || config.url === 'api/v1/account/auth/logout/'))
        }
        if(trigger_logout){
          store.dispatch({type : "[Logout] Action"});
        }
        if(!error.response.data['error']){
          let ers = [];
          if(Object.keys(error.response.data).length > 0){
            Object.keys(error.response.data).map( (v, i) => ers.push(error.response.data[v]));
          }
          error.response.data = {};
          error.response.data['error'] = ers;
        }
      }else if (error.response.status === 500) {
        error.response.data = {}
        error.response.data['error'] = ['Something went wrong', 'Please contact your administrator'];
      }else{
        if(typeof error.response.data === 'string'){
          error.response.data = {
            error : 'PAGE NOT FOUND'
          };
        }else{
          if(Object.keys(error.response.data).length > 0){
            Object.keys(error.response.data).map( (v, i) => {
              let s = error.response.data[v];
              if(!Array.isArray(s)){
                error.response.data[v] = [];
                error.response.data[v][s] = s;
              }
              return <></>;
            });
          }
        }
      }
    }
    return Promise.reject(error.response);
  });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export function getMonthStart(date){
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function capitalize(string){
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export function toValidDate(date, format){
  format = format || "MM/dd/yyyy";
  if(dateFns.isValid(date)){
    return dateFns.format(dateFns.date(date), format);
  }
  return date;
}
export function toValidTime(date, format){
  format = format || "HH:mm";
  if(dateFns.isValid(date)){
    return dateFns.format(dateFns.date(date), format);
  }
  return date;
}
export function toValidDateTime(date, format){
  format = format || "MM/dd/yyyy HH:mm";
  if(dateFns.isValid(date)){
    return dateFns.format(dateFns.date(date), format);
  }
  return date;
}

export function accessToProps(access){
  let keys = {'c' : 'canCreate', 'r' : 'canRead', 'u' : 'canUpdate', 'd':'canDelete', 'i' : 'isAdmin'};
  let newAccess = {};
  Object.keys(keys).map( (key, i) => {
    if(access){
      newAccess[keys[key]] = (key in access) && access[key] > 0;
    }else{
      newAccess[keys[key]] = false;
    }
    return <></>;
  });
  return newAccess;
}

export function convertUnit(value, unit){
  if(unit === 'hours'){
    value = Math.floor(value / 3600);
  }else if(unit === 'minutes'){
    value = Math.floor(value / 60);
  }
  return value;
}

export function formatSlider(props){

  return {
    valueLabelFormat : (v) => { return convertUnit(v, props.unit) },
  }
}

export function setMenu(newMenu, menu, index, new_access, reset){
  reset = reset || false;
  if("submenu" in menu){
    menu.submenu.map((sub_menu_1, index_1) => {
      if(sub_menu_1 && "submenu" in sub_menu_1){
        sub_menu_1.submenu.map((sub_menu_2, index_2) => menu.submenu[index_1].submenu[index_2]['hide'] = (reset ? false : (new_access && !new_access[sub_menu_2.page])));
        menu.submenu[index_1]['hide'] = (reset ? false : (sub_menu_1.submenu.filter(x => !x.hide).length === 0));
      }else{
        menu.submenu[index_1]['hide'] = (reset ? false : (new_access && !new_access[sub_menu_1.page]));
      }
      return <></>;
    });
    menu['hide'] = (reset ? false : (menu.submenu.filter(x => !x.hide).length === 0));
  }else{
    menu['hide'] = (reset ? false : (new_access && !new_access[menu.page]));
  }

  return menu;
}

export function passwordTest(pass){
  console.log(pass.match(/\d+/g));
  if(pass.length < 9){
    return "Password length must be greater than or equal to [9]"
  }else if(!pass.match(/\d+/g)){
    return "Should contain atleast [1] digit"
  }else if(!pass.match(/[a-z]/)){
    return "Should contain atleast [1] lower case"
  }else if(!pass.match(/[A-Z]/)){
    return "Should contain atleast [1] upper case"
  }

  return true;
}

export function getError(error){
  let msg = [];
  if(error.status === 404){
    msg.push("Error 404");
  }else{
    Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
  }
  return msg.join("\r\n");
}

export function getRandomColor(light){
  if(light === undefined){
    light = false;
  }
  
  let letters = '0123456789ABCDEF';
  if(light){
    letters = 'BCDEF';
  }
  let color = '#'; 
  for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color
}