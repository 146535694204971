import React from "react";
import { 
  withRouter,
} from "react-router-dom";
import Events from "./events";
import Users from './users';

import GlobalCrud from "../../../../crud/global.crud";

function AccountSubscriptions(props) {

  const { access, match } = props;
  const { selectedID } = match.params;

  const [selections, setSelections] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [selected_event, setSelected_event] = React.useState(null);
  
  let basePath = '/account/subscriptions';
  const event_api = new GlobalCrud('account/events');

  React.useEffect(() => {
    if(selectedID && events.length > 0){
      setSelected_event(events.find((d) => d.id.toString() === selectedID));
    }
  }, [selectedID, events]);

  React.useEffect(() => {
    const _api = new GlobalCrud('account/events');
    const fetchEventsData = async () => {
      const result = await _api.get();
      if(result.status === 200){
        setEvents(result.data);
      }
    };

    fetchEventsData();

    const fetchSelectionData = async () => {
      const result = await _api.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  React.useEffect(() => {
    if(!selected_event && events.length > 0){
      props.history.push({ pathname : basePath + "/"+events[0].id, state : { title : 'Todo', description : events[0].title } });
    }
  }, [basePath, events, selected_event, props.history]);

  let child_crud = null;

  if(selected_event){
    child_crud = new GlobalCrud(event_api.getBaseUrl()+'/'+selected_event.id+'/subscriptions');
  }
  
  return (
    <div className="row">
      <div className="col-xl-3">
        <Events basePath={basePath} events={events} selected_event={selected_event}/>
      </div>
      <div className="col-xl-9">
        {child_crud && selected_event &&
        <Users key={props.location.key} {...access} crudUrl={child_crud} events={events} selections={selections} title='Subscribers'/>
        }
      </div>
    </div>
  );
}

export default withRouter(AccountSubscriptions);
