import {
  withRouter,
} from "react-router-dom";
import ListX from "app/components/listx";

class Notifications extends ListX {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'type', label: 'Type' },
      { id: 'message', label: 'Message' },
      { id: 'from_username', label: 'From' },
      { id: 'created', label: 'Date/Time' },
    ];

  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.currentPage !== this.state.currentPage){
      console.log(this.state.currentPage)
      this.updatePage();
      return;
    }else if(prevState.currentPerPage !== this.state.currentPerPage){
      console.log(this.state.currentPerPage)
      this.updatePage();
      return;
    }
  }

  renderRowData(data){
    return [
      this.renderCell(data.type),
      this.renderCell(data.message),
      this.renderCell(data.from_username),
      this.renderCell(data.created),
    ];
  }
}

export default withRouter(Notifications);