import React from "react";

import { Route, Switch } from "react-router-dom";

import GlobalCrud from "../crud/global.crud";

import FormX from "./formx";
import Crud from "./crud";

class CrudPageX extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selections : {},
      loading : false,
    }
    
    let path = props.baseUrl ? props.baseUrl : props.path;
    if(props.apiPath){
      path = props.apiPath;
    }
    this.crudUrl = new GlobalCrud(path);
  }

  getSelections(){
    this.setState({loading : true });

    this.crudUrl.getData().then(({ data }) => {
      this.setState({
        selections : data,
        loading : false,
      });
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        loading : false,
      });
    });
  }

  componentDidMount(){
    if(this.props.getData){
      this.getSelections();
    }
  }

  form(props){
    return <FormX {...props} 
      fields={this.props.fields(props, this.state, this.props.params, this.props)}
    />
  }
  render(){ 
    const { 
      path,
      form_size,
      selections,
      title,
      List,
      subroute,
      successRedirect,
      isAdmin,
      canCreate,
      canDelete,
      canUpdate
    } = this.props;

    let selectoptions = selections;

    // if(this.state.loading){
    //   return (
    //     <div className="text-center">
    //       <Spinner animation="border"/>
    //     </div>
    //   )
    // }
    
    if(this.props.getData){
      const { selections } = this.state;
      selectoptions = {...selectoptions, ...selections};
    }

    return (
      <>
      <Switch>
        { canCreate &&
        <Route path={path + "/create"} exact title="Create" description="new" children={
          <Crud 
          colmd={form_size}
          successRedirect={successRedirect}
          baseUrl={this.props.baseUrl ? this.props.baseUrl : path}
          crudUrl={this.crudUrl} 
          selections={selectoptions}
          updateValues={this.props.updateValues}
          CustomForm={this.form.bind(this)}
          isAdmin={isAdmin}
          />
        }/>
        }
        { canUpdate &&
        <Route path={path + "/update/:id"} title="Update" description="refresh" exact children={
          <Crud 
          colmd={form_size}
          successRedirect={successRedirect}
          baseUrl={this.props.baseUrl ? this.props.baseUrl : path}
          crudUrl={this.crudUrl} 
          selections={selectoptions}
          updateValues={this.props.updateValues}
          CustomForm={this.form.bind(this)}
          isAdmin={isAdmin}
          />
        }/>
        }
        { canDelete && 
        <Route path={path + "/delete/:id"} exact title="Delete" description="remove" children={
          <Crud 
          isDelete={true}
          baseUrl={this.props.baseUrl ? this.props.baseUrl : path}
          crudUrl={this.crudUrl} 
          isAdmin={isAdmin}
          />
        }/>
        }
        { subroute && 
          subroute
        }
        { List && 
          <Route path={path} title={title} exact description="manage" children={
            <List
            baseUrl={path}
            crudUrl={this.crudUrl}
            selections={selectoptions}
            title={this.state.selections && this.state.selections.parent_info ? this.state.selections.parent_info : ''}
            isAdmin={isAdmin}
            canCreate={canCreate}
            canUpdate={canUpdate}
            canDelete={canDelete}
            />
          }/>
        }
      </Switch>
      </>
    )
  }
}

// Set default props
CrudPageX.defaultProps = {
  form_size : 12,
  selections : [],
  title : '',
  List : null,
  fields : [],
  subroute : null,
  getData : false,
  successRedirect: null,
  isAdmin : false,
  canCreate : false,
  canUpdate : false,
  canDelete : false,
  baseUrl : null,
  updateValues : null,
};

export default CrudPageX;