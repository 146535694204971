import React from "react";
import {
  withRouter,
} from "react-router-dom";

import FormX from "app/components/formx";
import GlobalCrud from "app/crud/global.crud.js";
import MiniList from "app/components/mini-list";
import BaseDropzone from "app/components/baseDropzone";

class Qualifications extends BaseDropzone {

  constructor(props) {
    super(props);

    const { id } = props.match.params;
    this.id = id;
    this.path_prefix = (this.id ? "/"+this.id+"/" : '');

    this.crudUrl = new GlobalCrud(props.path);
  }

  form(values){
    
    let files = values && values['files_data'] ? values['files_data'] : [];

    return <FormX {...this.props} 
      fields={[
        [
          [
            {
              name : 'id',
              type : 'hidden',
              value : values && values['id'] ? values['id'] : '',
            },
            { 
              name : 'company', 
              type : 'text', 
              required : true,
              size : 6,
              value : values && values['company'] ? values['company'] : ''
            },
            { 
              name : 'title', 
              label : 'Job Title',
              type : 'text', 
              required : true,
              size : 6,
              value : values && values['title'] ? values['title'] : ''
            },
            { 
              name : 'from_date', 
              label : 'From',
              type : 'date', 
              required : true, 
              size : 6,
              value : values && values['from_date'] ? values['from_date'] : ''
            },
            { 
              name : 'to_date', 
              label : 'To',
              type : 'date', 
              required : true, 
              size : 6,
              value : values && values['to_date'] ? values['to_date'] : ''
            },
            { 
              name : 'note', 
              type : 'textarea', 
              size : 12,
              value : values && values['note'] ? values['note'] : ''
            },
            { 
              name : 'files[]', 
              label : 'Attachments',
              type : 'dropzone',
              size : 12,
              value : files,
              showPreviews : true,
              handleDropzoneChange : this.handleDropzoneChange,
            },
          ]
        ]
      ]}
    />
  }

  render(){ 
    const { access } = this.props;
    const { files } = this.state;
    return (
      <>
      <div className="row">
        <div className="col-xl-12">
          <MiniList
          title="Work Experiences"
          apiPath={this.path_prefix+"experiences"}
          crudUrl={this.crudUrl}
          header={['Company', 'Job Title', 'From', 'To', 'Duration', 'Note']}
          body={['company', 'title', 'from_date', 'to_date', 'duration', 'note']}
          form={this.form.bind(this)}
          uploads={files}
          {...access}
          />
        </div>
      </div>
      </>
    )
  }
}

export default withRouter(Qualifications);