
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form, Table, Badge, Spinner } from 'react-bootstrap';

import {Portlet, PortletHeader, PortletBody,PortletHeaderToolbar } from "app/partials/content/Portlet";
import FormX from 'app/components/formx';
import ButtonX from 'app/components/buttonx';
import GlobalCrud from "app/crud/global.crud";
import Payslip from "app/components/payslip";
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';

import queryString from 'query-string'

function Create(props){
  
  const { id: currentID } = props.match.params;

  const { state: currentState } = props.history.location;
  let currentData = null;
  if(currentState){
    currentData = currentState.data;
  }

  const { selections } = props;
  const { url: currentUrl } = props.match;
  
  const isView = currentUrl.includes("/view");

  const { employee: selectedEmployeeID } = queryString.parse(props.location.search);

  const [employeeID, setEmployeeID] = useState(currentData ? currentData.employee.id : selectedEmployeeID);
  const [currentemployee, setCurrentEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validatedSetup, setValidatedSetup] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [selected, setSelected] = React.useState([]);
  
  useEffect(() => {
    const myApi = new GlobalCrud("/payroll");
    const fetchData = async (id) => {
      const result = await myApi.getRecord('records/'+id);
      if(result.status === 200){
        setFormData(result.data);
        setLoading(false);
      }
    };

    if(currentData){
      setFormData(currentData);
    }else if(currentID){
      setLoading(true);
      fetchData(currentID);
    }
  }, [currentData, currentID]);
  
  //const { record, details, income, deduction, current_income, ytd, company, employee } = computation;
  const { company: company_form, salary: salary_form, employee: employee_form, sheets: sheets_form, record: payroll_record, income, deduction, current_income, ytd, ytd_per_item } = formData;

  let record_id = null;
  if(payroll_record){
    record_id = payroll_record.id;
  }

  const show_details = ((sheets_form && sheets_form.length > 0) || record_id);

  useEffect(() => {
    const myApi = new GlobalCrud("/payroll/employees/");
    const fetchEmployeeData = async () => {
      const result = await myApi.read(employeeID);
      if(result.status === 200){
        setCurrentEmployee(result.data);
      }
    };

    if(employeeID){
      fetchEmployeeData();
    }else{
      setCurrentEmployee(null);
    }
  }, [employeeID]);

  useEffect(() => {
    if(show_details){
      const newSelecteds = sheets_form.map(n => {
        if(record_id){
          if(n.payslip && n.payslip.id === record_id){
            return n.id;
          }
          return false;
        }
        return n.id
      });
      setSelected(newSelecteds);
    }
  }, [sheets_form, record_id, show_details]);

  const handleSubmit = (event) =>{
    const form = event.currentTarget;
    setValidatedForm(true);
    setLoading(true);

    form.classList.add('was-validated');

    event.preventDefault();
    event.stopPropagation();
    
    if (form.checkValidity() === false) {  
      //not valid
      setLoading(false);
    }else{
      const data = new FormData(form);
      const computeAPI = new GlobalCrud("/payroll/generate/");
      computeAPI.create(data).then(({ data }) => {
        props.history.push({ pathname : "/payroll/records/update/"+data.record.id, state : { data : data }});
      })
      .catch((error) => {
        console.log(error);
      }).then(() => {
        setLoading(false);
      });
    }
  }

  const handleSetup = (event) =>{
    const form = event.currentTarget;
    setValidatedSetup(true);
    setLoading(true);

    form.classList.add('was-validated');

    event.preventDefault();
    event.stopPropagation();
    
    if (form.checkValidity() === false) {  
      //not valid
      setLoading(false);
    }else{
      const data = new FormData(form);
      const computeAPI = new GlobalCrud("/payroll");
      computeAPI.postRecord("form", data).then(({ data }) => {
        if(data.record.id){
          if(window.confirm("A payslip record was already created for this dates, do you want to go the update page?")){
            props.history.push({ pathname : "/payroll/records/update/"+data.record.id, state : { data : data }});
          }
        }else{
          setFormData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      }).then(() => {
        setLoading(false);
      });
    }
  }
  
  const boolField = (bool, params) => {
    const { text_true, text_false, variant_true, variant_false } = params;
    return (
      <Badge variant={bool ? variant_true : variant_false}>
        {bool ? text_true : text_false}
      </Badge>
    )
  }
  const mapObject = (label, parameters, func) => {
    return {
      'label' : label,
      'func' : func,
      'params' : parameters,
    }
  }
  
  const _company_form = () => {
    return [
      [
        [
          { 
            name : 'company', 
            type : 'hidden', 
            size : 12,
            value : company_form && company_form['name'] ? company_form['name'] : ''
          },
          { 
            name : 'address_1', 
            type : 'hidden', 
            size : 6,
            value : company_form && company_form['address_1'] ? company_form['address_1'] : ''
          },
          { 
            name : 'address_2', 
            type : 'hidden', 
            size : 6,
            value : company_form && company_form['address_1'] ? company_form['address_1'] : ''
          },
        ]
      ]
    ]
  }

  const _employee_form = () => {
    return [
      [
        [
          { 
            name : 'employee', 
            type : 'hidden', 
            size : 12,
            value : employee_form && employee_form['id'] ? employee_form['id'] : ''
          },
        ]
      ]
    ]
  }

  const _salary_form = () => {
    return [
      [
        [
          { 
            name : 'start_date', 
            type : 'hidden', 
            size : 12,
            value : salary_form && salary_form['start_date'] ? salary_form['start_date'] : ''
          },
          { 
            name : 'end_date', 
            type : 'hidden', 
            size : 12,
            value : salary_form && salary_form['end_date'] ? salary_form['end_date'] : ''
          },
          { 
            name : 'pay_date', 
            type : 'hidden', 
            size : 12,
            value : salary_form && salary_form['pay_date'] ? salary_form['pay_date'] : ''
          },
        ]
      ]
    ]
  }

  const showForms = () => {
    return (
      <div className="row">
        <div className="col-md-6">
          <FormX 
            validated={validatedForm}
            fields={_company_form()}
          />
        </div>
        <div className="col-md-6">
          <FormX 
            validated={validatedForm}
            fields={_employee_form()}
          />
        </div>
        <div className="col-md-6">
          <FormX 
            validated={validatedForm}
            fields={_salary_form()}
          />
        </div>
      </div>
    )
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sheets_form.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleSelectClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  const payslipColumn = (value, params) => {
    if(value){
      return (
        <Badge variant="danger">
          {value.no}
        </Badge>
      )
    }
    return 'N/A';
  }
  const showSheets = () => {
    if(show_details){
      let datamap = {
        'schedule' : mapObject('Schedule'),
        'day' : mapObject('Day'),
        'punch.in' : mapObject('Punch In'),
        'punch.out' : mapObject('Punch Out'),
        'work_time' : mapObject('Work Time'),
        'break_time' : mapObject('Break Time'),
        'remark' : mapObject('Remark'),
        'overunder' : mapObject('Over / Under'),
        'approved' : mapObject('Approved', {text_true : 'Approved', text_false : 'Pending Approval', variant_true : 'info', variant_false : 'secondary'}, boolField),
        'finalized' : mapObject('Finalized', {text_true : 'Finalized', text_false : 'Pending Review', variant_true : 'dark', variant_false : 'secondary'}, boolField),
        'payslip' : mapObject('Payslip', {}, payslipColumn),
      };

      return (
        <div className="col-md-12">
          { sheets_form.length > 0 ? (
            <Table size="sm" striped bordered hover responsive>
              <thead>
                <tr>
                  { !isView &&
                    <td style={{ textAlign: 'center' }}>
                    <Checkbox 
                      style={{padding:0}}
                      indeterminate={selected.length > 0 && selected.length < sheets_form.length}
                      checked={sheets_form.length === selected.length} 
                      onChange={handleSelectAllClick}
                      key={"cb_all"}
                    />
                  </td>
                  }
                  { Object.keys(datamap).map( (h, i) => {
                    return (
                      <th key={i}>{datamap[h].label}</th>
                    )
                  })}
                  {/* { (canUpdate || canDelete) &&
                    <th className="cell-action">Actions</th>
                  } */}
                </tr>
              </thead>
              <tbody>
                { sheets_form.map( ( d, idx ) => {
                  const checked = isSelected(d.id);
                  let has_payslip = (d.payslip);
                  if(has_payslip && record_id && record_id === d.payslip.id){
                    has_payslip = false;
                  }
                  const row_style = has_payslip ? { background: '#333333', color : '#ffffff' } : {};
                  return (
                    <tr key={idx} style={row_style}>
                      <>
                      { !isView &&
                        <td style={{ textAlign: 'center' }}>
                          { has_payslip ? (
                            <><ClearIcon/> N/A</>
                          ) : (
                            <Checkbox 
                              style={{padding:0}}
                              name={"sheets[]"}
                              checked={checked} 
                              value={d.id}
                              onChange={event => handleSelectClick(event, d.id)}
                              key={"cb_"+d.id}
                            />
                          )}
                        </td>
                      }
                      { Object.keys(datamap).map( (k, i) => {
                        let v = '';
                        if(k.includes(".")){
                          let ks = k.split(".");
                          v = d[ks[0]];
                          ks.map((vv, indx) => {
                            if(indx !== 0){
                              v = v[vv];
                            }
                            return <></>;
                          })
                        }else{
                          v = d[k];
                        }
                        if(datamap[k].func){
                          return (
                            <td key={i}>{datamap[k].func(v, datamap[k].params)}</td>
                          )
                        }else{
                          return (
                            <td key={i}>{v}</td>
                          )
                        }
                      })}
                      </>
                      {/* { (canUpdate || canDelete) &&
                        <td className="cell-action">
                          { canUpdate && 
                            <ButtonX 
                            className="btn btn-xs btn-primary btn-icon"
                            title="Edit"
                            icon="fa fa-edit"
                            //onClick={this.showform.bind(this, d)}
                            />
                          }
                          &nbsp;
                          { canDelete && 
                            <ButtonX 
                            className="btn btn-xs btn-danger btn-icon"
                            title="Delete"
                            icon="flaticon2-rubbish-bin-delete-button"
                            //onClick={this.showdelete.bind(this, d)}
                            />
                          }
                        </td>
                      } */}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) :(
            <h3>No Record found!</h3>
          )}
        </div>
      )
    }
    return <div className="col-md-12"><h2>No data to show, please click generate</h2></div>;
  }

  const showSummary = () => {
    if(show_details && record_id){
      return (
        <Payslip record={payroll_record} company={company_form} income={income} current_income={current_income} deduction={deduction} ytd={ytd} ytd_per_item={ytd_per_item} employee={employee_form} salary={salary_form}/>
      )
    }
    return <></>;
  }

  const handleEmployeeChange = (e, object) => {
    let value = '';
    if(e){
      value = e.value;
    }
    setEmployeeID(value);
  }

  const setupForm = () => {
    
    const disabled = show_details;

    let employee_id = employeeID;
    let salary = {};
    if(employee_form){
      employee_id = employee_form.id;
    }
    if(salary_form){
      salary = salary_form;
    }else if(currentemployee && currentemployee['salary']){
      salary = currentemployee['salary'];
    }

    if(currentID && !employee_id){
      return <></>;
    }

    const start_end_value = [salary && salary['start_date'] ? salary['start_date'] : '', salary && salary['end_date'] ? salary['end_date'] : ''];
    console.log(employee_id, salary, start_end_value);
    return (
      <Form
        noValidate
        onSubmit={e => handleSetup(e)}
      >
      <FormX 
        validated={validatedSetup}
        fields={[
          [
            [
              { 
                name : 'employee', 
                label : 'Employee',
                type : 'select', 
                size : 12,
                idField: 'id',
                required : true,
                valueField: 'user_info.fullname',
                selections : selections ? selections['employees'] : [],
                value : selections && employee_id ? employee_id : [],
                disabled : disabled,
                onChange : handleEmployeeChange
              },
              { 
                label_start : 'Start',
                label_end : 'End',
                name_start : 'start_date',
                name_end : 'end_date',
                type : 'daterange', 
                size : 12,
                required : true, 
                clearable : true,
                value : start_end_value,
                disabled : disabled,
                useNow : true,
              },
              {
                name : 'submit',
                label : 'Start Generate',
                text : 'Generate',
                type : 'button',
                icon : 'flaticon-refresh',
                variant : 'primary',
                className : 'btn-sm btn-block',
                size : 12,
                loading : loading,
                disabled : disabled,
              },
            ]
          ]
        ]}
      />
      </Form>
    )
  }

  return (
    <div className='row'>
      { !isView &&
        <div className='col-xl-2 col-md-4 col-xs-12'>
          <Portlet>
            <PortletHeader title="SETUP"/>
            <PortletBody>
              {setupForm()}
            </PortletBody>
          </Portlet>
        </div>
      }
      <Form
        noValidate
        onSubmit={e => handleSubmit(e)}
        className={ isView ? "col-xl-12 col-md-12 col-xs-12" : "col-xl-10 col-md-8 col-xs-12"}
      >
        <Portlet>
          <PortletHeader title={"DETAILS - " + (employee_form ? employee_form.name : '')} toolbar={(
            <PortletHeaderToolbar>
              { show_details &&
                <>
                  <span className="pr-2">
                    { isView ? (
                      <Link to={currentUrl.replace("/view", "/update")} className={"btn btn-sm btn-success"} title="Edit">
                        <i className="fa fa-edit"></i> Edit
                      </Link>
                    ) : (
                      <ButtonX 
                        text={ record_id ? "Update" : "Generate" }
                        icon="fa fa-save"
                        type="submit"
                        className="btn btn-sm btn-success"
                        loading={loading}
                      />
                    )}
                  </span>
                  <span className="pr-2">
                    { record_id ? (
                      <ButtonX 
                        className="btn btn-sm btn-outline-dark"
                        text={ isView ? "Back" : "Cancel"}
                        onClick={() => props.history.push("/payroll/records") }
                        icon="fa fa-arrow-left"
                      />
                    ) : (
                      <ButtonX 
                        className="btn btn-sm btn-outline-dark"
                        text="Cancel"
                        onClick={() => { setFormData({}) }}
                        icon="fa fa-arrow-left"
                      />
                    )}
                  </span>
                </>
              }
            </PortletHeaderToolbar>)}
          />
          <PortletBody >
            { loading ? (
              <div className="text-center">
                <Spinner animation="border"/>
              </div>
            ) : (
              <>
                <div className="row">
                  { showForms() }
                </div>
                <div className="row">
                  { showSummary() }
                </div>
                <div className="kt-separator kt-separator--dashed"></div>
                <div className="row">
                  {showSheets()}
                </div>
              </>
            )}
          </PortletBody>
        </Portlet>
      </Form>
    </div>
  )
}

export default withRouter(Create);