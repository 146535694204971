import React from "react";

import { withRouter } from "react-router-dom";

import { Badge, Form } from 'react-bootstrap';
import Listx from "app/components/listx";
import ButtonX from "app/components/buttonx";
import GlobalCrud from "app/crud/global.crud";
import FormX from "app/components/formx";

class Salaries extends Listx {

  constructor(props) {
    super(props);

    this.refresh_on_update = true;

    this.headings = [
      { id: 'effective_date', label: 'Effective Date' },
      { id: 'salary_type_name', label: 'Type' },
      { id: 'salary_frequency_name', label: 'Frequency' },
      { id: 'amount', label: 'Amount' },
      { id: 'note', label: 'Note' },
      { id: 'active', label: 'Status' },
      { id: 'created', label: 'Date/Time' },
    ];

    this.admin_api = new GlobalCrud(props.path);
    this.salary_api = new GlobalCrud(props.crudUrl.getBaseUrl());
    this.useModal();
  }

  renderRowData(data){
    const { canCreate, canUpdate } = this.props;

    let append_buttons = [];

    if(canCreate){
      append_buttons.push((
        <a className="kt-nav__link" onClick={this.componentNewShowform.bind(this, data.id)} href="/">
          <i className="kt-nav__link-icon flaticon-plus" />
          <span className="kt-nav__link-text">New Component</span>
        </a>
      ));
    }
    return [
      this.renderCell(data.effective_date),
      this.renderCell(data.salary_type_name),
      this.renderCell(data.salary_frequency_name),
      this.renderCell(data.amount_text),
      this.renderCell(data.note),
      this.renderCell(<Badge variant={data.active ? 'success' : 'danger'}>{data.active ? 'Active' : 'Inactive'}</Badge>),
      this.renderCell(data.created),
      this.renderCell(
        <>
          { canUpdate &&
            <span className="pr-2">
              { data.active ? (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'active', false, 'salaries')} 
                icon="flaticon2-cross" 
                className="btn btn-warning btn-xs btn-icon" 
                title="Deactivate" 
                loading={this.state.action_loading}
                />
              ) : (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'active', true, 'salaries')} 
                icon="flaticon2-checkmark" 
                className="btn btn-success btn-xs btn-icon" 
                title="Activate" 
                loading={this.state.action_loading}
                />
              )}
            </span>
          }
          {this.defaultActions(data.id, data, true, append_buttons)}
        </>
      , null, true),
    ];
  }

  renderSubRowData(data){
    const { canUpdate, canDelete } = this.props;

    return [
      this.renderCell(
        <strong>{ data.name }</strong>,
        { colSpan : 2, align: 'center'}
      ),
      this.renderCell(
        <>{ data.salary_frequency_name }</>
      ),
      this.renderCell(
        <>{ data.amount_text }</>,
        { colSpan : 2}
      ),
      this.renderCell(
        <></>,
      ),
      this.renderCell(
        <>{ data.created }</>,
      ),
      this.renderCell(
        <>
        { canUpdate && 
          <span className="pr-2">
            <ButtonX 
              className="btn btn-xs btn-primary btn-icon"
              title="Edit"
              icon="flaticon-edit"
              onClick={this.componentShowform.bind(this, data)}
            />
          </span>
        }
        { canDelete && 
          <span className="pr-2">
            <ButtonX 
              className="btn btn-xs btn-danger btn-icon"
              title="Delete"
              icon="flaticon2-rubbish-bin-delete-button"
              onClick={this.componentShowdelete.bind(this, data.salary, data.id)}
            />
          </span>
        }
        </>,
        { colSpan : 2, align : 'center'}
      ),
    ];
  }

  updateStatus(id, mode, bool, apiPath, e){
    let formdata = { field : mode, value : bool };
    if(e instanceof FormData){
      formdata = e;
    }
    
    let newData = [...this.state.data];

    this.setState({ action_loading : true });
    this.admin_api.postRecord(apiPath+"/"+id+"/update", formdata).then(({ data }) => {
      //this.updatePage();
      if(apiPath === 'components'){
        id = data.salary;
      }
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      if(apiPath === 'components'){
        let sub_row_index = prevData.sub_rows.findIndex(d => d.id === data.id);
        if(mode){
          prevData.sub_rows[sub_row_index][mode] = bool;
        }else{
          for (var pair of formdata.entries()) {
            var matches = pair[0].match(/\[(.*?)\]/);
            if (matches) {
                var submatch = matches[1];
                prevData.sub_rows[sub_row_index][submatch] = pair[1];
            }
          }
        }
      }else{
        //salary
        prevData[mode] = bool;
        newData.map((v, i) => {
          if(i !== index){
            newData[i][mode] = !bool;
          }
          return <></>;
        })
      }
      //reset
      newData[index] = prevData;
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      //this.setState({ action_loading : false });      
      this.setState({ 
        action_loading : false,
        modalShow : false,
        data: newData,
        form_validated: false,
      });
    });
  }

  fields(values){    
    const { selections } = this.props;

    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          { 
            name : 'effective_date', 
            label : 'Effect Date',
            type : 'date', 
            required : true, 
            size : 4,
            value : values && values['effective_date'] ? values['effective_date'] : ''
          },
          { 
            name : 'salary_type', 
            type : 'select', 
            required : true, 
            size : 4,
            value : values && values['salary_type'] ? values['salary_type'] : '',
            selections : selections && selections['salary_types'] ? selections['salary_types'] : [],
          },
          { 
            name : 'salary_frequency', 
            type : 'select', 
            required : true, 
            size : 4,
            value : values && values['salary_frequency'] ? values['salary_frequency'] : '',
            selections : selections && selections['salary_frequencies'] ? selections['salary_frequencies'] : [],
          },
          { 
            name : 'currency', 
            type : 'select', 
            required : true, 
            size : 6,
            value : values && values['currency'] ? values['currency'] : '',
            selections : selections && selections['currencies'] ? selections['currencies'] : [],
          },
          { 
            name : 'amount', 
            type : 'number', 
            required : true,
            size : 6,
            value : values && values['amount'] ? values['amount'] : ''
          },
          { 
            name : 'note', 
            type : 'textarea', 
            size : 12,
            value : values && values['note'] ? values['note'] : ''
          },
          { 
            name : 'active', 
            type : 'checkbox', 
            label : 'Is Active',
            required : false, 
            size : 12,
            value : values && values['active'] ? values['active'] : false
          },
        ]
      ]
    ];
  }

  handleComponentSubmit(salary_id, event) {
    const form = event.currentTarget;
    this.setState({ action_loading : true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      //create
      this.salary_api.postRecord(salary_id+'/components', data)
      .then(( {data} ) => {
        let newData = [], doNotUpdate = false;
        this.post_status_fields.map( f => doNotUpdate = (data[f] !== undefined && data[f] === false));
        if(this.state.data.some(item => data.id === item.id)){
          //update
          newData = [...this.state.data];  
          let index = newData.findIndex(d => d.id === data.id);
          if(doNotUpdate){
            newData.splice(index, 1);
          }else{
            newData[index] = data;
          }
        }else{
          newData = this.state.data;
          if(!doNotUpdate){
            newData = [...newData, data];
          }
        }
        //this.updatePage();
        this.setState({ 
          action_loading : false,
          modalShow : false,
          data: newData
        });
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        alert(msg.join("\r\n"));
        this.setState({ 
          action_loading : false,
        });
      });
    }
  }

  componentNewShowform(salary_id, e){
    e.preventDefault();
    let f = (<Form
      noValidate
      onSubmit={e => this.handleComponentSubmit(salary_id, e)}
      validated={false}
      id="modal_component_form"
    >
    {this.componentForm({'salary' : salary_id})}
    </Form>);
    
    this.setState({ 
      modalShow: true, 
      modalTitle : "Add component", 
      modalBody : f,
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'modal_component_form',
    })
  }
  
  componentShowform(d, e){

    let f = (<Form
      noValidate
      onSubmit={e => this.handleComponentSubmit(d.salary, e)}
      validated={false}
      id="modal_component_form"
    >
    {this.componentForm(d)}
    </Form>);
    
    this.setState({ 
      modalShow: true, 
      modalTitle : (d ? 'Edit ' : 'Add ' ) + "component", 
      modalBody : f,
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'modal_component_form',
    })
  }
  
  componentShowdelete(salary_id, d, e){
    this.setState({ 
      modalShow: true,
      modalTitle : 'Confirmation', 
      modalBody : 'Are you sure you want to delete this component?',
      modalSubmitTitle : 'Delete',
      modalSubmitVariant : 'danger',
      modalSubmit : this.executeComponentDelete.bind(this, salary_id, d),
    })
  }

  executeComponentDelete(salary_id, id, e){
    this.setState({ action_loading : true });
    this.salary_api.deleteRecord(salary_id+'/components', id).then(( {data} ) => {
      let newData = this.state.data.filter((d) => d.id !== data.id);
      this.setState(prevState => ({
        action_loading : false,
        modalShow : false,
        data : [...newData, data],
      }));
    })
    .catch(error => {
      let msg = [];
      Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      alert(msg.join("\r\n"));
      this.setState({ 
        action_loading : false,
      });
    });
  }

  componentForm(data){
    return <FormX fields={this.componentFields(data)}/>
  }

  componentFields(values){    
    const { selections } = this.props;
    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          {
            name : 'salary',
            type : 'hidden',
            value : values && values['salary'] ? values['salary'] : '',
          },
          { 
            name : 'name', 
            type : 'text', 
            required : true, 
            size : 6,
            value : values && values['name'] ? values['name'] : '',
          },
          { 
            label : 'Frequency', 
            name : 'salary_frequency', 
            type : 'select', 
            required : true, 
            size : 6,
            value : values && values['salary_frequency'] ? values['salary_frequency'] : '',
            selections : selections && selections['salary_frequencies'] ? selections['salary_frequencies'] : [],
          },
          { 
            name : 'currency', 
            type : 'select', 
            required : true, 
            size : 6,
            value : values && values['currency'] ? values['currency'] : '',
            selections : selections && selections['currencies'] ? selections['currencies'] : [],
          },
          { 
            name : 'amount', 
            type : 'number', 
            required : true,
            size : 6,
            value : values && values['amount'] ? values['amount'] : ''
          },
          { 
            name : 'note', 
            type : 'textarea', 
            size : 12,
            value : values && values['note'] ? values['note'] : ''
          },
        ]
      ]
    ];
  }
}

export default withRouter(Salaries);