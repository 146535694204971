import React from "react";
import { Table } from 'react-bootstrap';
import { capitalize } from "_metronic/utils/utils";

import FormX from "./formx";

export default function Payslip(props){

  const { company, income, deduction, current_income, ytd, ytd_per_item, employee, salary, record } = props;
  const { start_date: period_start, end_date: period_end, pay_date } = salary;

  const hidden_fields = [
    [
      [
        {
          name : 'record', 
          type : 'hidden',
          value : record ? record.id : 0,
        },
        { 
          name : 'employee', 
          type : 'hidden',
          value : employee.id,
        },
        { 
          name : 'start_date', 
          type : 'hidden',
          value : period_start,
        },
        { 
          name : 'end_date', 
          type : 'hidden',
          value : period_end,
        },
        { 
          name : 'pay_date', 
          type : 'hidden',
          value : pay_date,
        },
      ]
    ]
  ];
  
  const createRow = (_type, _name, d) => {
    let tmp = {
      income : '', 
      rate : '', 
      type_value : '', 
      income_total : '', 
      deduction : '', 
      deduction_total: '', 
      ytd : ''
    };
    tmp[_type] = capitalize(_name);
    Object.keys(d).map( _key => tmp[_key] = d[_key]);
    if(ytd_per_item[_type]){
      if(ytd_per_item[_type][_name]){
        tmp['ytd'] = ytd_per_item[_type][_name];
      }
    }
    return tmp;
  }

  // table_row = {'income' : '', 'rate' : '', 'type_value' : '', 'income_total' : '', 'deduction' : '', 'deduction_total': '', 'ytd' : ''}
  //   table_rows = []

  //   types = ['income', 'deduction']
  //   for _type in types:
  //       if record[_type]:
  //           for k, v in record[_type].items():
  //               tmp = table_row.copy()
  //               tmp[_type] = k.capitalize()
  //               for ke, kv in v.items():
  //                   tmp[ke] = kv
  //               if _type in ytd_per_item:
  //                   if k in ytd_per_item[_type]:
  //                       tmp['ytd'] = ytd_per_item[_type][k]
  //               table_rows.append(tmp)

    
  //   total_length = len(table_rows)
  //   min_data_rows = min_data_rows if min_data_rows > total_length else total_length
  let max_list_length = 7;
  let table_rows = [];  
  if(income){
    Object.keys(income).map( _name => table_rows.push(createRow("income", _name, income[_name])));
  }
  if(deduction){
    Object.keys(deduction).map( _name => table_rows.push(createRow("deduction", _name, deduction[_name])));
  }

  max_list_length = max_list_length > table_rows.length ? max_list_length : table_rows.length;
  
  let content = [];
  for (let rr = 0; rr < max_list_length; rr++) {
    content.push(
      <tr key={rr}>
        { table_rows[rr] ? (
          <>
          <td>{table_rows[rr].income}</td>
          <td>{table_rows[rr].rate}</td>
          <td>{table_rows[rr].type_value}</td>
          <td>{table_rows[rr].income_total}</td>
          <td style={{ borderLeft: '2px solid #000000' }}>{table_rows[rr].deduction}</td>
          <td>{table_rows[rr].deduction_total}</td>
          <td>{table_rows[rr].ytd}</td>
          </>
        ) : (
          <>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ borderLeft: '2px solid #000000' }}>&nbsp;</td>
          <td></td>
          <td></td>
          </>
        )}
      </tr>
    )
  }

  return (
    <>
      <FormX fields={hidden_fields} />
      <Table size="sm" bordered hover responsive style={{ textAlign : 'center', verticalAlign : 'middle', border: '2px solid #000000'}}>
        <tbody>
          <tr>
            <th colSpan={5} className="badge-info">COMPANY NAME</th>
            <th rowSpan={4} colSpan={2} style={{ verticalAlign : 'middle' }}><h2>EARNING STATEMENT{record ? <><br/>{record.no}</> : ''}</h2></th>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign : 'left', paddingLeft : 20 }}>{company && company.name ? company.name : <>&nbsp;</>}</td>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign : 'left', paddingLeft : 20  }}>{company && company.address_1 ? company.address_1 : <>&nbsp;</>}</td>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign : 'left', paddingLeft : 20  }}>{company && company.address_2 ? company.address_2 : <>&nbsp;</>}</td>
          </tr>
          <tr>
            <th colSpan={2} style={{ textAlign : 'left' }} className="badge-info">EMPLOYEE NAME</th>
            <th className="badge-info">SSS</th>
            <th className="badge-info">EMPLOYEE ID</th>
            <th colSpan={2} className="badge-info">PAY PERIOD</th>
            <th className="badge-info">PAY DATE</th>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign : 'left', paddingLeft : 20  }}>{ employee.user_info ? employee.user_info.fullname : employee.name }</td>
            <td rowSpan={3} style={{ verticalAlign : 'middle', borderLeft: '2px solid #000000' }}></td>
            <td rowSpan={3} style={{ verticalAlign : 'middle', borderLeft: '2px solid #000000' }}>{ employee.companyid }</td>
            <td rowSpan={3} colSpan={2} style={{ verticalAlign : 'middle', borderLeft: '2px solid #000000' }}>{ period_start } - { period_end }</td>
            <td rowSpan={3} style={{ verticalAlign : 'middle', borderLeft: '2px solid #000000' }}>{ pay_date }</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign : 'left', paddingLeft : 20  }}>{ employee.user_info ? employee.user_info.address : <>&nbsp;</> }</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign : 'left', paddingLeft : 20  }}>{ employee.user_info ? employee.user_info.address_2 : <>&nbsp;</> }</td>
          </tr>
          <tr>
            <th className="badge-info">INCOME</th>
            <th className="badge-info">RATE</th>
            <th className="badge-info">DAYS</th>
            <th className="badge-info">CURRENT TOTAL</th>
            <th className="badge-info">DEDUCTIONS</th>
            <th className="badge-info">CURRENT TOTAL</th>
            <th className="badge-info">YEAR-TO-DATE</th>
          </tr>
          { content }
          <tr>
            <th className="badge-info">CURRENT TOTAL</th>
            <th colSpan={2} className="badge-info">CURRENT DEDUCTIONS</th>
            <th className="badge-info">NET PAY</th>
            <th className="badge-info">YTD</th>
            <th className="badge-info">YTD DEDUCTIONS</th>
            <th className="badge-info">YTD NET PAY</th>
          </tr>
          <tr>
            <td >{current_income.income}</td>
            <td colSpan={2}>{current_income.deduction}</td>
            <td>{current_income.net}</td>
            <td style={{borderLeft: '2px solid #000000'}}>{ytd.income}</td>
            <td>{ytd.deduction}</td>
            <td>{ytd.net}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}