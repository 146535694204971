import React from "react";
import {
  withRouter,
} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../../components/crudpageX";

const SettingsShiftsSchedules = (props) =>{

  const fields = (props, state, params = null) => {
    let fields = [
      [
        [
          {
            name : 'shift',
            type : 'hidden',
            value : params ? params.id : ''
          },
          { 
            name : 'day', 
            type : 'select', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['day'] ? props.initialValues['day'] : '',
            selections : state.selections['days'],
          },
          { 
            name : 'start', 
            type : 'time', 
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['start'] ? props.initialValues['start'] : '09:00:00',
            useNow : true,
          },
          { 
            name : 'duration', 
            label : 'Duration (hrs)',
            type : 'slider', 
            min : 3600,
            step : 3600,
            max : 86400,
            unit : 'hours',
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['duration'] ? props.initialValues['duration'] : 32400,
            useNow : true,
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props} backLink="/hr/settings/shifts"/>
  }

  const { url } = props.match;
  const { access } = props;
  return (
    <CrudPageX 
    List={list}
    path={url} 
    apiPath={url} 
    form_size='6'
    fields={fields}
    title='Shift Schedule'
    params={props.match.params}
    getData={true}
    {...access}
    />
  )
}

export default withRouter(SettingsShiftsSchedules);