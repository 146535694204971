import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../_metronic";

export default function TimesheetStats(props) {

  const ref = useRef();
  const { successColor, brandColor, shape2Color, shape3Color, warningColor } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.2"
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.3"
    ),
    successColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.success"
    ),
    warningColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.warning"
    )
  }));

  const { timesheets, requests } = props.data;

  const timesheet_data = timesheets.data;
  let actual_work_hrs = [],
  expected_work_hrs = [],
  work_hrs = [];

  if(Object.keys(timesheet_data).length > 0){
    const data_keys = Object.keys(timesheet_data);
    data_keys.sort();
    data_keys.map( (date) => {
      let dt_key = date;
      if(props.mode === 'yearly'){
        dt_key = parseInt(date);
      }
      if(!timesheets.date_range.includes(dt_key)){
        console.log(props.mode, typeof dt_key, dt_key, timesheets.date_range.includes(dt_key));
        return false;
      }
      actual_work_hrs.push({
        x : date,
        y : Math.floor(timesheet_data[date].actual/3600),
      });
      expected_work_hrs.push({
        x : date,
        y : Math.floor(timesheet_data[date].expected/3600),
      })
      work_hrs.push({
        x : date,
        y : Math.floor(timesheet_data[date].work/3600),
      })
      return <></>;
    })
  }

  const data = useMemo(
    () => ({
      labels: timesheets.date_range,
      datasets: [
        {
          label: 'Expected Attendance (hrs)',
          lineTension : 0,
          backgroundColor: Chart.helpers
            .color(successColor)
            .alpha(0.8)
            .rgbString(),
          data: expected_work_hrs
        },
        {
          label: 'Actual Attendance (hrs)',
          lineTension : 0,
          backgroundColor: Chart.helpers
            .color(brandColor)
            .alpha(0.6)
            .rgbString(),
          data: actual_work_hrs
        },
        {
          label: 'Work (hrs)',
          lineTension : 0,
          backgroundColor: Chart.helpers
            .color(warningColor)
            .alpha(0.6)
            .rgbString(),
          data: work_hrs
        },
      ]
    }),
    [brandColor, actual_work_hrs, expected_work_hrs, successColor, timesheets.date_range, warningColor, work_hrs]
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
      data,
      type: "line",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                suggestedMax : 10,
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10,
                callback:(v)=> {
                  return v+"HR"
                },
                stepSize:1, //add a tick every 5 minutes
              }
            }
          ]
        },
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: brandColor,
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, brandColor, shape2Color, shape3Color]);

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__content">
        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Attendance</span>
            <span className="kt-widget12__value">{timesheets.total_timesheet} / {timesheets.total_days}</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Date Range</span>
            <span className="kt-widget12__value">{ timesheets.date_range[0] } - { timesheets.date_range.slice(-1)[0] }</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Requests</span>
            <span className="kt-widget12__value">{ requests.approved_count } / { requests.count }</span>
          </div>
        </div>
        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Average Hours</span>
            <span className="kt-widget12__value">{timesheets.average_actual_hrs}hr{ timesheets.average_actual_hrs >= 2 ? 's' : ''}</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Average Efficiency</span>
            <span className="kt-widget12__value">{timesheets.average_efficiency}%</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Rate</span>
            <div className="kt-widget12__progress">
              <div className="progress kt-progress--sm">
                <div
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuenow={100}
                  aria-valuemax={100}
                  style={{ width: timesheets.rating+"%" }}
                  className={timesheets.rating < 75 ? "progress-bar bg-danger" : "progress-bar bg-success"}
                />
              </div>
              <span className="kt-widget12__stat">{timesheets.rating}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="kt-widget12__chart" style={{ height: "300px" }}>
        <canvas
          ref={ref}
          id="kt_chart_timesheet_statistics"
        />
      </div>
    </div>
  );
}
