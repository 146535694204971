import React from "react";
import {
  Link,
} from "react-router-dom";

import { Form, ButtonGroup } from 'react-bootstrap';

import DataTable from "./datatable2/table";

import queryString from 'query-string'
import ListFilterX from "./listFilterx";
import ModalX from './modalx';
import ButtonX from './buttonx';
import FormX from './formx';
import Dropdown from "react-bootstrap/Dropdown";

class ListX extends React.Component {

  constructor(props) {
    super(props);

    this.headings = [];
    this.post_status_fields = [];
    this.refresh_on_update = false;
    this.title = "Records";
    this.overrideCanCreate = null;

    const { page, per_page, ...other_search } = queryString.parse(props.location.search);
    this.currentPage = 1;
    this.currentPerPage = 20;
    if(page){
      this.currentPage = page;
    }
    if(per_page){
      this.currentPerPage = per_page;
    }
    
    this.filterbar = false;
    this.use_modal = false;
    
    this.state = {
      data : [],
      totals : null,
      pagination : 1,
      currentPage : this.currentPage,
      currentPerPage : this.currentPerPage,
      search : {...other_search},
      loading : true,
      reload_once : false,
      mounted : false,
      modalShow : false,
      modalTitle : '',
      modalBody : null,
      modalSubmit : null,
      modalSubmitTitle : '',
      modalSubmitVariant : '',
      action_loading : false,
      form_validated : false,
      anchor_dropdown : undefined,
    };

    this.setFilterBar = this.setFilterBar.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.modalClose = this.modalClose.bind(this);

  }

  useModal(){
    this.use_modal = true;
  }

  setFilterBar(fields, advance_fields){
    this.filterbar = () => <ListFilterX fields={fields} advance_fields={advance_fields} selections={this.props.selections} search={this.state.search} isAdmin={this.props.isAdmin} onChange={this.updateSearch.bind(this)} />;
  }

  setPage(p, pp) {
    if(parseInt(this.state.currentPerPage) !== pp){
      p = 1;
    }
    this.setState({currentPage : p, currentPerPage : pp}, function() { this.updateSearch({page : p, per_page : pp}) });
  }

  updateSearch(new_filter){
    const { ...old_filter } = queryString.parse(this.props.location.search);
    let filter = {...old_filter, ...new_filter }

    console.log(old_filter, new_filter);
    if(filter.show_advance_filter && parseInt(filter.show_advance_filter) !== 1){
      console.log(this.advance_fields);
    }
    for (var propName in filter) {
      if (filter[propName] === null || filter[propName] === undefined) {
        delete filter[propName];
      }
    }

    this.setState({ search : filter }, () => {
      let filterString = new URLSearchParams(filter);
      //filterString.set('page', page);
      this.props.history.push({ search: filterString.toString()});
    })
  }

  updatePage(){
    if(!this.state.mounted){
      return;
    }
    this.setState({ loading: true });
    const { crudUrl } = this.props;
    crudUrl.get(this.state.currentPage, this.state.search, this.state.currentPerPage).then(({ data: { data, pagination, totals } }) => {
      this.setState({
        data : data,
        pagination : pagination,
        loading : false,
        totals : totals,
      });
    })
    .catch((error) => {
      //console.log(error);
      if(error.status === 404 && !this.state.reload_once){
        // this.setState({
        //   reload_once : true,
        // }, () => { this.updateSearch({page : 1}); });
        
        this.setState({
          data : [],
          loading : false,
        });
      }else{
        this.setState({
          data : [],
          loading : false,
        });
      }
    });
  }

  componentDidMount(){
    if(!this.state.mounted){
      this.setState({ mounted : true }, () => {this.updatePage();});
    }else{
      this.updatePage();
    }
  }

  componentWillUnmount(){
    this.setState({ mounted : false });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.module === this.props.parent){
      if (prevProps.redraw !== this.props.redraw) {
        this.updatePage();
        return;
      }
    }
    if(this.props.crudUrl.getBaseUrl() !== prevProps.crudUrl.getBaseUrl()){
      this.updatePage();
      return;
    }
    if(prevProps.location.search !== this.props.location.search){
      this.updatePage();
      return;
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   //console.log(this.state.loading, nextState.loading);
  //   if(this.state.loading !== nextState.loading){
  //     console.log(this.state.loading, this.state.data, nextState.loading, nextState.data);
  //     return true;
  //   }
  //   return false;
  // }

  defaultActions(id, data, use_dropdown, append_buttons, base_url) {
    use_dropdown = use_dropdown || true;
    
    const { baseUrl, canUpdate, canDelete, crudUrl } = this.props;
    
    let base_uri = (baseUrl ? baseUrl : crudUrl.getBaseUrl());

    if(base_url){
      base_uri = base_url;
    }

    let allow_update = canUpdate,
    allow_delete = canDelete;

    if(data){
      const { access } = data;
      if(access){
        allow_delete = access.delete;
        allow_update = access.edit;
      }
    }
    let buttons = [];

    if(append_buttons){
      buttons = append_buttons
    }

    if(this.use_modal){
      if(allow_update){
        if(use_dropdown){
          buttons.push(
            <a className="kt-nav__link" onClick={this.showform.bind(this, data)} href="/">
              <i className="kt-nav__link-icon flaticon-edit" />
              <span className="kt-nav__link-text">Edit</span>
            </a>
          )
        }else{
          buttons.push(
            <ButtonX 
              className='btn btn-xs btn-icon'
              variant='primary'
              title="Edit"
              icon="flaticon-edit"
              onClick={this.showform.bind(this, data)}
            />
          )
        }
      }
      if(allow_delete){
        if(use_dropdown){
          buttons.push(
            <a className="kt-nav__link" onClick={this.showdelete.bind(this, id)} href="/">
              <i className="kt-nav__link-icon flaticon2-rubbish-bin-delete-button" />
              <span className="kt-nav__link-text">Delete</span>
            </a>
          )
        }else{
          buttons.push(
            <ButtonX 
              className='btn btn-xs btn-icon'
              variant='danger'
              title="Delete"
              icon="flaticon2-rubbish-bin-delete-button"
              onClick={this.showdelete.bind(this, id)}
            />
          )
        }
      }
    }else{
      if(allow_update){
        buttons.push(
          <Link to={base_uri+'/update/'+id} className={use_dropdown ? 'kt-nav__link' : "btn btn-xs btn-primary btn-icon"} title="Update">
            <i className={ use_dropdown ? 'kt-nav__link-icon flaticon-edit' : "flaticon-edit"}></i>
            { use_dropdown &&
            <span className="kt-nav__link-text">Edit</span>
            }
          </Link>
        )
      }
      if(allow_delete){
        buttons.push(
          <Link to={base_uri+'/delete/'+id} className={use_dropdown ? 'kt-nav__link' : "btn btn-xs btn-danger btn-icon"} title="Delete">
            <i className={ use_dropdown ? 'kt-nav__link-icon flaticon2-rubbish-bin-delete-button' : "flaticon2-rubbish-bin-delete-button"}></i>
            { use_dropdown &&
            <span className="kt-nav__link-text">Delete</span>
            }
          </Link>
        )
      }
    }

    return (
      <>
      { use_dropdown && buttons.length > 0 ? (
        <span className="pr-2">
        <Dropdown as={ButtonGroup} style={{position: 'static'}}>
          <Dropdown.Toggle variant="secondary" className="btn-label-brand btn-bold btn-xs no-caret btn-icon">
            <i className="flaticon-more-v5"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false}>
            <ul className="kt-nav">
              <li className="kt-nav__section kt-nav__section--first">
                <span className="kt-nav__section-text">Choose an action:</span>
              </li>
              {buttons.map( (button, index) => (
                <li key={index} className="kt-nav__item">
                  {button}
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        </span>
      ) : (
        buttons.map( button => (
          <span className="pr-2">
            {button}
          </span>
        ))
      )}
      </>
    )
  }
  
  renderRowData(){
    return [];
  }

  renderSubRowData(){
    return [];
  }

  renderRelatedRowData(){
    return [];
  }
  
  renderTotalRow(){
    return null;
  }

  renderCell = (content, props, action) => {
    return {
      content : content,
      props : props,
      isAction : action || false,
    };
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    this.setState({ action_loading : true, form_validated: true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      const { crudUrl } = this.props;
      //create
      crudUrl.create(data)
      .then(( {data} ) => {
        if(!this.refresh_on_update){
          let newData = [], doNotUpdate = false;
          this.post_status_fields.map( f => doNotUpdate = (data[f] !== undefined && data[f] === false));
          if(this.state.data.some(item => data.id === item.id)){
            //update
            newData = [...this.state.data];  
            let index = newData.findIndex(d => d.id === data.id);
            if(doNotUpdate){
              newData.splice(index, 1);
            }else{
              newData[index] = data;
            }
          }else{
            newData = this.state.data;
            if(!doNotUpdate){
              newData = [...newData, data];
            }
          }

          //this.updatePage();
          this.setState({ 
            data: newData,
          });
        }
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        alert(msg.join("\r\n"));
      }).then(() => {
        this.setState({ 
          modalShow : false,
          action_loading : false,
          form_validated: false,
        }, () => {
          if(this.refresh_on_update){
            this.updatePage();
          }
        });
      });
    }
  }

  executeDelete(id, e){
    this.setState({ action_loading : true });
    const { crudUrl } = this.props;
    crudUrl.delete(id).then(( {data} ) => {
      let newData = this.state.data.filter((d) => d.id !== id);
      this.setState(prevState => ({
        action_loading : false,
        modalShow : false,
        data : newData,
      }));
    })
    .catch(error => {
      let msg = [];
      Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      alert(msg.join("\r\n"));
      this.setState({ 
        action_loading : false,
      });
    });
  }

  fields(data){
    return [];
  }

  form(data){
    return <FormX fields={this.fields(data)}/>
  }

  showcustomform(onSubmit, url, form, title){

    let f = (<Form
      noValidate
      onSubmit={e => onSubmit(e, url)}
      validated={false}
      id="custom_modal_form"
    >
    {form}
    </Form>);
    
    this.setState({ 
      anchor_dropdown : undefined,
      modalShow: true, 
      modalTitle : title, 
      modalBody : f,
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'custom_modal_form',
    })
  }
  
  showform(d, e){
    e.preventDefault();
    const { title } = this.props;

    let f = (<Form
      noValidate
      onSubmit={e => this.handleSubmit(e)}
      validated={false}
      id="modal_form"
    >
    {this.form(d)}
    </Form>);
    
    this.setState({ 
      anchor_dropdown : undefined,
      modalShow: true, 
      modalTitle : (d ? 'Edit ' : 'Add ' ) + title, 
      modalBody : f,
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'modal_form',
    })
  }
  

  showdelete(d, e){
    e.preventDefault();
    const { title } = this.props;

    this.setState({ 
      anchor_dropdown : undefined,
      modalShow: true,
      modalTitle : 'Confirmation', 
      modalBody : 'Are you sure you want to delete this ' + title + '?',
      modalSubmitTitle : 'Delete',
      modalSubmitVariant : 'danger',
      modalSubmit : this.executeDelete.bind(this, d),
    })
  }
  
  modalClose(){
    this.setState({ modalShow: false });
  }

  renderModal(){
    return <ModalX
      show={this.state.modalShow}
      title={this.state.modalTitle}
      onHide={this.modalClose}
      body={ this.state.modalBody }
      submit={ this.state.modalSubmit }
      submit_title={ this.state.modalSubmitTitle }
      submit_variant={ this.state.modalSubmitVariant }
      action_loading={this.state.action_loading}
    />
  }

  render(){ 
    const { totals, data, pagination, currentPage, currentPerPage, loading, search } = this.state;
    const { baseUrl, backLink, title, canCreate, canUpdate, canDelete, crudUrl } = this.props;

    //const rows = data.map(this.renderRow);

    //console.log('rendering table', totals, data);
    return (
      <>
        { !loading &&
        <>
        {this.renderModal()}
        </>
        }
        <DataTable 
        onNewClick={ this.props.onNewclick !== undefined ? this.props.onNewclick : (this.use_modal ? this.showform.bind(this, null) : null)}
        orderDirection={search ? search.orderdir : null}
        orderField={search ? search.orderby : null}
        baseUrl={baseUrl ? baseUrl : crudUrl.getBaseUrl()}
        headings={this.headings} 
        rows={data} 
        totals={totals}
        pagination={pagination} 
        renderRowData={this.renderRowData.bind(this)}
        renderSubRowData={this.renderSubRowData.bind(this)}
        renderRelatedRowData={this.renderRelatedRowData.bind(this)}
        renderTotalRow={this.renderTotalRow.bind(this)}
        page={currentPage} 
        per_page={currentPerPage} 
        search={search} 
        title={title ? title : this.title} 
        CustomFilter={ this.filterbar ? this.filterbar : null}
        onPageChanged={this.setPage.bind(this)} 
        onSortChanged={this.updateSearch.bind(this)}
        backLink={backLink}
        loading={loading}
        canCreate={this.overrideCanCreate !== null ? this.overrideCanCreate : canCreate}
        canUpdate={canUpdate}
        canDelete={canDelete}
        />
      </>
    )
  }
}
// Set default props
ListX.defaultProps = {
  canCreate: false,
  canUpdate: false,
  canDelete: false,
  CustomFilter : null,
  backLink : null,
  crudUrl : null,
};


export default ListX;