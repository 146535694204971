import React from "react";
import {
  withRouter,
} from "react-router-dom";
import { Form, Spinner, Col, Badge } from "react-bootstrap";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { passwordTest } from "_metronic/utils/utils";

import AlertX from "app/components/alertx";
import Buttonx from "app/components/buttonx";
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "app/partials/content/Portlet";

class Account extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      validated : false,
      old_password : { value : '', isValid : false, message : ''},
      new_password : { value : '', isValid : false, message : ''},
      new_password_confirm : { value : '', isValid : false, message : ''},
      validation_errors : [],
      error : '',
      success : '',
      action_loading : false,
      verify_loading : false,
    }
    
  }
  
  handleSubmit(event) {
    const { crudUrl, baseUrl } = this.props;

    const form = event.currentTarget;
    this.setState({ validated: true, error: '', success: '', action_loading : true });

    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false || this.state.validation_errors.length > 0) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      //create
      crudUrl.postRecord(baseUrl+"/changepassword", data)
      .then(( {data} ) => {
        this.setState({ 
          action_loading : false,
          success : 'Succesfully updated password!',
        });
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        this.setState({ 
          action_loading : false,
          error : msg.join("\r\n"),
        });
      });
    }
  }

  onChange = (e) => {
    const state = {
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      }
    };

    let updated_field = e.target.name;
    this.setState(state, function() {
      const old_password = { ...this.state.old_password };
      const new_password = { ...this.state.new_password };
      const new_password_confirm = { ...this.state.new_password_confirm };

      switch(updated_field){
        case 'old_password':{
          if(!old_password.value){
            old_password.isValid = false;
            old_password.message = "Required";
          }
          break;
        }
        default:{
          if(!new_password.value){
            new_password.isValid = false;
            new_password.message = "Required";
          }
          if(!new_password_confirm.value){
            new_password_confirm.isValid = false;
            new_password_confirm.message = "Required";
          }
        }
      }
      let validation_errors = [];

      if(old_password.value){
        old_password.isValid = true;
        old_password.message = "";
      }

      if(new_password.value){
        new_password.isValid = true;
        new_password.message = "";
      }

      if(new_password_confirm.value){
        new_password_confirm.isValid = true;
        new_password_confirm.message = "";
      }

      if(new_password.isValid && new_password_confirm.isValid){
        if(new_password.value !== new_password_confirm.value){
          new_password.isValid = false;
          new_password.message = "Password does not match";
          new_password_confirm.isValid = false;
          new_password_confirm.message = "Password does not match";
          validation_errors.push("Password does not match");
        }else{
          let test = passwordTest(new_password.value);
          if(test === true){
            new_password.isValid = true;
            new_password.message = "";
            new_password_confirm.isValid = true;
            new_password_confirm.message = "";
          }else{
            new_password.isValid = false;
            new_password.message = test;
            new_password_confirm.isValid = false;
            new_password_confirm.message = test;
            validation_errors.push(test);         
          }
        }
      }

      this.setState({old_password : old_password, new_password: new_password, new_password_confirm : new_password_confirm, validation_errors : validation_errors});
    });
  }

  verify(e){
    const { baseUrl, crudUrl } = this.props;
    
    crudUrl.postRecord(baseUrl+"/resendverification")
    .then(( {data} ) => {
      this.setState({ 
        verify_loading : false,
      });
      alert("Succesfully sent verification email");
    })
    .catch(error => {
      let msg = [];
      if(error.status === 404){
        msg.push("Error 404");
      }else{
        Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      }
      this.setState({ 
        verify_loading : false,
      });
      alert(msg.join("\r\n"));
    });
  }

  render(){ 
    const { page_title, loading, user } = this.props;
    const { validated, new_password, old_password, new_password_confirm, success, error, action_loading, verify_loading } = this.state;

    return (
      <div className="row">
        <div className="col-xl-8">
          <Portlet>
            <PortletHeader title={ page_title}/>
            <PortletBody>
              { loading ? (
                <div className="text-center">
                  <Spinner animation="border"/>
                </div>
              ) : (
                <>
                { success !== '' &&
                  <AlertX variant="success" message={success}/>
                }
                { error !== '' &&
                  <AlertX variant="danger" message={error}/>
                }
                <Form noValidate onSubmit={e => this.handleSubmit(e)} validated={validated}>
                  <Form.Row>
                    <Form.Group as={Col} md={12}>
                      <TextField
                        type="password"
                        margin="normal"
                        label="Old password"
                        className="kt-width-full"
                        name="old_password"
                        onBlur={this.onChange}
                        onChange={this.onChange}
                        required={true}
                        helperText={!old_password.isValid ? old_password.message : ''}
                        error={Boolean(!old_password.isValid && old_password.message)}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <TextField
                        type="password"
                        margin="normal"
                        label="New password"
                        className="kt-width-full"
                        name="new_password"
                        onBlur={this.onChange}
                        onChange={this.onChange}
                        required={true}
                        helperText={!new_password.isValid ? new_password.message : ''}
                        error={Boolean(!new_password.isValid && new_password.message)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <TextField
                        type="password"
                        margin="normal"
                        label="New password Confirm"
                        className="kt-width-full"
                        name="new_password_confirm"
                        onBlur={this.onChange}
                        onChange={this.onChange}
                        required={true}
                        helperText={!new_password_confirm.isValid ? new_password_confirm.message : ''}
                        error={Boolean(!new_password_confirm.isValid && new_password_confirm.message)}
                      />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <span className="pr-1">
                        <Buttonx type="submit" text="Change Password" variant="success" className="btn-block" loading={action_loading}/>
                      </span>
                    </Form.Row>
                  </Form>
                  </>
                )}
              </PortletBody>
            </Portlet>
        </div>
        <div className="col-xl-4">
          <Portlet>
            <PortletHeader title="Status" toolbar={(
              <PortletHeaderToolbar>
                <Badge variant={user ? (user.is_active ? 'primary' : 'danger') : 'secondary'}>{user ? (user.is_active ? 'Active' : 'Inactive') : 'N/A'}</Badge>
              </PortletHeaderToolbar>
            )}/>
            <PortletBody>
              <div className="row">
                <div className="col-xl-6">
                  <Typography variant="h6" gutterBottom>
                    Date joined
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                  {user ? (user.date_joined ? user.date_joined : 'N/A') : 'N/A'}
                  </Typography>
                </div>
                <div className="col-xl-6">
                  <Typography variant="h6" gutterBottom>
                    Last Login
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {user ? (user.last_login ? user.last_login : 'N/A') : 'N/A'}
                  </Typography>
                </div>
              </div>
              <div className="kt-separator kt-separator--space-md kt-separator--border-dashed" />
              { (user && !user.is_active) && 
              <Buttonx text="Resend Verification" variant="warning" icon="flaticon-email" onClick={this.verify.bind(this)} loading={verify_loading}/>
              }
            </PortletBody>
          </Portlet>
        </div>
      </div>
    )
  }
}

export default withRouter(Account);