import React from "react";

import Info from "./info";

export default function Account() {

  return (
    <>
    <Info path="/hr/my/account" myaccount={true}/>
    </>
  );
}