import React from "react";
import {
  Link,
} from "react-router-dom";

import { withRouter } from "react-router-dom";

import FormX from "app/components/formx";
import Listx from "../../../../../components/listx";
import ButtonX from "../../../../../components/buttonx";

import Avatar from '@material-ui/core/Avatar';

import { toAbsoluteUrl } from "../../../../../../_metronic/utils/utils";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'photo', label: 'Photo', sort : false },
      { id: 'name', label: 'Name/Email' },
      { id: 'companyid', label: 'Employee ID' },
      { id: 'status', label: 'Hire Date' },
    ];

    this.state = {
      ...this.state,
      action_loading : false,
    }

    this.filter = this.filter.bind(this)
    this.setFilterBar(this.filter);

    this.onHire = this.onHire.bind(this);
    this.onTerminate = this.onTerminate.bind(this);

    //const { isPayroll } = props;
  }

  filter(props, handlers){
    const { isPayroll } = this.props;
    return [
      [
        [
          { 
            name : 'wildcard', 
            label : 'Type in keyword',
            type : 'text', 
            size : 9,
            value : props.search && props.search['wildcard'] ? props.search['wildcard'] : '',
            onChange : handlers.handleChange
          },
          { 
            name : 'status', 
            label : 'Status',
            type : (isPayroll) ? 'hidden' : 'select', 
            size : 2,
            selections : [{ key : 1, value : 'Hired' }, { key : 0, value : 'Not Hired' }],
            value : props.search && !isNaN(parseInt(props.search['status'])) ? props.search['status'] : 3,
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            size : 1,
          },
        ],
      ],
    ];
  }

  updateStatus(id, mode){
    const { crudUrl } = this.props;
    this.setState({ action_loading : true });
    crudUrl.postRecord(id+"/"+mode).then(({ data }) => {
      //this.updatePage();
      let newData = [...this.state.data];
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      prevData.user_info.is_active = (mode === 'activate')
      //reset
      newData[index] = prevData;
      this.setState({ data : newData});
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      this.setState({ action_loading : false });
    });
  }

  hire_or_terminate_form(id, is_terminate){
    return <FormX 
      fields={[
        [
          [
            {
              name : 'id',
              type : 'hidden',
              value : id,
            },
            { 
              name : 'date', 
              label : (is_terminate ? "Termination" : "Hired")+' Date',
              type : 'date', 
              required : true, 
              size : 12,
              useNow : true,
            },
            { 
              name : 'note', 
              label : 'Note',
              type : 'textarea', 
              size : 12,
              value : ''
            },
          ]
        ]
      ]}
    />
  }

  onCustomSubmit(event, url){
    const form = event.currentTarget;
    this.setState({ action_loading : true, form_validated: true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      const { crudUrl } = this.props;
      //create
      this.setState({ action_loading : true });

      let newData = [...this.state.data];
      let id = parseInt(data.get('id'));
      let date = data.get('date');

      crudUrl.postRecord(url, data).then(({ result }) => {
        //this.updatePage();
        //find
        let index = newData.findIndex(d => d.id === id);
        
        if(index >= 0){
          let prevData = newData[index];
          //update
          prevData.status = !prevData.status;
          if(url.includes("hire")){
            prevData.hire_date = date;
          }else{
            prevData.termination_date = date;
          }
          //reset
          newData[index] = prevData;
        }
      })
      .catch((error) => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => {
            error.data[da].map( (d, i) => {
              msg.push(da.toUpperCase()+' : ' + d);
              return <></>;
            });
            return <></>;
          });
        }
        alert(msg.join("\r\n"));
        this.setState({ 
          action_loading : false,
          form_validated: false,
        });
      })
      .then(() => {
        this.setState({ 
          action_loading : false,
          modalShow : false,
          data: newData,
          form_validated: false,
        });
      });
    }
  }

  onHire(event, url){
    this.onCustomSubmit(event,url);
  }

  onTerminate(event, url) {
    this.onCustomSubmit(event,url);
  }

  renderRowData(data){
    let default_img = 21;
    if(data.user_info){
      default_img = (data.user_info.gender === 'male' ? 21 : 11);
    }

    const { baseUrl, canDelete, canUpdate, isPayroll } = this.props;
    let custom_form_url = data.id+"/"+(data.status ? "terminate" : "hire");
    let hireOrTerminate = (data.status ? "Terminate" : "Hire");
    let hireOrTerminate_style = "btn btn-sm " + (data.status ? "btn-danger" : "btn-primary");

    return [
      this.renderCell(<Avatar variant="square" alt={data.user_info ? data.user_info.email : 'N/A'} src={ data.user_info && data.user_info.avatar ? data.user_info.avatar : toAbsoluteUrl("/media/users/300_"+default_img+".jpg")} />),      
      this.renderCell(data.name),
      this.renderCell(data.companyid),
      this.renderCell(
        <>
        <span className='pr-2'>
          {data.status_text}
        </span>
        { (canUpdate && !isPayroll) &&
          <span className='pr-2'>
            <ButtonX 
            className={hireOrTerminate_style}
            onClick={this.showcustomform.bind(this, (data.status ? this.onTerminate : this.onHire), custom_form_url, this.hire_or_terminate_form(data.id, data.status), hireOrTerminate)}
            text={hireOrTerminate}
            />
          </span>
        }
      </>),
      this.renderCell(
        <>
        { !isPayroll ? (
          <>
            <span className='pr-2'>
            <Link to={baseUrl+'/info/'+data.id} className="btn btn-outline-brand btn-icon btn-xs" title="View">
              <i className="flaticon-folder-1"></i>
            </Link>
            </span>
            { canDelete &&
              <>
              { data.user_info &&
                <span className='pr-2'>
                  { (data.user_info.is_active) ? (
                    <ButtonX 
                    onClick={this.updateStatus.bind(this, data.id, 'deactivate')} 
                    icon="flaticon2-cross" 
                    className="btn btn-warning btn-xs btn-icon" 
                    title="Deactivate" 
                    loading={this.state.action_loading}
                    />
                  ) : (
                    <ButtonX 
                    onClick={this.updateStatus.bind(this, data.id, 'activate')} 
                    icon="flaticon2-checkmark" 
                    className="btn btn-success btn-xs btn-icon" 
                    title="Activate" 
                    loading={this.state.action_loading}
                    />
                  )}
                </span>
              }
              </>
            }
          </>
        ) : (
          <>
          <span className='pr-2'>
          <Link to={{
              pathname : baseUrl+'/generate/'+data.id,
              state : { title : 'Payroll Generation', description : data.name }
            }} className="btn btn-outline-brand btn-icon btn-xs" title="Generate">
            <i className="flaticon-event-calendar-symbol"></i>
          </Link>
          </span>
          </>
        )}
        </>
      , null, true),
    ];
  }

}

export class ExtendableEmployeeList extends List{};

export default withRouter(List);