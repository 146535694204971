
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Table, Badge, Spinner } from 'react-bootstrap';

import {Portlet, PortletHeader, PortletBody } from "app/partials/content/Portlet";
import FormX from 'app/components/formx';
import GlobalCrud from "app/crud/global.crud";
import Payslip from "app/components/payslip";

function Generate(props){

  const employee_id = props.match.params.id;
  
  const [currentemployee, setCurrentEmployee] = useState();
  const [computation, setComputation] = useState({});
  const [loading, setLoading] = useState(false);
  
  
  const { record, details, income, deduction, current_income, ytd, company, employee } = computation;

  useEffect(() => {
    const myApi = new GlobalCrud("/payroll/employees/");
    const fetchEmployeeData = async () => {
      const result = await myApi.read(employee_id);
      if(result.status === 200){
        setCurrentEmployee(result.data);
      }
    };

    fetchEmployeeData();
  }, [employee_id]);

  const handleCompute = (event) =>{
    const form = event.currentTarget;
    setLoading(true);

    form.classList.add('was-validated');

    event.preventDefault();
    event.stopPropagation();
    
    if (form.checkValidity() === false) {  
      //not valid
      setLoading(false);
    }else{
      const data = new FormData(form);
      const computeAPI = new GlobalCrud("/payroll/compute/");
      computeAPI.create(data).then(({ data }) => {
        setComputation(data);
      })
      .catch((error) => {
        console.log(error);
      }).then(() => {
        setLoading(false);
      });
    }
  }
  
  const boolField = (bool, params) => {
    const { text_true, text_false, variant_true, variant_false } = params;
    return (
      <Badge variant={bool ? variant_true : variant_false}>
        {bool ? text_true : text_false}
      </Badge>
    )
  }
  const mapObject = (label, parameters, func) => {
    return {
      'label' : label,
      'func' : func,
      'params' : parameters,
    }
  }
  const showDetails = () => {
    if(computation && details){
      let datamap = {
        'schedule' : mapObject('Schedule'),
        'day' : mapObject('Day'),
        'punch.in' : mapObject('Punch In'),
        'punch.out' : mapObject('Punch Out'),
        'work_time' : mapObject('Work Time'),
        'break_time' : mapObject('Break Time'),
        'remark' : mapObject('Remark'),
        'overunder' : mapObject('Over / Under'),
        'approved' : mapObject('Approved', {text_true : 'Approved', text_false : 'Pending Approval', variant_true : 'info', variant_false : 'secondary'}, boolField),
        'finalized' : mapObject('Finalized', {text_true : 'Finalized', text_false : 'Pending Review', variant_true : 'dark', variant_false : 'secondary'}, boolField),
      };
      return (
        <div className="col-md-12">
          { details.data.length > 0 ? (
            <Table size="sm" striped bordered hover responsive>
              <thead>
                <tr>
                  { Object.keys(datamap).map( (h, i) => {
                    return (
                      <th key={i}>{datamap[h].label}</th>
                    )
                  })}
                  {/* { (canUpdate || canDelete) &&
                    <th className="cell-action">Actions</th>
                  } */}
                </tr>
              </thead>
              <tbody>
                { details.data.map( ( d, idx ) => {
                  return (
                    <tr key={idx}>
                      <>
                      { Object.keys(datamap).map( (k, i) => {
                        let v = '';
                        if(k.includes(".")){
                          let ks = k.split(".");
                          v = d[ks[0]];
                          ks.map((vv, indx) => {
                            if(indx !== 0){
                              v = v[vv];
                            }
                            return <></>;
                          })
                        }else{
                          v = d[k];
                        }
                        if(datamap[k].func){
                          return (
                            <td key={i}>{datamap[k].func(v, datamap[k].params)}</td>
                          )
                        }else{
                          return (
                            <td key={i}>{v}</td>
                          )
                        }
                      })}
                      </>
                      {/* { (canUpdate || canDelete) &&
                        <td className="cell-action">
                          { canUpdate && 
                            <ButtonX 
                            className="btn btn-xs btn-primary btn-icon"
                            title="Edit"
                            icon="fa fa-edit"
                            //onClick={this.showform.bind(this, d)}
                            />
                          }
                          &nbsp;
                          { canDelete && 
                            <ButtonX 
                            className="btn btn-xs btn-danger btn-icon"
                            title="Delete"
                            icon="flaticon2-rubbish-bin-delete-button"
                            //onClick={this.showdelete.bind(this, d)}
                            />
                          }
                        </td>
                      } */}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) :(
            <h3>No Record found!</h3>
          )}
        </div>
      )
    }
    return <div className="col-md-12"><h2>No data to show, please click generate</h2></div>;
  }

  const showTotals = () => {
    if(computation && details){
      return (
        <Payslip record={record} company={company} income={income} current_income={current_income} deduction={deduction} ytd={ytd} employee={employee} period_start={details.start_date} period_end={details.end_date} pay_date={details.pay_date}/>
      )
    }
    return <></>;
  }
  const setupForm = () => {
    
    let salary = {}
    if(currentemployee && currentemployee['salary']){
      salary = currentemployee['salary'];
    }

    return (
      <Form
        noValidate
        onSubmit={e => handleCompute(e)}
      >
      <FormX 
        fields={[
          [
            [
              {
                name : 'employee',
                type : 'hidden',
                value : employee_id,
              },
              { 
                name : 'start_date', 
                label : 'Start',
                type : 'date', 
                required : true, 
                size : 6,
                useNow : true,
                value : salary && salary['start_date'] ? salary['start_date'] : ''
              },
              { 
                name : 'end_date', 
                label : 'End',
                type : 'date', 
                required : true, 
                size : 6,
                useNow : true,
                value : salary && salary['end_date'] ? salary['end_date'] : ''
              },
              {
                name : 'submit',
                label : 'Start Generate',
                text : 'Generate',
                type : 'button',
                icon : 'flaticon-refresh',
                variant : 'primary',
                className : 'btn-sm btn-block',
                size : 12,
                loading : loading,
              },
            ]
          ]
        ]}
      />
      </Form>
    )
  }

  return (
    <div className='row'>
      <div className='col-xl-2 col-md-4 col-xs-12'>
        <Portlet>
          <PortletHeader title="SETUP"/>
          <PortletBody>
            {setupForm()}
          </PortletBody>
        </Portlet>
      </div>
      <div className='col-xl-10 col-md-8 col-xs-12'>
        <Portlet>
          <PortletHeader title="DETAILS"/>
          <PortletBody >
            { loading ? (
              <div className="text-center">
                <Spinner animation="border"/>
              </div>
            ) : (
              <>
                <div className="row">
                  {showTotals()}
                </div>
                <div className="kt-separator kt-separator--dashed"></div>
                <div className="row">
                  {showDetails()}
                </div>
              </>
            )}
          </PortletBody>
        </Portlet>
      </div>
    </div>
  )
}

export default withRouter(Generate);