import React from "react";
import {
  withRouter,
} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../../components/crudpageX";

const SettingsDepartmentsPositions = (props) => {

  const fields = (props, state, params = null) => {
    let fields = [
      [
        [
          {
            name : 'department',
            type : 'hidden',
            value : params ? params.id : ''
          },
          { 
            name : 'title', 
            type : 'text', 
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['title'] ? props.initialValues['title'] : ''
          },
          { 
            name : 'level', 
            type : 'number', 
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['level'] ? props.initialValues['level'] : '1',
          },
          { 
            name : 'description', 
            type : 'textarea', 
            required : false, 
            size : 12,
            value : props.initialValues && props.initialValues['description'] ? props.initialValues['description'] : ''
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) => {
    return <List {...props} backLink="/hr/settings/departments"/>
  }

  const { url } = props.match;
  const { access } = props;
  
  return (
    <CrudPageX 
    List={list}
    path={url} 
    apiPath={url} 
    form_size='6'
    fields={fields}
    title='Department Positions'
    params={props.match.params}
    {...access}
    />
  )
}

export default withRouter(SettingsDepartmentsPositions);