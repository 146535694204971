import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as page from "app/store/ducks/page.duck";

import { Badge, Form } from 'react-bootstrap';

import Listx from "app/components/listx";
import ButtonX from "app/components/buttonx";
import GlobalCrud from "app/crud/global.crud";
import FormX from "app/components/formx";

class Tasks extends Listx {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      action_loading : false,
      task_selections : false,
      current_project : null,
      project_selections : false,
    };

    this.headings = [{ id: 'project', label: 'Project' },];
    if(props.hideProject){
      this.headings = [];
    }
    this.headings.push(...[
      { id: 'priority', label: 'Priority' },
      { id: 'state', label: 'State' },
      { id: 'startdate', label: 'Start' },
      { id: 'duedate', label: 'Due' },
      { id: 'title', label: 'Title' },
      { id: 'progress', label: 'Progress' },
      { id: 'my_hours', label: 'My Hours', sort: false },
      { id: 'my_stats', label: 'Status', sort: false },
      // { id: 'closed', label: 'Closed?' },
      // { id: 'approved', label: 'Approved?' },
      //{ id: 'timer', label: 'Timer', sort: false },
    ]);

    //this.post_status_fields = ['active'];

    this.api = new GlobalCrud('todo/my/tasks');
    this.admin_api = new GlobalCrud('todo');
    this.log_api = new GlobalCrud(props.crudUrl.getBaseUrl());

    this.useModal();

    this.timerForm = this.timerForm.bind(this);
    this.onUpdateLog = this.onUpdateLog.bind(this);

    this.project_id = props.match.params.projectId;

    const { state: loc_state } = props.location;
    this.title = 'Tasks - '+this.project_id;
    if(loc_state){
      const { description } = loc_state;
      if(description){
        this.title = description;
      }
    }
  }

  componentDidMount(...args) {
    super.componentDidMount.apply(this, args);

    if(this.project_id){
      this.admin_api.getRecord('projects/'+this.project_id+'/data').then(({ data }) => {
        this.setState({
          task_selections : data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
      this.overrideCanCreate = false;
      this.admin_api.getRecord((this.props.isAdmin ? '' : 'my/')+'projects/'+this.project_id).then(({ data }) => {
        this.overrideCanCreate = true;
        this.setState({
          current_project : data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      this.admin_api.getRecord('tasks/data').then(({ data }) => {
        this.setState({
          task_selections : data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
      this.admin_api.getRecord('my/projects').then(({ data }) => {
        this.setState({
          project_selections : data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

  // componentDidUpdate(prevProps) {  
  //   const { task_update } = prevProps;
  //   let timestamp = task_update ? task_update.timestamp : '';
  //   if(this.props.task_update && (this.props.task_update.timestamp !== timestamp)){
  //     const data = this.props.task_update;
  //     let newData = [...this.state.data];  
  //     let index = newData.findIndex(d => d.id === data.id);
  //     if(index >= 0){
  //       //update
  //       newData[index] = data;
  //     }else{
  //       // newData = this.state.data;
  //       // if(!doNotUpdate){
  //       //   newData = [...newData, data];
  //       // }
  //     }

  //     this.setState({ 
  //       data: newData
  //     });
  //   }
  // }

  onNoteChange(e){
    let value = e.target.value ? e.target.value : '';
    let newinput = {[e.target.name] : value };
    this.setState( prevstate => ({ inputs : { ...prevstate.inputs, ...newinput } }));
  }

  timerForm(id, start){
    let label = (start ? 'Start Note' : 'Stop Note');

    let fields = [
      [
        [
          { 
            name : 'note', 
            label : label,
            type : 'textarea', 
            required : false, 
            size : 12,
            value : this.state.inputs ? this.state.inputs.note : '',
            onChange: this.onNoteChange.bind(this)
          },
        ]
      ]
    ]

    let f = (<Form
      noValidate
      validated={false}
    >
    <FormX fields={fields}/>
    </Form>);

    this.setState({ 
      modalShow: true,
      modalTitle : (start ? 'Start' : 'Stop') + ' Task Confirmation', 
      modalBody : f,
      modalSubmitTitle : "Continue",
      modalSubmitVariant : 'primary',
      modalSubmit : this.timer.bind(this, id, start),
    });
  }

  timer(id, start){
    this.setState({ 
      action_loading : true,
    });
    let note = this.state.inputs ? this.state.inputs.note : '';
    this.api.postRecord('punch', {start : start, id : id, note: note}).then(( {data} ) => {
      this.setState({ 
        action_loading : false,
        modalShow : false, 
        inputs: {},
      }, ()=>{
        if(data.status){
          this.props.reload(this.props.parent);
        }else{
          alert(data.message);
        }
      });
    })
    .catch(error => {
      let msg = [];
      if(error.status === 404){
        msg.push("Error 404");
      }else{
        Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      }
      alert(msg.join("\r\n"));
      this.setState({ 
        action_loading : false,
        modalShow : false,
        inputs: {},
      });
    });
  }

  renderRowData(data){
    const { access } = data;
    
    let owner = false;
    if(access){
      owner = access.owner;
    }

    let append_buttons = [];
    
    const updateStat = this.updateStatus.bind(this),
    showForm = this.showform.bind(this),
    { action_loading } = this.state;

    if(!data.is_sub){
      append_buttons.push(
        <a className="kt-nav__link" 
          onClick={(e) => {
            // updateStat(data.id, 'closed', false, 'tasks', action_loading);
            showForm({current_parent_id: data.id}, e);
          }} 
          href="/">
          <i className="kt-nav__link-icon flaticon-add-circular-button" />
          <span className="kt-nav__link-text">Create Sub-Task</span>
        </a>
      );
    }

    append_buttons.push((
      data.closed ?
      <a className="kt-nav__link" 
        onClick={(e) => {
          e.preventDefault();
          updateStat(data.id, 'closed', false, 'tasks', action_loading);
        }} 
        href="/">
        <i className="kt-nav__link-icon flaticon-more-v4" />
        <span className="kt-nav__link-text">{ action_loading ? 'Loading...' : 'Open'}</span>
      </a>
      :
      <a className="kt-nav__link" 
        onClick={(e) => {
          e.preventDefault();
          updateStat(data.id, 'closed', true, 'tasks', action_loading);
        }} 
        href="/">
        <i className="kt-nav__link-icon flaticon-multimedia-5" />
        <span className="kt-nav__link-text">{ action_loading ? 'Loading...' : 'Close'}</span>
      </a>
    ));

    if(owner){
      append_buttons.push((
        data.active ?
        <a className="kt-nav__link" 
          onClick={(e) => {
            e.preventDefault();
            updateStat(data.id, 'active', false, 'tasks', action_loading);
          }} 
          href="/">
          <i className="kt-nav__link-icon flaticon2-cross" />
          <span className="kt-nav__link-text">{ action_loading ? 'Loading...' : 'Deactivate'}</span>
        </a>
        :
        <a className="kt-nav__link" 
          onClick={(e) => {
            e.preventDefault();
            updateStat(data.id, 'active', true, 'tasks', action_loading);
          }} 
          href="/">
          <i className="kt-nav__link-icon flaticon2-checkmark" />
          <span className="kt-nav__link-text">{ action_loading ? 'Loading...' : 'Activate'}</span>
        </a>
      ));
      
      append_buttons.push((
        data.approved ?
        <a className="kt-nav__link" 
          onClick={(e) => {
            e.preventDefault();
            updateStat(data.id, 'approved', false, 'tasks', action_loading);
          }} 
          href="/">
          <i className="kt-nav__link-icon fa fa-thumbs-down" />
          <span className="kt-nav__link-text">{ action_loading ? 'Loading...' : 'Disapprove'}</span>
        </a>
        :
        <a className="kt-nav__link" 
          onClick={(e) => {
            e.preventDefault();
            updateStat(data.id, 'approved', true, 'tasks', action_loading);
          }} 
          href="/">
          <i className="kt-nav__link-icon fa fa-thumbs-up" />
          <span className="kt-nav__link-text">{ action_loading ? 'Loading...' : 'Approve'}</span>
        </a>
      ));
    }

    let row = [this.renderCell(data.project_title)];
    if(this.props.hideProject){
      row = [];
    }
    row.push(...[
      this.renderCell(data.priority_name),
      this.renderCell(data.state_name),
      this.renderCell(data.startdate ? data.startdate : '----'),
      this.renderCell(data.duedate ? data.duedate : '----'),
      this.renderCell(
        <>
        {data.title}
        {data.description ? 
        (<><br/><strong>Note : </strong>{data.description}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        {data.progress}%
        </>
      ),
      this.renderCell(
        <>
        { data.log && data.log.total_per_user && data.log.total_per_user[this.props.currentUser.id] ? (
          <>
            My : { data.log.total_per_user[this.props.currentUser.id].time } ( <strong>{data.log.total_per_user[this.props.currentUser.id].count}</strong> )
          </>
        ) : (
          <>--:--:-- (0)</>
        )
        }
        { data.log &&
          <>
            <small><br/>Team : { data.log.total_time } ( <strong>{data.log.total_count}</strong> )</small>
          </>
        }
        </>
      ),
      this.renderCell(
        <>
          <Badge className="font13 mr5" variant={data.active ? 'success' : 'danger'}>
            {data.active ? <i className="flaticon2-checkmark"></i> : <i className="flaticon2-cross"></i>}
          </Badge>
          <Badge className="font13 mr5" variant={data.closed ? 'dark' : 'warning'}>
            {data.closed ? <i className="flaticon-multimedia-5"></i> : <i className="flaticon-more-v4"></i>}
          </Badge>
          <Badge className="font13 mr5" variant={data.approved ? 'info' : 'secondary'}>
            {data.approved ? <i className="fa fa-thumbs-up"></i> : <i className="fa fa-thumbs-down"></i>}
          </Badge>
        </>
      ),
      // this.renderCell(
      //   <>
      //     { (data.log && data.log.timers) ? (
      //       <>
      //       {data.log.timers.map( (timer, index) => (
      //         <TimerX key={index} {...timer} label={timer.user} color='default' variant="outlined" apply_default_style={false} size='small'/>
      //       ))}
      //       </>
      //     ) : (
      //       <>--:--:--</>
      //     ) }
      //   </>
      // ),
      this.renderCell(
        <>
          <span className="pr-2">
          { (data.log && data.log.timers && data.log.timers.some(item => this.props.currentUser.id === item.user_id)) ? (
            <ButtonX 
              className="btn btn-xs btn-warning btn-icon"
              title="Start"
              icon="fa fa-stop"
              loading={this.state.action_loading}
              onClick={() => this.timerForm(data.id, false)}
            />
          ) : (
            <ButtonX 
              className="btn btn-xs btn-success btn-icon"
              title="Start"
              icon="fa fa-play"
              loading={this.state.action_loading}
              onClick={() => this.timerForm(data.id, true)}
            />
          ) }
          </span>
          {this.defaultActions(data.id, data, true, append_buttons)}
        </>
      , null, true),
    ]);
    return row;
  }

  onUpdateLog(event, url){
    const form = event.currentTarget;
    //this.setState({ form_validated: true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      let id = data.get('id');
      // for (var pair of data.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]); 
      // }
      this.updateStatus(id, null, null, 'logs', data);
    }
  }

  update_log_form(data){
    return <FormX 
    fields={[
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : data.id,
          },
          { 
            name : 'fields[approved]', 
            type : 'hidden',
            size : 12,
            value : (data.approved) ? 0 : 1,
          },
          { 
            label : 'Total Time : '+data.total_time,
            type : 'label',
            size : 12,
          },
          { 
            name : 'fields[billing]', 
            label : 'Approve for billing',
            type : 'number',
            size : 6,
            value : data.billing,
          },
          { 
            name : 'fields[salary]', 
            label : 'Approve for salary',
            type : 'number',
            size : 6,
            value : data.salary,
          },
        ]
      ]
    ]}
  />;
  }

  renderRelatedRowData(data){
    return this.renderRowData(data);
  }

  renderSubRowData(data){
    const { access } = data;

    const { logs_access, hideProject } = this.props;
    
    let canUpdate = logs_access && logs_access.canUpdate,
    canDelete = logs_access && logs_access.canDelete,
    owner = false;

    if(access){
      owner = access.owner;
      canUpdate = access.edit;
      canDelete = access.delete;
    }

    if(typeof data.approved === 'string'){
      data.approved = (parseInt(data.approved) > 0);
    }

    let row = [
      this.renderCell(
        <>{ data.owner }</>,
        { colSpan : 3, align: 'right'}
      )
    ];
    if(hideProject){
      row = [
        this.renderCell(
          <>{ data.owner }</>,
          { colSpan : 2, align: 'right'}
        )
      ];
    }
    row.push(...[
      this.renderCell(
        <>
        {data.start_time + ' - ' + data.end_time}
        {data.start_note ? 
        (<><br/><strong>Start Note : </strong>{data.start_note}</>) : (<></>)
        }
        {data.end_note ? 
        (<><br/><strong>End Note : </strong>{data.end_note}</>) : (<></>)
        }
        </>,
        { colSpan : 3, align : 'right'}
      ),
      this.renderCell(
        <>{ data.duration }</>,
        { colSpan : 2, align: 'right'}
      ),
      // this.renderCell(
      //   <Badge className="font13" variant={data.approved ? 'info' : 'secondary'}>
      //     {data.approved ? <i className="fa fa-thumbs-up"></i> : <i className="fa fa-thumbs-down"></i>}
      //   </Badge>
      // ),
      this.renderCell(
        <>
        { canUpdate && 
          <span className="pr-2">
            <ButtonX 
              className="btn btn-xs btn-primary btn-icon"
              title="Edit"
              icon="flaticon-edit"
              onClick={this.logShowform.bind(this, data)}
            />
          </span>
        }
        { canDelete && 
          <span className="pr-2">
            <ButtonX 
              className="btn btn-xs btn-danger btn-icon"
              title="Delete"
              icon="flaticon2-rubbish-bin-delete-button"
              onClick={this.logShowdelete.bind(this, data.task, data.id)}
            />
          </span>
        }      
        { owner &&
          <span className="pr-2">
            { data.approved ? (
              <ButtonX 
              //onClick={this.updateStatus.bind(this, data.id, 'approved', false, 'logs')} 
              onClick={this.showcustomform.bind(this, this.onUpdateLog, 'logs', this.update_log_form(data), 'Disapprove')} 
              icon="fa fa-thumbs-down" 
              className="btn btn-warning btn-xs btn-icon" 
              title="Disapprove" 
              loading={this.state.action_loading}
              />
            ) : (
              <ButtonX 
              //onClick={this.updateStatus.bind(this, data.id, 'approved', true, 'logs')} 
              onClick={this.showcustomform.bind(this, this.onUpdateLog, 'logs', this.update_log_form(data), 'Approve')} 
              icon="fa fa-thumbs-up" 
              className="btn btn-success btn-xs btn-icon" 
              title="Approve" 
              loading={this.state.action_loading}
              />
            )}
          </span>
        }
        </>,
        { colSpan : 2, align : 'right'}
      ),
    ]);
    return row;
  }

  updateStatus(id, mode, bool, apiPath, e, is_loading){
    if(is_loading){
      return;
    }
    let formdata = { field : mode, value : bool };
    if(e instanceof FormData){
      formdata = e;
    }
    
    let newData = [...this.state.data];

    this.setState({ action_loading : true });
    this.admin_api.postRecord(apiPath+"/"+id+"/update", formdata).then(({ data }) => {
      //this.updatePage();
      if(apiPath === 'logs'){
        id = data.task;
      }
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      if(apiPath === 'logs'){
        let sub_row_index = prevData.sub_rows.findIndex(d => d.id === data.id);
        if(mode){
          prevData.sub_rows[sub_row_index][mode] = bool;
        }else{
          for (var pair of formdata.entries()) {
            var matches = pair[0].match(/\[(.*?)\]/);
            if (matches) {
                var submatch = matches[1];
                prevData.sub_rows[sub_row_index][submatch] = pair[1];
            }
          }
        }
      }else{
        //task
        prevData[mode] = bool;
      }
      //reset
      newData[index] = prevData;
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      //this.setState({ action_loading : false });      
      this.setState({ 
        action_loading : false,
        modalShow : false,
        data: newData,
        form_validated: false,
      });
    });
  }

  handleLogSubmit(task_id, event) {
    const form = event.currentTarget;
    this.setState({ action_loading : true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      //create
      this.log_api.postRecord(task_id+'/logs', data)
      .then(( {data} ) => {
        let newData = [], doNotUpdate = false;
        this.post_status_fields.map( f => {
          doNotUpdate = (data[f] !== undefined && data[f] === false);
          return <></>;
        });
        if(this.state.data.some(item => data.id === item.id)){
          //update
          newData = [...this.state.data];  
          let index = newData.findIndex(d => d.id === data.id);
          if(doNotUpdate){
            newData.splice(index, 1);
          }else{
            newData[index] = data;
          }
        }else{
          newData = this.state.data;
          if(!doNotUpdate){
            newData = [...newData, data];
          }
        }
        //this.updatePage();
        this.setState({ 
          action_loading : false,
          modalShow : false,
          data: newData
        });
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        alert(msg.join("\r\n"));
        this.setState({ 
          action_loading : false,
        });
      });
    }
  }

  logShowform(d, e){

    let f = (<Form
      noValidate
      onSubmit={e => this.handleLogSubmit(d.task, e)}
      validated={false}
      id="modal_log_form"
    >
    {this.logForm(d)}
    </Form>);
    
    this.setState({ 
      modalShow: true, 
      modalTitle : (d ? 'Edit ' : 'Add ' ) + "log", 
      modalBody : f,
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'modal_log_form',
    })
  }
  
  logShowdelete(task_id, d, e){
    this.setState({ 
      modalShow: true,
      modalTitle : 'Confirmation', 
      modalBody : 'Are you sure you want to delete this log?',
      modalSubmitTitle : 'Delete',
      modalSubmitVariant : 'danger',
      modalSubmit : this.executeLogDelete.bind(this, task_id, d),
    })
  }

  executeLogDelete(task_id, id, e){
    this.setState({ action_loading : true });
    this.log_api.deleteRecord(task_id+'/logs', id).then(( {data} ) => {
      let newData = this.state.data.filter((d) => d.id !== data.id);
      this.setState(prevState => ({
        action_loading : false,
        modalShow : false,
        data : [...newData, data],
      }));
    })
    .catch(error => {
      let msg = [];
      Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      alert(msg.join("\r\n"));
      this.setState({ 
        action_loading : false,
      });
    });
  }

  logForm(data){
    return <FormX fields={this.logFields(data)}/>
  }

  logFields(values){
    const { access } = values;
    let owner = false;
    if(access){
      owner = access.owner;
    }
    
    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          { 
            name : 'user', 
            type : 'hidden', 
            value : values && values['user'] ? values['user'] : '',
          },
          { 
            name : 'start', 
            label : 'Start',
            type : 'datetime', 
            required : true, 
            size : 6,
            value : values && values['start'] ? values['start'] : '',
            useNow : true,
          },
          { 
            name : 'end', 
            label : 'End',
            type : 'datetime', 
            required : false, 
            size : 6,
            value : values && values['end'] ? values['end'] : '',
          },
          { 
            name : 'start_note', 
            label : 'Punch In Note',
            type : 'textarea', 
            required : false, 
            size : 12,
            value : values && values['start_note'] ? values['start_note'] : ''
          },
          { 
            name : 'end_note', 
            label : 'Punch Out Note',
            type : 'textarea', 
            required : false, 
            size : 12,
            value : values && values['end_note'] ? values['end_note'] : ''
          },
          { 
            name : 'billing', 
            label : 'Approve for billing',
            type : 'number',
            size : 6,
            value : values ? values['billing'] : 0,
          },
          { 
            name : 'salary', 
            label : 'Approve for salary',
            type : 'number',
            size : 6,
            value : values ? values['salary'] : 0,
          },
          { 
            name : 'approved', 
            type : 'checkbox', 
            label : 'Approved',
            required : false, 
            size : 4,
            value : values ? values['approved'] : true,
            hide : !owner,
          },
        ]
      ]
    ];
  }

  fields(values){    
    const { task_selections, current_project, project_selections } = this.state;
    const { currentUser, myMode } = this.props;
    const default_state = task_selections && task_selections['states'] ? task_selections['states'].find((v) => v.default === true) : '';
    const default_priority = task_selections && task_selections['priorities'] ? task_selections['priorities'].find((v) => v.default === true) : '';
    let owner = false,
    access = null;

    let parent_id = false;
    if(values){
      const { current_parent_id } = values;
      if(current_parent_id){
        parent_id = current_parent_id;
        values = null;
      }
    }
    if(values){
        
      if(values.access){
        access = values.access;
        owner = access.owner;
      }
    }else{
      if(current_project){
        owner = current_project && current_project.access ? current_project.access.owner : false;
      }else{        
        owner = true;
      }
    }
    
    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          {
            name : 'parent',
            type : 'hidden',
            value : values && values['parent'] ? values['parent'] : (parent_id ? parent_id : ''),
          },
          {
            name : 'approved',
            type : 'hidden',
            value : values ? values['approved'] : false,
          },
          { 
            name : 'user', 
            type : myMode ? 'hidden' : 'select', 
            size : 6,
            value : values && values['user'] ? values['user'] : currentUser ? currentUser.id : '',
            selections : task_selections && task_selections['members'] ? task_selections['members'] : [],
            idField : 'id',
            valueField : 'name',
            hide : !owner,
          },
          { 
            name : 'project', 
            type : 'select', 
            size : 6,
            value : values && values['project'] ? values['project'] : '',
            selections : project_selections ? project_selections : [],
            idField : 'id',
            valueField : 'title',
            hide : !owner,
            ignore: !myMode || (myMode && current_project),
          },
          { 
            name : 'title', 
            label : 'Task Name',
            type : 'text', 
            required : true,
            size : (!myMode || (myMode && current_project)) ? (!myMode ? 6 : 12) : 6,
            value : values && values['title'] ? values['title'] : '',
            hide : !owner,
          },
          { 
            name : 'description', 
            label : 'Description',
            type : 'textarea', 
            size : 12,
            value : values && values['description'] ? values['description'] : '',
            hide : !owner,
          },
          { 
            name : 'priority', 
            type : 'select', 
            required : true, 
            size : 6,
            value : values && values['priority'] ? values['priority'] : default_priority ? default_priority.id : '',
            selections : task_selections && task_selections['priorities'] ? task_selections['priorities'] : [],
            idField : 'id',
            valueField : 'name',
            hide : !owner,
          },
          { 
            name : 'state', 
            type : 'select', 
            required : true, 
            size : 6,
            value : values && values['state'] ? values['state'] : default_state ? default_state.id : '',
            selections : task_selections && task_selections['states'] ? task_selections['states'] : [],
            idField : 'id',
            valueField : 'name',
            hide : !owner,
          },
          { 
            name : 'startdate', 
            required : true, 
            type : 'date', 
            size : 3,
            value : values && values['startdate'] ? values['startdate'] : '',
            useNow : true,
            hide : !owner,
          },
          { 
            name : 'duedate', 
            required : true, 
            type : 'date', 
            size : 3,
            value : values && values['duedate'] ? values['duedate'] : '',
            useNow : true,
            hide : !owner,
          },
          { 
            name : 'progress', 
            label : 'Progress (%)',
            type : 'slider', 
            min : 0,
            step : 5,
            max : 100,
            required : true, 
            size : owner ? 6 : 12,
            value : values && values['progress'] ? values['progress'] : 0,
          },
          { 
            name : 'members', 
            type : 'multiselect', 
            size : 12,
            value : values && values['members'] ? values['members'] : [],
            selections : task_selections && task_selections['members'] ? task_selections['members'] : [],
            idField : 'id',
            valueField : 'name',
            hide : !owner || myMode,
          },
          { 
            name : 'active', 
            type : 'checkbox', 
            label : 'Active',
            required : false, 
            size : 4,
            value : values ? values['active'] : true,
            hide : !owner,
          },
          { 
            name : 'closed', 
            type : 'checkbox', 
            required : false, 
            size : 4,
            value : values ? values['closed'] : false,
            // ignore : values ? false : true,
            ignore : true,
          },
        ]
      ]
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    task_update : state.ws.task_update,
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(Tasks));