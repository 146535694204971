import {
  withRouter,
} from "react-router-dom";
import ListX from "app/components/listx";

class Logs extends ListX {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'action', label: 'Action' },
      { id: 'meta', label: 'Meta' },
      { id: 'identifier', label: 'Identifier' },
      { id: 'obj', label: 'Object' },
      { id: 'created', label: 'Date/Time' },
    ];

  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.currentPage !== this.state.currentPage){
      this.updatePage();
      return;
    }else if(prevState.currentPerPage !== this.state.currentPerPage){
      this.updatePage();
      return;
    }
  }
  
  renderRowData(data){
    return [
      this.renderCell(data.action),
      this.renderCell(data.meta),
      this.renderCell(data.identifier),
      this.renderCell(data.obj),
      this.renderCell(data.created),
    ];
  }
}

export default withRouter(Logs);