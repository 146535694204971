import React, { useState, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "app/components/crudpageX";
import GlobalCrud from "app/crud/global.crud";
import Tasks from "./tasks";

export default function Projects(props) {

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("/todo");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  const fields = (props) => {
    const { selections } = props;
    let values = props.initialValues;

    let owner = false;
    if(access){
      owner = access.owner;
    }

    let fields = [
      [
        [
          { 
            name : 'user', 
            label : 'Owner',
            type : 'select', 
            size : 6,
            value : values && values['user'] ? values['user'] : [],
            selections : selections && selections['members'] ? selections['members'] : [],
            idField : 'id',
            valueField : 'name',
          },
          { 
            name : 'title', 
            label : 'Project Name',
            type : 'text', 
            required : true,
            size : 6,
            value : values && values['title'] ? values['title'] : ''
          },
          { 
            name : 'description', 
            label : 'Description',
            type : 'textarea', 
            size : 12,
            value : values && values['description'] ? values['description'] : ''
          },
          { 
            name : 'startdate', 
            required : true, 
            type : 'date', 
            size : 6,
            value : values && values['startdate'] ? values['startdate'] : '',
            useNow : true
          },
          { 
            name : 'duedate', 
            required : true, 
            type : 'date', 
            size : 6,
            value : values && values['duedate'] ? values['duedate'] : '',
            useNow : true
          },
          { 
            name : 'members', 
            type : 'multiselect', 
            size : 12,
            value : values && values['members'] ? values['members'] : [],
            selections : selections && selections['members'] ? selections['members'] : [],
            idField : 'id',
            valueField : 'name',
          },
          { 
            name : 'active', 
            type : 'checkbox', 
            label : 'Active',
            required : false, 
            size : 4,
            value : values ? values['active'] : true,
            hide : !owner,
          },
          { 
            name : 'approved', 
            type : 'checkbox', 
            label : 'Approved',
            required : false, 
            size : 4,
            value : values ? values['approved'] : true,
            hide : !owner,
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access, currentUser, location } = props;

  let project_access = access['todo/projects'] ? access['todo/projects'] : null;
  let tasks_access = access['todo/projects/tasks'] ? access['todo/projects/tasks'] : null;
  let logs_access = access['todo/projects/tasks/logs'] ? access['todo/projects/tasks/logs'] : null;

  let task_crud = new GlobalCrud(location.pathname.replace('mg/', ''));
  
  return (
    <Switch>
      <Route 
        path={path + "/mg/:projectId/tasks"}
        children={
          <Tasks currentUser={currentUser} {...tasks_access} logs_access={logs_access} crudUrl={task_crud} parent="todo.project.task" selections={selections} />
        } 
      />
      { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='8'
        fields={fields}
        selections={selections}
        title='User Accounts'
        {...project_access}
        />} 
      />}
    </Switch>
  )
}