import React from "react";

import { withRouter } from "react-router-dom";

import { Badge } from 'react-bootstrap';
import Listx from "app/components/listx";
import ButtonX from "app/components/buttonx";

import GlobalCrud from "app/crud/global.crud";

class Positions extends Listx {

  constructor(props) {
    super(props);

    this.refresh_on_update = true;

    this.headings = [
      { id: 'effective_date', label: 'Effective Date' },
      { id: 'department', label: 'Department', sort : false },
      { id: 'position', label: 'Position', sort : false },
      { id: 'note', label: 'Note', sort : false },
      { id: 'active', label: 'Status' },
      { id: 'created', label: 'Date/Time' },
    ];

    this.admin_api = new GlobalCrud("/hr/employee/records");
    this.useModal();
  }

  renderRowData(data){
    const { canUpdate } = this.props;

    return [
      this.renderCell(data.effective_date),
      this.renderCell(data.department_name),
      this.renderCell(data.position_name),
      this.renderCell(data.note),
      this.renderCell(<Badge variant={data.active ? 'success' : 'danger'}>{data.active ? 'Active' : 'Inactive'}</Badge>),
      this.renderCell(data.created),
      this.renderCell(
        <>
          { canUpdate &&
            <span className="pr-2">
              { data.active ? (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'active', false)} 
                icon="flaticon2-cross" 
                className="btn btn-warning btn-xs btn-icon" 
                title="Deactivate" 
                loading={this.state.action_loading}
                />
              ) : (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'active', true)} 
                icon="flaticon2-checkmark" 
                className="btn btn-success btn-xs btn-icon" 
                title="Activate" 
                loading={this.state.action_loading}
                />
              )}
            </span>
          }
          {this.defaultActions(data.id, data, true)}
        </>
      , null, true),
    ];
  }

  updateStatus(id, mode, bool, e){
    let formdata = { field : mode, value : bool };
    if(e instanceof FormData){
      formdata = e;
    }
    
    let newData = [...this.state.data];

    this.setState({ action_loading : true });
    this.admin_api.postRecord("positions/"+id+"/update", formdata).then(({ data }) => {
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      prevData[mode] = bool;
      newData.map((v, i) => {
        if(i !== index){
          newData[i][mode] = !bool;
        }
        return <></>;
      })
      //reset
      newData[index] = prevData;
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      //this.setState({ action_loading : false });      
      this.setState({ 
        action_loading : false,
        modalShow : false,
        data: newData,
        form_validated: false,
      });
    });
  }

  fields(values){    
    const { selections } = this.props;

    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          { 
            name : 'effective_date', 
            label : 'Effect Date',
            type : 'date', 
            required : true, 
            size : 4,
            value : values && values['effective_date'] ? values['effective_date'] : ''
          },
          { 
            name : 'position', 
            type : 'select', 
            required : true, 
            size : 8,
            value : values && values['position'] ? values['position'] : '',
            selections : selections && selections['positions'] ? selections['positions'] : [],
            idField : 'id',
            valueField : 'position_title',
          },
          { 
            name : 'note', 
            label : 'Note',
            type : 'textarea', 
            size : 12,
            value : values && values['note'] ? values['note'] : ''
          },
          { 
            name : 'active', 
            type : 'checkbox', 
            label : 'Is Active',
            required : false, 
            size : 12,
            value : values && values['active'] ? values['active'] : false
          },
        ]
      ]
    ];
  }

}

export default withRouter(Positions);