import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Account from "./account";
import Todo from "./todo";
import Attendance from "./attendance";
import Requests from "./requests";
import TodoProjects from "./todo/project";

export default function My({ currentUser, access }) {

  const routes = [
  {
    path : 'my/account',
    component : (props, access) => <Account {...props} access={access} currentUser={currentUser}/>,
  },
  {
    path : 'my/todo/projects/:projectId?',
    component : (props) => <TodoProjects {...props} access={access} currentUser={currentUser}/>,
  },
  {
    path : 'my/todo',
    component : (props) => <Todo {...props} access={access} currentUser={currentUser}/>,
  },
  {
    path : 'my/attendance',
    component : (props, access) => <Attendance {...props} access={access} currentUser={currentUser}/>,
  },
  {
    path : 'my/requests',
    component : (props, a) => <Requests {...props} access={access['my/requests'] ? access['my/requests'] : null} currentUser={currentUser}/>,
  },
  ];

  return (
    <Switch>
      { routes.map( (route, index) => {
        return (
          <Route key={index}
            path={"/"+route.path}
            render={(props) => route.component({...props}, null)} 
          />
        )
      })}

      <Redirect to={{pathname: "/dashboard", state: { message : 'Invalid Page Access ', message_variant : 'danger' }}} />

    </Switch>
  );
}