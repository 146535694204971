import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  TaskTimerUpdate: "TASK.TIMER",
  UserStatusUpdate: "USER.STATUS.UPDATE",
  AttendanceTimerUpdate: "ATTENDANCE.TIMER",
  RecentActivityUpdate: "RECENT.ACTIVITY.UPDATE",
  DataChange: "DATA.CHANGE",
  Nothing : '',
};

const initialState = {
  task_timer : null,
  attendance_timer : null,
  user_status : null,
  recent_activity : null,
  data : null,
  action : null,
};

export const reducer = persistReducer(
    { storage, key: "ws-duck", whitelist: [] },
    (state = initialState, action) => {
      //console.log(action);
      switch (action.type) {
        case actionTypes.TaskTimerUpdate: {
          const { data } = action.payload;

          let s = { task_timer : data };
          return {...state, ...s};
        }
        case actionTypes.AttendanceTimerUpdate: {
          const { data } = action.payload;

          let s = { attendance_timer : data };
          return {...state, ...s};
        }
        case actionTypes.UserStatusUpdate: {
          const { data } = action.payload;

          let s = { user_status : data };
          return {...state, ...s};
        }
        case actionTypes.RecentActivityUpdate: {
          const { data } = action.payload;

          let s = { recent_activity : data };
          return {...state, ...s};
        }
        case actionTypes.DataChange: {
          const { data } = action.payload;

          let s = { data : data, action : action.action };
          return {...state, ...s};
        }

        default:
          return state;
      }
    }
);

function getActionByValue(object, value) {
  let type = value.toUpperCase();
  const f = Object.keys(object).find(key => object[key] === type);
  return f ? actionTypes[f] : actionTypes.Nothing;
}


export const actions = {
  trigger: ( event, data ) => ({ type: getActionByValue(actionTypes, event), payload : data }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Reload, function* reload() {
  //   console.log('asd222asdasdasdasd');
  // });
  
}
