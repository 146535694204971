/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Form } from "react-bootstrap";
import GlobalCrud from '../crud/global.crud'

import * as page from '../store/ducks/page.duck';

import ModalX from "../components/modalx";
import FormX from "../components/formx";
import TimerX from "../components/timerx"
import { capitalize } from "../../_metronic/utils/utils"

class Task extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      started : false,
      submit_loading : true,
      show : false,
      modalShow : false,
      modalTitle : '',
      modalBody : null,
      modalSubmit : null,
      modalSubmitTitle : '',
      modalSubmitVariant : '',
      inputs : {},
      timer : false,
    };

    this.action_type = "todo.project.task";
    this.api = new GlobalCrud("todo/my/tasks");
  }
  
  updateWidget(){
    this.api.getRecord("widget").then(({ data: { timer, started } }) => {
      this.setState({
        timer : timer,
        started : started,
        submit_loading : false,
        show : started
      });
    })
    .catch((error) => {
      this.setState({ show : false});
    });
  }
  componentDidMount(){
    this.updateWidget();
  }
  
  onChange(e){
    let newinput = {[e.target.name] : e.target.value};
    this.setState( prevstate => ({ inputs : { ...prevstate.inputs, ...newinput } }));
  }

  modal(mode, data = {}){
    let label = '';
    switch(mode){
      case "start":
        label = 'Stop Note';
        break;
      case "stop":
        label = 'Stop Note';
        break;
      default:
        break;
    }

    let fields = [
      [
        [
          { 
            name : 'note', 
            label : label,
            type : 'textarea', 
            required : false, 
            size : 12,
            value : '',
            onChange: this.onChange.bind(this)
          },
        ]
      ]
    ]

    let f = (<Form
      noValidate
      onSubmit={e => this.handleSubmit(e)}
      validated={false}
      id="modal_form"
    >
    <FormX fields={fields}/>
    </Form>);

    this.setState({ 
      modalShow: true,
      modalTitle : capitalize(mode) + ' Task Confirmation', 
      modalBody : f,
      modalSubmitTitle : "Continue",
      modalSubmitVariant : 'primary',
      modalSubmit : this.punch.bind(this, mode, data),
    });
  }

  punch(mode, data = {}){
    this.setState({submit_loading : true});
    if(this.state.inputs){
      data = { ...data, ...this.state.inputs};
    }
    this.api.postRecord("punch", data).then(({ data: { timer, status, message, started } }) => {
      if(!status){
        alert(message);
        this.setState({submit_loading : false, modalShow: false, inputs: {}});
      }else{
        switch(mode){
          case "start":
            this.setState({is_logged_in : true, submit_loading : false, modalShow: false, inputs: {}, timer : timer});
            break;
          case "stop":
            this.setState({is_logged_in : false, submit_loading : false, modalShow: false, inputs: {}, timer : false, show : false});
            break;
          default:
            break;
        }
      }
      this.props.reload(this.action_type);
    })
    .catch((error) => {
      let msg = [];
      if(error.status === 404){
        msg.push("Error 404");
      }else{
        Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      }
      alert(msg.join("\r\n"));
      this.setState({submit_loading : false, modalShow: false, inputs: {}});
    });
  }

  componentDidUpdate(prevProps) {  
    const { task_timer } = prevProps;
    let timestamp = task_timer ? task_timer.timestamp : '';
    if(this.props.task_timer && (this.props.task_timer.timestamp !== timestamp)){
      const { timer, started } = this.props.task_timer;
      this.setState({
        timer : timer,
        started : started,
        show : started
      });
    }
  }

  goToProject(timer){
    //<Redirect to="/error/error-v1" />
    this.props.history.push({ pathname : '/my/todo/'+timer.project_id, state : { title : 'Todo', description : timer.project_title } });
  }
  
  render(){ 
    const {timer, started, submit_loading, show, modalShow, modalTitle, modalBody, modalSubmit, modalSubmitTitle, modalSubmitVariant} = this.state;

    let modalClose = () => this.setState({ modalShow: false });

    if(!show){
      return <></>
    }

    return (
      <>
        <ModalX
          show={modalShow}
          title={modalTitle}
          onHide={modalClose}
          body={ modalBody }
          submit={ modalSubmit }
          submit_title={ modalSubmitTitle }
          submit_variant={ modalSubmitVariant }
          action_loading={submit_loading}
        />
        { started && timer &&
          <span className='pr-4'>
          <TimerX onClick={this.goToProject.bind(this, timer)} label={timer && timer.title ? timer.title : 'Task'} {...timer} color='default' onDelete={this.modal.bind(this, "stop", {id:timer.id, start: 0})}/>
          </span>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    task_timer : state.ws.task_timer,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(Task));