/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */
import React from "react";

import { connect } from "react-redux";

import { Form } from "react-bootstrap";
import GlobalCrud from '../crud/global.crud'

import * as page from '../store/ducks/page.duck';

import ButtonX from "../components/buttonx";
import ModalX from "../components/modalx";
import FormX from "../components/formx";
import TimerX from "../components/timerx"

class Punch extends React.Component {
  
  constructor(props) {
    super();
    this.state = {
      is_logged_in : false,
      on_break : false,
      breaks : [],
      shifts : [],
      submit_loading : true,
      show : false,
      modalShow : false,
      modalTitle : '',
      modalBody : null,
      modalSubmit : null,
      modalSubmitTitle : '',
      modalSubmitVariant : '',
      inputs : {},
      timer : false,
    };

    this.api = new GlobalCrud("hr/my/attendance");
  }
  
  componentDidMount(){
    this.api.getRecord("widget").then(({ data: { timer, is_logged_in, on_break, breaks, shifts } }) => {
      this.setState({
        timer : timer,
        is_logged_in : is_logged_in,
        on_break : on_break,
        breaks : breaks,
        shifts : shifts,
        submit_loading : false,
        show : true
      });
    })
    .catch((error) => {
      this.setState({ show : false});
    });
  }

  componentDidUpdate(prevProps) {  
    const { attendance_timer } = prevProps;
    let timestamp = attendance_timer ? attendance_timer.timestamp : '';
    if(this.props.attendance_timer && (this.props.attendance_timer.timestamp !== timestamp)){
      const { timer, is_logged_in, on_break } = this.props.attendance_timer;
      this.setState({
        timer : timer,
        is_logged_in : is_logged_in,
        on_break : on_break,
      });
    }
  }
  
  onChange(e){
    let newinput = {[e.target.name] : e.target.value};
    this.setState( prevstate => ({ inputs : { ...prevstate.inputs, ...newinput } }));
  }

  onRadioChange(value, e){
    let newinput = {[e.name] : value};
    this.setState( prevstate => ({ inputs : { ...prevstate.inputs, ...newinput } }));
  }

  getShift(mode, data = {}){
    this.setState({submit_loading : true});
    this.api.getRecord("shift").then(({ data: { breaks, shifts } }) => {
      this.setState({
        breaks : breaks,
        shifts : shifts,
      });
    })
    .catch((error) => {
      this.setState({ show : false});
    }).then(()=>{
      this.setState({submit_loading : false});
      this.modal(mode, data);
    });
  }
  modal(mode, data = {}){
    let label = '';
    let options = null, option_name = null, option_key = 'id', option_value = '';
    switch(mode){
      case "in":
        option_name = 'timesheet_id';
        option_value = 'name';
        options = this.state.shifts;
        label = 'Punch In Note';
        break;
      case "out":
        label = 'Punch Out Note';
        break;
      case "break":
        label = 'End Note';
        if(data.start){
          option_name = 'shift_break_id';
          option_value = 'title';
          options = this.state.breaks;
          label = 'Start Note';
        }
        break;
      default:
        break;
    }

    let fields = [
      [
        [
          { 
            name : 'note', 
            label : label,
            type : 'textarea', 
            required : false, 
            size : 12,
            value : '',
            onChange: this.onChange.bind(this)
          },
        ]
      ]
    ]

    if(options !== null && options.length > 0){
      fields.unshift(
        [
          [
            { 
              name : option_name, 
              selections : options,
              idField : option_key,
              valueField : option_value,
              type : 'radiobutton', 
              required : true, 
              size : 12,
              value : '',
              onChange: this.onRadioChange.bind(this)
            },
          ]
        ]
      )
      
      let newinput = {[option_name] : options ? options[0][option_key] : ''};
      this.setState( prevstate => ({ inputs : { ...prevstate.inputs, ...newinput } }));
    }else{
      if(mode === 'in'){
        fields.unshift(
          [
            [
              {
                type : 'alert', 
                size : 12,
                value : "Hi hard worker! You do not have any shift today, this time will be subject for your manager's approval",
                variant : 'danger',
              },
            ]
          ]
        )
      }
    }

    let f = (<Form
      noValidate
      onSubmit={e => this.handleSubmit(e)}
      validated={false}
      id="modal_form"
    >
    <FormX fields={fields}/>
    </Form>);

    this.setState({ 
      modalShow: true,
      modalTitle : 'Confirmation', 
      modalBody : f,
      modalSubmitTitle : "Continue",
      modalSubmitVariant : 'primary',
      modalSubmit : this.punch.bind(this, mode, data),
    });
  }

  punch(mode, data = {}){
    this.setState({submit_loading : true});
    if(this.state.inputs){
      data = { ...data, ...this.state.inputs};
    }
    this.api.postRecord("punch/"+mode, data).then(({ data: { timer, status, message, started, shifts, breaks } }) => {
      if(!status){
        alert(message);
        this.setState({submit_loading : false, modalShow: false, inputs: {}});
      }else{
        switch(mode){
          case "in":
            this.setState({is_logged_in : true, submit_loading : false, modalShow: false, inputs: {}, timer : timer, shifts : shifts, breaks : breaks});
            break;
          case "out":
            this.setState({is_logged_in : false, submit_loading : false, modalShow: false, inputs: {}, timer : false});
            break;
          case "break":
            this.setState({on_break : started, submit_loading : false, modalShow: false, inputs: {}, timer : timer});
            break;
          default:
            break;
        }
        this.props.reload("timekeeping.attendance");
        this.props.reload("todo.project.task");
      }
    })
    .catch((error) => {
      let msg = [];
      if(error.status === 404){
        msg.push("Error 404");
      }else{
        Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      }
      alert(msg.join("\r\n"));
      this.setState({submit_loading : false, modalShow: false, inputs: {}});
    });
  }

  render(){ 
    const {timer, is_logged_in, on_break, breaks, submit_loading, show, modalShow, modalTitle, modalBody, modalSubmit, modalSubmitTitle, modalSubmitVariant} = this.state;

    let modalClose = () => this.setState({ modalShow: false });

    if(!show){
      return <></>
    }

    return (
      <>
        <ModalX
          show={modalShow}
          title={modalTitle}
          onHide={modalClose}
          body={ modalBody }
          submit={ modalSubmit }
          submit_title={ modalSubmitTitle }
          submit_variant={ modalSubmitVariant }
          action_loading={submit_loading}
        />
        { timer &&
          <TimerX {...timer} color={on_break ? 'default' : 'primary'}/>
        }
        {!is_logged_in ? (
          <>
          <ButtonX 
          className="btn btn-brand" 
          onClick={this.getShift.bind(this, "in", {})} 
          loading={submit_loading}
          text="Punch In"
          icon="kt-menu__link-icon fa fa-key"
          />
          </>
        ) : (
          on_break ? (
            <>
            <ButtonX 
            className="btn btn-warning" 
            onClick={this.modal.bind(this, "break", {start : 0})} 
            loading={submit_loading}
            text="Back"
            icon="kt-menu__link-icon fa fa-hand-point-left"
            />
            </>
          ) : (
            <>
              { breaks !== null && breaks.length > 0 &&
              <ButtonX 
              className="btn btn-warning" 
              onClick={this.modal.bind(this, "break", {start : 1})} 
              loading={submit_loading}
              text="Take a Break"
              icon="kt-menu__link-icon fa fa-clock"
              />
              }
              <ButtonX 
              className="btn btn-danger" 
              onClick={this.modal.bind(this, "out", {})} 
              loading={submit_loading}
              text="Punch Out"
              icon="kt-menu__link-icon fa fa-door-open"
              />
            </>
          )
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    attendance_timer : state.ws.attendance_timer,
  }
}

export default connect(
  mapStateToProps,
  page.actions
)(Punch);