import React from "react";

import { Button, Spinner } from "react-bootstrap";

class ButtonX extends React.Component {

  render(){ 
    const { text, icon, type,  loading, className, onClick, variant, title, attr, disabled, ...others } = this.props;

    return (
      <Button {...others} variant={variant} type={type} className={className} onClick={onClick} disabled={ disabled ? disabled : loading} title={title} {...attr}>
      { loading ? (
        <>
        <Spinner
          as="span"
          size="sm"
          animation="grow"
          role="status"
          aria-hidden="true"
        />
        { text ? (<>&nbsp;&nbsp;Loading...</>) : ('')}
        </>
      ) : (
        <>
        { icon && 
          <i className={icon} />
        }
        {text}
        </>
      )}
    </Button>
    )
  }
}

// Set default props
ButtonX.defaultProps = {
  text : '',
  icon : null,
  loading : false,
  className : null,
  onClick : null,
  type : 'button',
  variant : '',
  title : '',
  attr : null,
};

export default ButtonX;