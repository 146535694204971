import React from "react";
import { 
  withRouter, Link
} from "react-router-dom";
import { connect } from "react-redux";
import * as page from "app/store/ducks/page.duck";
import Projects from "./projects";
import Tasks from 'app/pages/home/todo/projects/tasks';

import GlobalCrud from "../../../../crud/global.crud";

function TodoProjects(props) {

  const { access, match, currentUser } = props;
  const { projectId } = match.params;

  // console.log(access);

  const [selections, setSelections] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [selected_project, setSelected_project] = React.useState(null);
  
  let basePath = '/my/todo/projects';

  React.useEffect(() => {
    if(projectId && projects.length > 0){
      setSelected_project(projects.find((d) => d.id.toString() === projectId));
    }
  }, [projectId, projects]);

  React.useEffect(() => {
    const todo_crud = new GlobalCrud('todo');
    const fetchSelectionData = async () => {
      const result = await todo_crud.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  React.useEffect(() => {
    if(!selected_project && projects.length > 0){
      props.history.push({ pathname : "/my/todo/projects/"+projects[0].id, search: '?orderby=duedate&orderdir=asc', state : { title : 'My Todo', description : projects[0].title } });
    }
  }, [projects, selected_project, props.history]);

  let project_crud = new GlobalCrud('todo/my/projects');
  let task_crud = null;
  
  let project_access = null;//access['todo/projects'] ? access['todo/projects'] : null;
  let tasks_access = access['todo/projects/tasks'] ? access['todo/projects/tasks'] : null;
  let logs_access = access['todo/projects/tasks/logs'] ? access['todo/projects/tasks/logs'] : null;

  if(selected_project){
    task_crud = new GlobalCrud(project_crud.getBaseUrl()+'/'+selected_project.id+'/tasks');
    if(selected_project.access){
      project_access = selected_project.access;
    }
  }
  
  const { state } = props.location;
  const task_title = state ? 'Tasks - '+state.description : 'Tasks';
  return (
    <>
      <Link to={"/my/todo?orderby=duedate&orderdir=asc"} className={"btn btn-outline-primary btn-square"} title="Tasks">
        Tasks
      </Link>
      <Link to={"/my/projects"} className={"btn btn-primary btn-square"} title="Projects">
        Projects
      </Link>
      <div className="row">
        <div className="col-xl-3">
          <Projects access={project_access} currentUser={currentUser} basePath={basePath} crudUrl={project_crud} selections={selections} updateParent={setProjects} selected_project={selected_project}/>
        </div>
        <div className="col-xl-9">
          {task_crud && selected_project &&
          <Tasks myMode={true} currentUser={currentUser} hideProject={true} key={props.location.key} {...tasks_access} logs_access={logs_access} crudUrl={task_crud} parent="todo.project.task" title={task_title}/>
          }
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    task_update : state.ws.task_update,
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(TodoProjects));