
import { withRouter } from "react-router-dom";

import Listx from "../../../../../components/listx";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'username', label: 'User' },
      { id: 'type', label: 'Type' },
      { id: 'message', label: 'Message' },
      { id: 'from_username', label: 'From' },
      { id: 'created', label: 'Date/Time' },
    ];
  }


  renderRowData(data){
    return [
      this.renderCell(data.username),
      this.renderCell(data.type),
      this.renderCell(data.message),
      this.renderCell(data.from_username),
      this.renderCell(data.created),
    ];
  }

}

export default withRouter(List);