import React from "react";
import {
  withRouter,
} from "react-router-dom";
import { Spinner } from "react-bootstrap";

import Crud from "app/components/crud";
import FormX from "app/components/formx";
import GlobalCrud from "app/crud/global.crud.js";
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "app/partials/content/Portlet";

import Schedules from "./employement/schedules";
import Positions from "./employement/positions";
import Statuses from "./employement/statuses";

class Employement extends React.Component {

  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    this.id = id;
    this.path_prefix = (this.id ? "/"+this.id+"/" : '/');

    this.crudUrl = new GlobalCrud(props.path);

    let employee_path = props.path;
    if(employee_path === '/hr/my/account'){
      employee_path = props.path+'/employee';
    }
    this.employee_api = new GlobalCrud(employee_path);
    this.employee_log_api = new GlobalCrud(props.path+this.path_prefix+"logs");
  }

  fields(props, state){
    let hired = props.initialValues && props.initialValues['status'] ? props.initialValues['status'] : false;
    let fields = [
      [
        [
          { 
            name : 'companyid', 
            label : 'Employee ID',
            type : 'text', 
            required : true, 
            disabled : !(props.access && props.access.canUpdate),
            size : 4,
            value : props.initialValues && props.initialValues['companyid'] ? props.initialValues['companyid'] : ''
          },
          // { 
          //   name : 'email', 
          //   label : 'Employee email',
          //   type : 'email', 
          //   required : true, 
          //   size : 4,
          //   value : props.initialValues && props.initialValues['email'] ? props.initialValues['email'] : ''
          // },
          { 
            name : 'hire_date', 
            label : 'Status ('+(hired ? 'Hired' : 'Terminated')+')',
            type : 'text', 
            disabled : true,
            size : 4,
            value : props.initialValues && props.initialValues['status_text'] ? props.initialValues['status_text'] : '',
          },
        ]
      ]
    ]
    return fields;
  }
  
  form(props){
    return <FormX {...this.props} 
      fields={this.fields(this.props, this.props)}
    />
  }

  render(){ 
    const { baseUrl, selections, initialValues, updateParent, updateParentState, page_title, loading, access } = this.props;
    
    if(loading){
      return (
        <div className="text-center">
          <Spinner animation="border"/>
        </div>
      )
    }
    return (
      <>
      <Portlet>
        <PortletHeader title={ page_title} toolbar={(
          <PortletHeaderToolbar>
            {/* { (access && access.canUpdate && !loading) &&
              <ButtonX 
              className={ initialValues['status'] ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"}
              text={ initialValues['status'] ? "Terminate" : "Hire"}
              />
            } */}
          </PortletHeaderToolbar>)}/>
        <PortletBody>
          <Crud 
              colmd="12"
              baseUrl={baseUrl}
              crudUrl={this.employee_api} 
              selections={selections}
              initialValues={initialValues}
              isUpdate={true}
              CustomForm={this.form.bind(this)}
              form_only={true}
              no_redirect={true}
              updateParentState={updateParentState}
              updateParent={updateParent}
              isDisabled={access && access.canUpdate ? false : true}
              {...access}
          />
        </PortletBody>
      </Portlet>
      <div className="row">
        <div className="col-xl-12">
          <Positions
            title="Positions"
            selections={selections}
            path={this.path_prefix+"positions"}
            crudUrl={new GlobalCrud(this.crudUrl.getBaseUrl() + this.path_prefix+"positions")}
            {...access}
          />
        </div>
        <div className="col-xl-12">
          <Statuses
            title="Status"
            selections={selections}
            path={this.path_prefix+"statuses"}
            crudUrl={new GlobalCrud(this.crudUrl.getBaseUrl() + this.path_prefix+"statuses")}
            {...access}
          />
        </div>
        <div className="col-xl-12">
          <Schedules 
            title="Schedules"
            selections={selections}
            path={this.path_prefix+"shifts"}
            crudUrl={new GlobalCrud(this.crudUrl.getBaseUrl() + this.path_prefix+"shifts")}
            {...access}
            canUpdate={false}
          />
        </div>
      </div>
      </>
    )
  }
}

export default withRouter(Employement);