import React, { useState, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "app/components/crudpageX";
import GlobalCrud from "app/crud/global.crud";

export default function Customers(props) {

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("/crm/customers");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  const fields = (props) => {
    const { selections } = props;
    let values = props.initialValues;

    let fields = [
      [
        [
          { 
            name : 'company', 
            type : 'text', 
            required : true,
            size : 6,
            value : values && values['company'] ? values['company'] : ''
          },
          { 
            name : 'customer_reference', 
            type : 'text', 
            size : 6,
            value : values && values['customer_reference'] ? values['customer_reference'] : ''
          },
          { 
            name : 'first_name', 
            type : 'text', 
            required : true,
            size : 6,
            value : values && values['first_name'] ? values['first_name'] : ''
          },
          { 
            name : 'last_name', 
            type : 'text', 
            required : true,
            size : 6,
            value : values && values['last_name'] ? values['last_name'] : ''
          },
          { 
            name : 'email', 
            type : 'email', 
            size : 6,
            value : values && values['email'] ? values['email'] : ''
          },
          { 
            name : 'phone', 
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 6,
            value : values && values['phone'] ? values['phone'] : ''
          },
          { 
            name : 'country', 
            type : 'select', 
            required : true,
            size : 6,
            value : values && values['country'] ? values['country'] : [],
            selections : selections && selections['countries'] ? selections['countries'] : [],
          },
          { 
            name : 'currency', 
            type : 'select', 
            size : 6,
            value : values && values['currency'] ? values['currency'] : [],
            selections : selections && selections['currencies'] ? selections['currencies'] : [],
          },
          { 
            name : 'address_1', 
            label : 'Address 1',
            type : 'textarea',
            required : true, 
            size : 12,
            value : values && values['address_1'] ? values['address_1'] : ''
          },
          { 
            name : 'address_2', 
            label : 'Address 2',
            type : 'textarea',
            size : 12,
            value : values && values['address_2'] ? values['address_2'] : ''
          },
          { 
            name : 'city', 
            type : 'text', 
            required : true,
            size : 3,
            value : props.initialValues && props.initialValues['city'] ? props.initialValues['city'] : ''
          },
          { 
            name : 'state', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['state'] ? props.initialValues['state'] : ''
          },
          { 
            name : 'zip_code', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['zip_code'] ? props.initialValues['zip_code'] : ''
          },
          { 
            name : 'payment_due_days', 
            type : 'number', 
            size : 3,
            value : props.initialValues && props.initialValues['payment_due_days'] ? props.initialValues['payment_due_days'] : ''
          },
          { 
            name : 'sales_tax_name', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['sales_tax_name'] ? props.initialValues['sales_tax_name'] : ''
          },
          { 
            name : 'sales_tax_percent', 
            type : 'number', 
            size : 3,
            value : props.initialValues && props.initialValues['sales_tax_percent'] ? props.initialValues['sales_tax_percent'] : ''
          },
          { 
            name : 'sales_tax_number', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['sales_tax_number'] ? props.initialValues['sales_tax_number'] : ''
          },
          { 
            name : 'extra', 
            type : 'textarea',
            size : 12,
            value : values && values['extra'] ? values['extra'] : ''
          },
          { 
            name : 'meta', 
            type : 'textarea',
            size : 12,
            value : values && values['meta'] ? values['meta'] : '{}'
          },
          { 
            name : 'consolidated_billing', 
            type : 'checkbox', 
            required : false, 
            size : 4,
            value : values ? values['active'] : false
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <Switch>
      { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='8'
        fields={fields}
        selections={selections}
        title='User Accounts'
        {...access}
        />} 
      />}
    </Switch>
  )
}