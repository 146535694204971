import React from "react";
import {
  withRouter
} from "react-router-dom";

import { Form, Table } from "react-bootstrap";
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "../partials/content/Portlet";

import ButtonX from "./buttonx";
import ModalX from "./modalx";

class MiniList extends React.Component {
  constructor(props) {
    super();

    this.state = {
      data : [],
      pagination : 1,
      modalShow : false,
      modalTitle : '',
      modalBody : null,
      modalSubmit : null,
      modalSubmitTitle : '',
      modalSubmitVariant : '',
      action_loading : false,
      form_validated : false,
    };
  }

  updatePage() {
    const { crudUrl, apiPath } = this.props;
    crudUrl.getRecord(apiPath).then(({ data }) => {
      this.setState({
        data : data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  componentDidMount(){
    this.updatePage();
  }

  componentDidUpdate(prevProps){
    if(prevProps.reload !== this.props.reload){
      this.updatePage(); 
    }
    if(prevProps.apiPath !== this.props.apiPath){
      this.updatePage(); 
    }
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    this.setState({ action_loading : true, form_validated: true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      const { crudUrl, apiPath, uploads } = this.props;
      if(uploads){
        uploads.map(f => data.append("file[]", f));
      }
      //create
      crudUrl.postRecord(apiPath, data)
      .then(( {data} ) => {
        /*let newData = [];
        if(this.state.data.some(item => data.id === item.id)){
          //update
          newData = [...this.state.data];  
          let index = newData.findIndex(d => d.id === data.id);
          newData[index] = data;
        }else{
          newData = [...this.state.data, data];
        }*/
        this.updatePage();
        this.setState({ 
          action_loading : false,
          modalShow : false,
          form_validated: false,
          //data: newData
        });
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        alert(msg.join("\r\n"));
        this.setState({ 
          action_loading : false,
          form_validated: false,
          //modalShow : false
        });
      });
    }
  }

  executeDelete(d, e){
    this.setState({ action_loading : true });
    const { crudUrl, apiPath } = this.props;
    let id = d['id'];
    crudUrl.deleteRecord(apiPath, id).then(( {data} ) => {
      this.updatePage();
      this.setState(prevState => ({
        action_loading : false,
        modalShow : false,
      }));
    })
    .catch(error => {
      let msg = [];
      Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      alert(msg.join("\r\n"));
      this.setState({ 
        action_loading : false,
        //modalShow : false
      });
    });
  }
  
  showform(d, e){
    const { title, form, form_data } = this.props;

    let f = (<Form
      noValidate
      onSubmit={e => this.handleSubmit(e)}
      validated={false}
      id="modal_form"
    >
    {(form ? form(d, form_data) : '')}
    </Form>);
    
    this.setState({ 
      modalShow: true, 
      modalTitle : (d ? 'Edit ' : 'Add ' ) + title, 
      modalBody : (form ? f : ''),
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'modal_form',
    })
  }
  

  showdelete(d, e){
    const { title } = this.props;

    this.setState({ 
      modalShow: true,
      modalTitle : 'Confirmation', 
      modalBody : 'Are you sure you want to delete this ' + title + '?',
      modalSubmitTitle : 'Delete',
      modalSubmitVariant : 'danger',
      modalSubmit : this.executeDelete.bind(this, d),
    })
  }

  render() {
    const { data, modalShow, modalTitle, modalBody, modalSubmit, modalSubmitTitle, modalSubmitVariant, action_loading } = this.state;
    const { title, canCreate, canDelete, canUpdate, header, body } = this.props;

    let modalClose = () => this.setState({ modalShow: false });

    return (
      <>
      <ModalX
        show={modalShow}
        title={modalTitle}
        onHide={modalClose}
        body={ modalBody }
        submit={ modalSubmit }
        submit_title={ modalSubmitTitle }
        submit_variant={ modalSubmitVariant }
        action_loading={action_loading}
      />
      <Portlet>
        <PortletHeader title={title} toolbar={(
          <PortletHeaderToolbar>
            { canCreate &&
              <ButtonX 
              className="btn btn-sm btn-primary"
              text="New"
              onClick={this.showform.bind(this, null)}
              icon="fa fa-plus"
              />
            }
          </PortletHeaderToolbar>)}/>
        <PortletBody>
          <Table size="sm" striped bordered hover responsive>
            <thead>
              <tr>
                { header.map( (h, i) => {
                  return (
                    <th key={i}>{h}</th>
                  )
                })}
                { (canUpdate || canDelete) &&
                  <th className="cell-action">Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              { data.map( ( d, idx ) => {
                return (
                  <tr key={idx}>
                    { typeof body === 'function' ? (
                      <>{ body(d, idx) }</>
                    ) : (
                      <>
                      { body.map( (k, i) => {
                        let v = '';
                        if(k.includes(".")){
                          let ks = k.split(".");
                          v = d[ks[0]];
                          ks.map((vv, indx) => {
                            if(indx !== 0){
                              v = v[vv];
                            }
                            return <></>;
                          })
                        }else{
                          v =d[k];
                        }
                        return (
                          <td key={i}>{v}</td>
                        )
                      })}
                      </>
                    )}
                    { (canUpdate || canDelete) &&
                      <td className="cell-action">
                        { canUpdate && 
                          <ButtonX 
                          className="btn btn-xs btn-primary btn-icon"
                          title="Edit"
                          icon="fa fa-edit"
                          onClick={this.showform.bind(this, d)}
                          />
                        }
                        &nbsp;
                        { canDelete && 
                          <ButtonX 
                          className="btn btn-xs btn-danger btn-icon"
                          title="Delete"
                          icon="flaticon2-rubbish-bin-delete-button"
                          onClick={this.showdelete.bind(this, d)}
                          />
                        }
                      </td>
                    }
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </PortletBody>
      </Portlet>
      </>
    );
  }
}

// Set default props
MiniList.defaultProps = {
  id: 0,
  apiPath : '',
  isAdmin : false,
  canCreate : false,
  canDelete : false,
  canUpdate : false,
  crudUrl : null,
  title : '',
  header : [],
  body : [],
  form : null,
  reload : false,
  uploads : [],
};

export default withRouter(MiniList);