import React from "react";

import { Switch, Route, withRouter } from "react-router-dom";
import GlobalCrud from "app/crud/global.crud";
import List from "app/pages/home/hr/employee/records/list";

import Generate from "./generate";

function Employees(props){

  const baseUrl = "/payroll/employees";
  const listCrud = new GlobalCrud(baseUrl);
  const { path } = props.match;

  return (
    <Switch>
      <Route 
        path={path + "/generate/:id"}
        children={
          <Generate 
            {...props}
            path="/payroll/employees/generate"
          />
        } 
      />
      <Route 
        path={path}
        children={
          <List 
            {...props}
            path={path}
            crudUrl={listCrud}
            isPayroll={true}
            baseUrl={path}
          />
        } 
      />
    </Switch>
  )
}

export default withRouter(Employees);