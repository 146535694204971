import React from "react";

import { withRouter } from "react-router-dom";

import Listx from "../../../../../components/listx";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'name', label: 'Type' },
      { id: 'description', label: 'Description', sort : false },
      { id: 'status', label: 'Status' },
    ];
  }

  renderRowData(data){
    return [
      this.renderCell(data.name),
      this.renderCell(data.description),
      this.renderCell(data.status ? 'Active' : 'Inactive'),
      this.renderCell(
        <>
        {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);