import React from "react";
import {
  withRouter,
  Link
} from "react-router-dom";
import {Portlet, PortletHeader, PortletBody,PortletHeaderToolbar, PortletFooter } from "../../../../../partials/content/Portlet";
import ButtonX from '../../../../../components/buttonx';
import FormX from '../../../../../components/formx';
import BaseCrud from '../../../../../components/baseCrud';
import { ListGroup, Badge } from "react-bootstrap";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { toAbsoluteUrl } from "../../../../../../_metronic/utils/utils";

class Projects extends BaseCrud {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.title = 'Project';
    this.post_status_fields = ['active'];
  }

  form(values){
    const { selections, access } = this.props;
    let owner = false;
    if(values){
      if(values.access){
        owner = values.access.owner;
      }
    }
    return <FormX 
      fields={[
        [
          [
            {
              name : 'id',
              type : 'hidden',
              value : values && values['id'] ? values['id'] : '',
            },
            { 
              name : 'user_id', 
              label : 'Owner',
              type : 'select', 
              size : 6,
              value : values && values['user'] ? values['user'] : [],
              selections : selections && selections['members'] ? selections['members'] : [],
              idField : 'id',
              valueField : 'name',
              hide: !owner
            },
            { 
              name : 'title', 
              label : 'Project Name',
              type : 'text', 
              required : true,
              size : 6,
              value : values && values['title'] ? values['title'] : ''
            },
            { 
              name : 'description', 
              label : 'Description',
              type : 'textarea', 
              size : 12,
              value : values && values['description'] ? values['description'] : ''
            },
            { 
              name : 'members', 
              type : 'multiselect', 
              size : 12,
              value : values && values['members'] ? values['members'] : [],
              selections : selections && selections['members'] ? selections['members'] : [],
              idField : 'id',
              valueField : 'name',
            },
            { 
              name : 'active', 
              type : 'checkbox', 
              label : 'Active',
              required : false, 
              size : 4,
              value : values ? values['active'] : true
            },
          ]
        ]
      ]}
    />
  }

  render(){
    const { access, basePath, selected_project } = this.props;

    return (
      <>
        { this.renderModal() }
        <div className='row'>
          <div className='col-xl-12 col-md-6'>
            <Portlet>
              <PortletHeader title="ACTIVE PROJECTS" toolbar={(
                <PortletHeaderToolbar>
                  { access && access.canCreate &&
                    <span className='pr-2'>
                      <ButtonX 
                      className="btn btn-sm btn-icon btn-primary"
                      onClick={this.showform.bind(this, null)}
                      icon="fa fa-plus"
                      />
                    </span>
                  }
                  { access && access.edit && selected_project &&
                    <span className='pr-2'>
                      <ButtonX 
                      className="btn btn-sm btn-icon btn-warning"
                      onClick={this.showform.bind(this, selected_project)}
                      icon="flaticon-edit"
                      />
                    </span>
                  }
                  { access && access.delete && selected_project &&
                    <span className='pr-2'>
                      <ButtonX 
                      className="btn btn-sm btn-icon btn-danger"
                      onClick={this.showdelete.bind(this, selected_project)}
                      icon="flaticon2-rubbish-bin-delete-button"
                      />
                    </span>
                  }
                </PortletHeaderToolbar>)
              }/>
              <PortletBody fluid={true} style={{ maxHeight: 'calc(50vh - 100px)', overflowY: 'auto', flexDirection : 'column' }}>
                <div className="row">
                  <div className="col-md-12 text-center">
                    { !this.state.loading &&
                    <ListGroup 
                    //defaultActiveKey={this.state.menu ? this.state.menu[0].link : ''}
                    >
                      { this.state.data.map((d, index) => {
                        return (
                          <Link key={index} to={{pathname:basePath+'/'+d.id, search: '?orderby=duedate&orderdir=asc', state : { title : 'Todo', description : d.title } }} className={selected_project && selected_project.id === d.id ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}>
                            {d.title}
                          </Link>
                        )
                      })}
                    </ListGroup>
                    }
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </div>
          <div className='col-xl-12 col-md-6'>
            { selected_project &&
            <Portlet>
            <PortletHeader title="MEMBERS" toolbar={(
              <PortletHeaderToolbar>
                <Badge variant='warning'>{selected_project.members.length}</Badge>
              </PortletHeaderToolbar>)}/>
            <PortletBody fluid={true} style={{ maxHeight: 'calc(50vh - 100px)', overflowY: 'auto' }}>
              <div style={{ width : '100%' }}>
                <List style={{ width : '100%', maxWidth: 360 }}>
                  { selected_project.members_list.map( (member, index) => {  
                    let default_img = (member.gender === 'male' ? 21 : 11);
                    return (
                      <div key={index}>
                      <ListItem style={{ padding: 0, paddingBottom: 4 }} >
                        <ListItemAvatar>
                          <Avatar alt={member.fullname} src={ member.avatar ? member.avatar : toAbsoluteUrl("/media/users/300_"+default_img+".jpg")} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={member.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                style={{display: 'inline'}}
                                color="textPrimary"
                              >
                                {member.fullname}
                              </Typography>
                              { selected_project.totals && selected_project.totals.per_user &&
                                <>
                                {" — "+(selected_project.totals.per_user[member.id] ? selected_project.totals.per_user[member.id].time : '--:--:--')+ ' / ' +(selected_project.totals.per_user[member.id] ? selected_project.totals.per_user[member.id].count+' tasks' : '0 task')}
                                </>
                              }
                              
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      </div>
                    );
                  })}
                </List>
              </div>
            </PortletBody>
            <PortletFooter>
              <div className="text-right">
                { selected_project.totals ? (
                  <>
                  TOTAL : <strong>{selected_project.totals.time }</strong> / {selected_project.totals.count} Tasks
                  </>
                ) : (
                  <>
                  No Task
                  </>
                )}
              </div>
            </PortletFooter>
            </Portlet>
          }
        </div>
      </div>
      </>
    )
  }
}

export default withRouter(Projects)