import React from "react";

import { Badge } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Listx from "../../../../../components/listx";
import * as page from "../../../../../store/ducks/page.duck";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: 'employee_name', label: 'Employee', sort : false },
      { id: 'schedule' },
      { id: 'shift_schedule', label : 'Day' },
      { id: 'start', label : 'Punch In' },
      { id: 'end', label : 'Punch Out' },
      { id: 'actual', label: 'Work Time' },
      { id: 'actual_break', label: 'Break Time' },
      { id: 'remark', label: 'Remark' },
      { id: 'overunder', label: 'Over / Under' },
      { id: 'approved' },
      { id: 'finalized' },
    ];
    
    this.setFilterBar(this.filter);

  }

  filter(props, handlers){

    return [
      [
        [
          { 
            name : 'employee', 
            label : 'Employee',
            type : 'select', 
            size : 4,
            idField: 'id',
            valueField: 'name',
            selections : props.selections ? props.selections['employees'] : [],
            value : props.search && props.search['employee'] ? props.search['employee'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'schedule', 
            label_start : 'From Date',
            label_end : 'To',
            type : 'daterange', 
            size : 6,
            required: false,
            clearable : true,
            value : props.search && props.search['from_schedule'] ? [props.search['from_schedule'], props.search['to_schedule']] : [],
            onChange : handlers.handleDateRangeChange,
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            size : 2,
          },
        ],
      ],
    ];
  }

  renderRowData(data){
    
    return [
      this.renderCell(
        <>
          {data.employee_name ? data.employee_name : 'N/A'}
        </>
      ),
      this.renderCell(data.schedule),
      this.renderCell(data.day),
      this.renderCell(data.punch ? data.punch.in : 'N/A'),
      this.renderCell(data.punch ? data.punch.out : 'N/A'),
      this.renderCell(data.work_time),
      this.renderCell(data.break_time),
      this.renderCell(data.remark),
      this.renderCell(data.overunder),
      this.renderCell(
        <Badge variant={data.approved ? 'info' : 'secondary'}>
          {data.approved ? 'Approved' : 'Pending Approval'}
        </Badge>
      ),
      this.renderCell(
        <Badge variant={data.finalized ? 'dark' : 'secondary'}>
          {data.finalized ? 'Finalized' : 'Pending Review'}
        </Badge>
      ),
    ];
  }

  renderSubRowData(data){
    
    return [
      this.renderCell(
        <>
        {data.project_title}
        </>,
        { colSpan:2 , align : 'center' }
      ),
      this.renderCell(
        <>
        {data.task_title}
        </>
      ),
      this.renderCell(
        <>
        {data.start_time}
        {data.start_note ? 
        (<><br/><strong>Start Note : </strong>{data.start_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        {data.end_time}
        {data.end_note ? 
        (<><br/><strong>End Note : </strong>{data.end_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        {data.duration}
        </>
      ),
      this.renderCell(
        <></>,
        { colSpan: 3 }
      ),
      this.renderCell(
        <Badge variant={data.approved ? 'info' : 'secondary'}>
          {data.approved ? 'Approved' : 'Pending Approval'}
        </Badge>
      ),
      this.renderCell(
        <></>,
      ),
    ];
  }

  renderTotalRow(totals){
    if(totals){
      return [
        [
          this.renderCell(
            <></>,
            { colSpan: 7 }
          ),
          this.renderCell(
            <strong style={{ fontSize : 30 }}>Total Hours</strong>,
            { colSpan: 2, align: 'right' },
          ),
          this.renderCell(
            <span style={{ fontSize : 30 }}>{totals['actual']}</span>,
            { colSpan: 2, align: 'center' },
          ),
        ],
        [
          this.renderCell(
            <></>,
            { colSpan: 7 },
          ),
          this.renderCell(
            <strong style={{ fontSize : 30 }}>Total Work Hours</strong>,
            { colSpan: 2, align: 'right' },
          ),
          this.renderCell(
            <span style={{ fontSize : 30 }}>{totals['work']}</span>,
            { colSpan: 2, align: 'center' },
          ),
        ],
        [
          this.renderCell(
            <></>,
            { colSpan: 7 }
          ),
          this.renderCell(
            <strong style={{ fontSize : 30 }}>Total Days</strong>,
            { colSpan: 2, align: 'right' },
          ),
          this.renderCell(
            <span style={{ fontSize : 30 }}>{totals['present']}</span>,
            { colSpan: 2, align: 'center' },
          ),
        ],
      ]
    }
    return null;
  }
}

// Set default props
List.defaultProps = {
  isAdmin: true,
  canUpdate: true,
  canDelete: true,
  canCreate : true,
  CustomFilter : null,
};

const mapStateToProps = (state) => {
  return {
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(List));