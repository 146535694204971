import React from "react";

import { withRouter } from "react-router-dom";

import Listx from "../../../../components/listx";
import GlobalCrud from "../../../../crud/global.crud";
import Chip from '@material-ui/core/Chip';

class Users extends Listx {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      action_loading : false,
    };

    this.headings = [
      { id: '#' },
      { id: 'user_name', label: 'User' },
      { id: 'targets', label: 'Targets', sort: false },
    ];

    this.post_status_fields = ['active'];

    this.api = new GlobalCrud('todo/my/tasks');

    this.useModal();

  }

  renderRowData(data){

    return [
      this.renderCell(data.user_name),
      this.renderCell(
        <>
        { data.target_all ? (
          <>ALL</>
        ):(
          <>
          {data.targets_list.map( (target, index) => (
            <Chip key={index} color="default" variant="outlined" label={target.name} size="small" style={{margin: 1}}/>
          ))}
          </>
        )}
        </>
      ),
      this.renderCell(
        <>
          {this.defaultActions(data.id, data)}
        </>
      , null, true),
    ];
  }

  fields(values){
    const { selections } = this.props;
    console.log(values);
    return [
      [
        [
          {
            name : 'id',
            type : 'hidden',
            value : values && values['id'] ? values['id'] : '',
          },
          { 
            name : 'user', 
            type : 'select', 
            required : true, 
            size : 12,
            value : values && values['user'] ? values['user'] : [],
            selections : selections && selections['users'] ? selections['users'] : [],
            idField : 'id',
            valueField : 'email',
          },
          { 
            name : 'target_all', 
            type : 'checkbox', 
            label : 'Target All',
            required : false, 
            size : 4,
            value : values ? values['target_all'] : true
          },
          { 
            name : 'targets', 
            type : 'multiselect', 
            required : false, 
            size : 12,
            value : values && values['targets'] ? values['targets'] : [],
            selections : selections && selections['users'] ? selections['users'] : [],
            idField : 'id',
            valueField : 'nick_name',
          },
        ]
      ]
    ];
  }
}

export default withRouter(Users);