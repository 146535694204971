import React from "react";
import {
  Link,
} from "react-router-dom";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Listx from "../../../../../components/listx";
import * as page from "../../../../../store/ducks/page.duck";

class List extends Listx {

  constructor(props) {
    super(props);
    
    this.headings = [
      { id: 'name', label: 'Employee' },
      { id: 'start', label: 'Time In' },
      { id: 'end', label: 'Time Out' },
      { id: 'task_time', label: 'Task Time' },
      { id: 'work_time', label: 'Total Time' },
      { id: 'break_time', label: 'Total Break' },
    ];
    
    this.setFilterBar(this.filter);
  }
  
  filter(props, handlers){

    return [
      [
        [
          { 
            name : 'wildcard', 
            label : 'Type in keyword',
            type : 'text', 
            size : 5,
            value : props.search && props.search['wildcard'] ? props.search['wildcard'] : '',
            onChange : handlers.handleChange
          },
          { 
            name : 'employee', 
            label : 'Employee',
            type : 'select', 
            size : 2,
            idField: 'id',
            valueField: 'name',
            ignore: !props.isAdmin,
            selections : props.selections ? props.selections['employees'] : [],
            value : props.search && props.search['employee'] ? props.search['employee'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'date', 
            label_start : 'From Date',
            label_end : 'To',
            type : 'daterange', 
            size : 4,
            required: false,
            clearable : true,
            value : props.search && props.search['from_date'] ? [props.search['from_date'], props.search['to_date']] : [],
            onChange : handlers.handleDateRangeChange,
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            offset : (!props.isAdmin ? 2 : 0),
            size : 1,
          },
        ],
      ],
    ];
  }

  renderRowData(data){
    return [
      this.renderCell(
        <>
        {data.employee_info ? data.employee_info.name : 'N/A'}
        {data.employee_info && data.employee_info.companyid ? 
        (<><br/><strong>ID : </strong>{data.employee_info.companyid}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        {data.start}
        {data.start_note ? 
        (<><br/><strong>Note : </strong>{data.start_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        {data.end}
        {data.end_note ? 
        (<><br/><strong>Note : </strong>{data.end_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(data.task_time),
      this.renderCell(data.work_time),
      this.renderCell(data.break_time),
      this.renderCell(
        <>
          <span className="pr-2">
            <Link to={this.props.baseUrl+'/mg/'+data.id+'/breaks/create'} className="btn btn-xs btn-primary btn-icon" title="Add Break">
              <i className="flaticon-tea-cup"></i>
            </Link>
          </span>
          {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

  renderSubRowData(data){
    const { baseUrl, crudUrl, canUpdate } = this.props;
    
    const base_url = (baseUrl ? baseUrl : crudUrl.getBaseUrl()) + '/mg/' + data.attendance+'/breaks';
    
    return [
      this.renderCell(data.title, { 'colSpan' : 3, 'align' : 'center' }),
      this.renderCell(
        <>
        {data.start}
        {data.start_note ? 
        (<><br/><strong>Note : </strong>{data.start_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        {data.end}
        {data.end_note ? 
        (<><br/><strong>Note : </strong>{data.end_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(
        <>
        { !canUpdate &&
          <Link to={base_url+'/update/'+data.id} className="btn btn-xs btn-primary btn-icon" title="Update">
            <i className="flaticon-edit"></i>
          </Link>
        }
        {this.defaultActions(data.id, null, null, null, base_url)}
        </>
      , null, true),
    ];
  }
}

// Set default props
List.defaultProps = {
  canUpdate: true,
  canDelete: true,
  canCreate : true,
  isAdmin : true,
  CustomFilter : null,
};

const mapStateToProps = (state) => {
  return {
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(List));