/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";

import GlobalCrud from "../crud/global.crud";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function RecentActivities({ recent_activity }) {
  
  const [activitiesData, setActivitiesData] = useState([]);

  useEffect(() => {
    const myApi = new GlobalCrud('account');
    const fetchTimesheetData = async () => {
      const result = await myApi.getRecord("activities");
      if(result.status === 200){
        setActivitiesData(result.data);
      }
    };

    fetchTimesheetData();
  }, []);

  useEffect(() => {
    if(recent_activity){
      let index = activitiesData.findIndex(d => d === recent_activity);
      if(index < 0){
        setActivitiesData([recent_activity, ...activitiesData]);
      }
    }
  }, [recent_activity, activitiesData]);

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Recent Activities</h3>
          </div>
        </div>
        <div className="kt-portlet__body" >
          {/* style="max-height: 50vh;" */}
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ maxHeight: "30vh", position: "relative" }}
          >
          <div className="kt-timeline-v2 ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">

              { activitiesData.map( (activity, index) => (
                <div key={index} className="kt-timeline-v2__item">
                  <span className="kt-timeline-v2__item-time">{ activity.time }</span>
                  <div className="kt-timeline-v2__item-cricle">
                    <i className="fa fa-genderless kt-font-brand" />
                  </div>
                  <div className="kt-timeline-v2__item-text kt-padding-top-5">
                    { activity.action }
                  </div>
                  <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20">
                    { activity.avatar &&
                      <a>
                        <img
                          src={activity.avatar}
                          title=""
                          alt=""
                        />
                      </a>
                    }
                  </div>
                </div>
              ))}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    recent_activity : state.ws.recent_activity,
  }
}

export default connect(
  mapStateToProps,
)(RecentActivities)