import React from "react";

import { withRouter, Link } from "react-router-dom";

import FormX from "app/components/formx";
import Listx from "app/components/listx";
import ButtonX from "app/components/buttonx";

import { getError } from "_metronic/utils/utils";
import GlobalCrud from "app/crud/global.crud";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: 'no', label: '#' },
      { id: 'name', label: 'Name/Email' },
      { id: 'gross', label: 'Gross' },
      { id: 'deduction', label: 'Deduction' },
      { id: 'net', label: 'Net' },
      { id: 'pay_period', label: 'Pay Period' },
      { id: 'pay_date', label: 'Pay Date' },
      { id: 'created', label: 'Date/Time' },
    ];

    this.state = {
      ...this.state,
      action_loading : false,
    }

    this.filter = this.filter.bind(this)
    this.setFilterBar(this.filter);

    this.onHire = this.onHire.bind(this);
    this.onTerminate = this.onTerminate.bind(this);

    this.api = new GlobalCrud("/payroll/records");
  }

  filter(props, handlers){
    return [
      [
        [
          { 
            name : 'employee', 
            label : 'Employee',
            type : 'select', 
            size : 4,
            idField: 'id',
            valueField: 'user_info.fullname',
            selections : props.selections ? props.selections['employees'] : [],
            value : props.search && props.search['employee'] ? props.search['employee'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'pay_date', 
            label_start : 'From Pay Date',
            label_end : 'To Pay Date',
            type : 'daterange', 
            size : 7,
            required: false,
            clearable : true,
            value : props.search && props.search['pay_date'] ? props.search['pay_date'] : [],
            onChange : handlers.handleDateRangeChange,
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            size : 1,
          },
        ],
      ],
    ];
  }

  updateStatus(id, mode){
    const { crudUrl } = this.props;
    this.setState({ action_loading : true });
    crudUrl.postRecord(id+"/"+mode).then(({ data }) => {
      //this.updatePage();
      let newData = [...this.state.data];
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      prevData.user_info.is_active = (mode === 'activate')
      //reset
      newData[index] = prevData;
      this.setState({ data : newData});
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      this.setState({ action_loading : false });
    });
  }

  hire_or_terminate_form(id, is_terminate){
    return <FormX 
      fields={[
        [
          [
            {
              name : 'id',
              type : 'hidden',
              value : id,
            },
            { 
              name : 'date', 
              label : (is_terminate ? "Termination" : "Hired")+' Date',
              type : 'date', 
              required : true, 
              size : 12,
              useNow : true,
            },
            { 
              name : 'note', 
              label : 'Note',
              type : 'textarea', 
              size : 12,
              value : ''
            },
          ]
        ]
      ]}
    />
  }

  onCustomSubmit(event, url){
    const form = event.currentTarget;
    this.setState({ action_loading : true, form_validated: true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      const { crudUrl } = this.props;
      //create
      this.setState({ action_loading : true });

      let newData = [...this.state.data];
      let id = parseInt(data.get('id'));
      let date = data.get('date');

      crudUrl.postRecord(url, data).then(({ result }) => {
        //this.updatePage();
        //find
        let index = newData.findIndex(d => d.id === id);
        
        if(index >= 0){
          let prevData = newData[index];
          //update
          prevData.status = !prevData.status;
          if(url.includes("hire")){
            prevData.hire_date = date;
          }else{
            prevData.termination_date = date;
          }
          //reset
          newData[index] = prevData;
        }
      })
      .catch((error) => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        alert(msg.join("\r\n"));
        this.setState({ 
          action_loading : false,
          form_validated: false,
        });
      })
      .then(() => {
        this.setState({ 
          action_loading : false,
          modalShow : false,
          data: newData,
          form_validated: false,
        });
      });
    }
  }

  onHire(event, url){
    this.onCustomSubmit(event,url);
  }

  onTerminate(event, url) {
    this.onCustomSubmit(event,url);
  }

  renderRowData(data){
    const { crudUrl, baseUrl } = this.props;

    let base_uri = (baseUrl ? baseUrl : crudUrl.getBaseUrl());

    let append_buttons = [];

    append_buttons.push((
      <Link to={base_uri+'/view/'+data.id} className={"kt-nav__link"} title="View">
        <i className={"kt-nav__link-icon flaticon-eye"}></i>
        <span className="kt-nav__link-text">View</span>
      </Link>
    ));

    return [
      this.renderCell((
        <Link to={base_uri+'/view/'+data.id} className={"kt-nav__link"} title="View">
          <span className="kt-nav__link-text">{data.no}</span>
        </Link>
      )),
      this.renderCell(data.employee && data.employee.user_info.nick_name ? data.employee.user_info.nick_name : data.employee.user_info.fullname),
      this.renderCell(data.totals && data.totals.income ? data.totals.income.gross : "N/A"),
      this.renderCell(data.totals && data.totals.deduction ? data.totals.deduction.undertime : "N/A"),
      this.renderCell(data.totals ? data.totals.net : "N/A"),
      this.renderCell(
        <>{data.start} - {data.end}</>
      ),
      this.renderCell(data.pay_date),
      this.renderCell(data.created),
      this.renderCell(
        <>
          <span className='pr-2'>
            <ButtonX 
              onClick={() => {
                this.setState({ action_loading : true });
                crudUrl.getRecord(data.id+"/download/", {}, 'blob').then((response) => {
                  const filename = "Payslip_" + data.no + ".pdf";
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                })
                .catch((error) => {
                  alert(getError(error));
                })
                .then(() => {
                  this.setState({ action_loading : false });
                });
              }} 
              icon="flaticon2-download" 
              className="btn btn-success btn-xs btn-icon" 
              title="Download" 
              loading={this.state.action_loading}
              />
          </span>
          <span className='pr-2'>
            <ButtonX 
            onClick={() => {
                this.setState({ action_loading : true });
                crudUrl.getRecord(data.id+"/send/", {}).then(({ data }) => {
                  alert(data.message);
                })
                .catch((error) => {
                  alert(getError(error));
                })
                .then(() => {
                  this.setState({ action_loading : false });
                });
            }} 
            icon="flaticon2-paper-plane" 
            className="btn btn-success btn-xs btn-icon" 
            title={"Send to " + data.employee ? data.employee.user_info.email : ''} 
            loading={this.state.action_loading}
            />
           </span>
           { this.defaultActions(data.id, data, true, append_buttons) }
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);