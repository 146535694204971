import React from "react";

class BaseDropzone extends React.Component {
  constructor(props) {
    super();

    this.state = {
      files : [],
    };

    this.handleDropzoneChange = this.handleDropzoneChange.bind(this);
  }
  handleDropzoneChange(files){
    this.setState({files : files});
  }
}

export default BaseDropzone;