import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import {
  withRouter,
  Redirect
} from "react-router-dom";

import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "../partials/content/Portlet";
import AlertX from "./alertx";

class Crud extends React.Component {
  constructor(props) {
    super();
    let success = '', error = '';
    if(props.location.state){
      if("success" in props.location.state){
        success = props.location.state.success;
      }
      if("error" in props.location.state){
        error = props.location.state.error;
      }
    }

    this.state = {
      validated: false,
      error : error,
      success : success,
      redirect : false,
      initialValues : undefined,
      isDeleted : false,
      loading : false,
      submit_loading : false,
      redirect_new : false,
    };

    
  }

  executeSubmit(redirect_new){
    this.setState({redirect_new : redirect_new});
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    this.setState({ validated: true, error: '', success: '', submit_loading : true });

    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ submit_loading : false });
    }else{
      const data = new FormData(form);
      const { crudUrl, isUpdate } = this.props;
      const { create } = crudUrl;

      let mode = 'submitted';
      
      if(isUpdate){
        mode = 'updated';
      }else{
        if(this.props.match.params.id > 0){
          mode = 'updated';
        }
      }
      //create
      create(data)
      .then(( {data} ) => {
        if(this.state.redirect_new){
          this.props.history.push(this.props.baseUrl);
          this.props.history.replace({pathname: this.props.baseUrl+"/create", state : { success : 'Successfully '+mode+' form' }});
        }else{
          this.setState({ error: '', success: 'Successfully '+mode+' form', initialValues: data, submit_loading : false, validated : false }, this.updateParent(data));        
        }
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
        }
        this.setState({ error: msg.join("\r\n"), submit_loading : false, validated: false });
      });
    }
  }

  executeDelete(){
    const { crudUrl } = this.props;
    let id = this.props.match.params.id;

    this.setState({ error: '', success: '', submit_loading : true });
    crudUrl.delete(id).then(( {data} ) => {
      this.setState({ isDeleted : true, submit_loading : false });
    })
    .catch(error => {
      let msg = [];
      Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      this.setState({ error: msg.join("\r\n"), submit_loading : false });
    });
  }
  
  componentDidMount(){
    const { initialValues } = this.state;
    const { match, crudUrl, isDelete, updateParent } = this.props;
    const { read } = crudUrl;
    let id = match.params.id;

    if(id && !initialValues && !isDelete && !updateParent){
      //get initial values
      this.setState({ loading : true });
      read(id).then(( { data } ) => {
        this.setState({ initialValues: data, loading : false });
      })
      .catch(error => {
        this.setState({ redirect: true });
      });
    }
  }

  componentWillReceiveProps(props){
    const { initialValues } = this.state;
    if(!initialValues && props.initialValues){
      this.setState({initialValues : props.initialValues});
    }
  }

  updateParent(value){
    const { updateParent, updateParentState } = this.props;
    if(updateParent){
      updateParent(updateParentState, value);
    }
  }

  render() {
    const { validated, initialValues, error, success, redirect, isDeleted, loading, submit_loading, redirect_new } = this.state;
    const { baseUrl, selections, isDelete, CustomForm, colmd, successRedirect, form_only, no_redirect, enctype, isAdmin, updateValues, isDisabled } = this.props;

    if(!no_redirect){
      if (isDelete){
        if(isDeleted){
          return <Redirect to={{pathname: baseUrl, state: { message : 'Successfully deleted', message_variant : 'success' }}} />
        }
      }else{
        if (success !== '' && (this.props.match.params.id === 0 || !this.props.match.params.id)) {      
          if(initialValues && !redirect_new){
            //return <Redirect to={{pathname: successRedirect ? baseUrl+(successRedirect.endsWith('/') ? successRedirect : successRedirect+'/')+initialValues.id :  baseUrl+"/update/"+initialValues.id, state : { success : success}}} />
            return <Redirect to={{pathname: successRedirect ? baseUrl+(successRedirect.endsWith('/') ? successRedirect : successRedirect+'/')+initialValues.id :  baseUrl, state : { success : success}}} />
          }else{
            //return <Redirect to={{pathname: baseUrl+"/create", state : { success : success }}} />
          }
        }
        if (redirect){
          return <Redirect to={{pathname: baseUrl, state: { message : 'Invalid Page : ' + this.props.location.pathname, message_variant : 'danger' }}} />
        }
    
        if(!initialValues && this.props.match.params.id > 0){
          return <></>;
        }
      }
    }
    
    //console.log(initialValues, success, 'hey');
    return (
      <div className="row">
      <div className={"col-md-" + (isDelete ? '6' : colmd)}>
        { form_only ? (
          <>
            { success !== '' &&
              <AlertX variant="success" message={success}/>
            }
            { error !== '' &&
              <AlertX variant="danger" message={error}/>
            }
            <Form
              noValidate
              validated={validated}
              onSubmit={e => this.handleSubmit(e)}
              encType={enctype}
            >
              {CustomForm({ initialValues : initialValues, selections : selections, isAdmin : isAdmin, validated : validated, updateValues:updateValues})}
              { !isDisabled &&
              <span className="pr-1">
                <Button className="btn btn-success btn-sm" onClick={this.executeSubmit.bind(this, false)} type="submit" disabled={submit_loading}>
                  { submit_loading ? (
                    <>
                    <Spinner
                      as="span"
                      size="sm"
                      animation="grow"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                    </>
                  ) : (
                    <><i className="fa fa-save" /> Submit form</>
                  )}
                </Button>
              </span>
              }
            </Form>
          </>
        ):(
          <Portlet>
            <PortletHeader title={ isDelete ? 'Are you sure you want to continue deleting?' : 'Form'} toolbar={(
              <PortletHeaderToolbar>
                <Button
                  onClick={()=> this.props.history.goBack()}
                  variant="outline-dark"
                  className='btn-sm'>
                  <i className="fa fa-arrow-left" /> Back
                </Button>
              </PortletHeaderToolbar>)}/>
            <PortletBody>
              
            { loading ? (
                <div className="text-center">
                  <Spinner animation="border"/>
                </div>
              ) : (
                <>
                { error !== '' &&
                  <AlertX variant="danger" message={error}/>
                }
                { isDelete && 
                  <Form.Row>
                    <span className="pr-1">
                      <Button
                        onClick={()=> this.props.history.goBack()}
                        variant="outline-dark">
                        <i className="fa fa-arrow-left" /> Back
                      </Button>
                    </span>
                    <span className="pr-1">
                      <Button className="btn btn-danger ng-star-inserted" onClick={this.executeDelete.bind(this)} disabled={submit_loading}>
                        { submit_loading ? (
                          <>
                          <Spinner
                            as="span"
                            size="sm"
                            animation="grow"
                            role="status"
                            aria-hidden="true"
                          />
                          &nbsp;&nbsp;Loading...
                          </>
                        ) : (
                          <><i className="fa fa-trash" /> Delete</>
                        )}
                      </Button>
                    </span>
                  </Form.Row>
                }
                { !isDelete && 
                  <>
                  { success !== '' &&
                    <AlertX variant="success" message={success}/>
                  }
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.handleSubmit(e)}
                    >
                    {CustomForm({ initialValues : initialValues, selections : selections, isAdmin : isAdmin, validated : validated, updateValues:updateValues})}
                    <span className="pr-1">
                      <Button
                        onClick={()=> this.props.history.goBack()}
                        variant="outline-dark">
                        <i className="fa fa-arrow-left" /> Back
                      </Button>
                    </span>
                    { !isDisabled &&
                    <>
                      <span className="pr-1">
                        <Button onClick={this.executeSubmit.bind(this, false)} className="btn btn-primary ng-star-inserted" type="submit" disabled={submit_loading}>
                          { submit_loading ? (
                            <>
                            <Spinner
                              as="span"
                              size="sm"
                              animation="grow"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp;&nbsp;Loading...
                            </>
                          ) : (
                            <><i className="fa fa-save" /> Submit form</>
                          )}
                        </Button>
                      </span>
                      { (this.props.match.params.id === 0 || !this.props.match.params.id) &&
                        <span className="pr-1">
                          <Button className="btn ng-star-inserted" onClick={this.executeSubmit.bind(this, true)} type="submit" variant="success" disabled={submit_loading}>
                            { submit_loading ? (
                              <>
                              <Spinner
                                as="span"
                                size="sm"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              />
                              &nbsp;&nbsp;Loading...
                              </>
                            ) : (
                              <><i className="fa fa-save" /> Submit form and create new</>
                            )}
                          </Button>
                        </span>
                      }
                    </>
                    }
                  </Form>
                  </>
                }
                </>
            )}
            </PortletBody>
          </Portlet>
        )}
      </div>
      </div>
    );
  }
}

// Set default props
Crud.defaultProps = {
  id: 0,
  isDisabled : false,
  isAdmin: true,
  isDelete: false,
  isUpdate: false,
  selections : {},
  colmd: 12,
  successRedirect : null,
  form_only : false,
  no_redirect : false,
  updateParent : null,
  updateParentState : '',
  enctype : 'application/x-www-form-urlencoded',
  updateValues : null,
};

export default withRouter(Crud);