import React from "react";
import { withRouter } from "react-router-dom";
import EmployeeAttendance from "../../hr/employee/attendance";

function Attendance(props) {

  const { access, ...others } = props;
  let new_access = {
    isAdmin : false,
    canCreate : false,
    canUpdate : false,
    canDelete : false,
  };
  return (
    <EmployeeAttendance 
    access={new_access}
    canCreateBreak={true}
    canUpdateBreak={true}
    {...others}
    path={props.match.path}
    baseUrl='hr/my/timekeeping/attendance'
    myMode={true}
    />
  )
}

export default withRouter(Attendance);