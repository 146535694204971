import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../components/crudpageX";
import GlobalCrud from "../../../../../crud/global.crud";

import Info from "../../../my/account/info";
import Create from "./create";

export default function EmployeeRecords(props) {

  const history = useHistory();
  const [selections, setSelections] = useState();
  const [updateValues, setUpdateValues] = useState(null);
  
  useEffect(() => {
    const myApi = new GlobalCrud("/account");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  const onEmailChange = (e) => {
    setUpdateValues({email : e.target.value});
  }
  const fields = (props, state, params = null) => {
    let fields = [
      [
        [
          /*{ 
            name : 'user_id', 
            type : 'select', 
            label : 'Assign to existing user?',
            size : 3,
            idField : 'id',
            valueField : 'fullname',
            selections : state.selections ? state.selections['available_users'] : [],
            //value : props.initialValues && props.initialValues[''] ? props.initialValues['country'] : ''
          },*/
          { 
            name : 'user_email', 
            label : 'Email',
            type : 'email', 
            required : true, 
            size : 4,
            onChange : onEmailChange,
            value : props.initialValues && props.initialValues['user_email'] ? props.initialValues['user_email'] : ''
          },
          { 
            name : 'user_first_name', 
            label : 'First Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['user_first_name'] ? props.initialValues['user_first_name'] : ''
          },
          { 
            name : 'user_last_name', 
            label : 'Last Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['user_last_name'] ? props.initialValues['user_last_name'] : ''
          },
          { 
            name : 'user_nick_name', 
            label : 'Nick Name',
            type : 'text', 
            required : true,
            size : 4,
            value : props.initialValues && props.initialValues['user_nick_name'] ? props.initialValues['user_nick_name'] : ''
          },
          { 
            name : 'companyid', 
            label : 'Employee ID',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['companyid'] ? props.initialValues['companyid'] : ''
          },
          { 
            name : 'email', 
            label : 'Employee Email',
            type : 'email', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['email'] ? props.initialValues['email'] : '',
          },
          { 
            name : 'user_address', 
            label : 'Address',
            type : 'textarea', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['user_address'] ? props.initialValues['user_address'] : ''
          },
          { 
            name : 'user_country', 
            label : 'Country',
            type : 'select', 
            size : 3,
            selections : props.selections ? props.selections['countries'] : [],
            value : props.initialValues && props.initialValues['country'] ? props.initialValues['country'] : ''
          },
          { 
            name : 'user_city', 
            label : 'City',
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['city'] ? props.initialValues['city'] : ''
          },
          { 
            name : 'user_state', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['state'] ? props.initialValues['state'] : ''
          },
          { 
            name : 'user_zipcode', 
            label : 'Zipcode',
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['zipcode'] ? props.initialValues['zipcode'] : ''
          },
          { 
            name : 'user_birthdate', 
            label : 'Birthdate',
            type : 'date', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['user_birthdate'] ? props.initialValues['user_birthdate'] : ''
          },
          { 
            name : 'user_gender', 
            label : 'Gender',
            type : 'radio', 
            required : true, 
            size : 4,
            selections : props.selections['genders'],
            value : props.initialValues && props.initialValues['user_gender'] ? props.initialValues['user_gender'] : ''
          },
          { 
            name : 'user_maritalstatus', 
            label : 'Marital Status',
            type : 'select', 
            required : true, 
            size : 4,
            selections : props.selections['maritalstatuses'],
            value : props.initialValues && props.initialValues['user_maritalstatus'] ? props.initialValues['user_maritalstatus'] : ''
          },
        ]
      ]
    ]
    return fields;
  }

  const { path } = props.match;
  const { access } = props;
  
  const list = (props) => {
    return <List {...props} onNewclick={() => history.push(path + '/createemployee')}/>
  }


  return (
    <Switch>
      <Route 
        path={path + "/info/:id"}
        children={
          <Info 
            access={access} 
            path="/hr/employee/records"
          />
        } 
      />
      <Route 
        path={path + "/createemployee"}
        children={
          <Create 
            access={access} 
            path="/hr/employee/records"
            selections={selections}
          />
        } 
      />
      { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='8'
        fields={fields}
        title='Employees'
        getData={true}
        successRedirect="/info/"
        selections={selections}
        updateValues={updateValues}
        {...access}
        />} 
      />
      }
    </Switch>
  )
}