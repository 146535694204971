import {
  withRouter,
} from "react-router-dom";
import ListX from "app/components/listx";

class History extends ListX {

  constructor(props) {
    super(props);

    this.title = "History";
    this.headings = [
      { id: '#', label: '#' },
      { id: 'tag', label: 'Tag' },
      { id: 'note', label: 'Note' },
      { id: 'user', label: 'By' },
      { id: 'created', label: 'Date/Time' },
    ];

  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.currentPage !== this.state.currentPage){
      this.updatePage();
      return;
    }else if(prevState.currentPerPage !== this.state.currentPerPage){
      this.updatePage();
      return;
    }
  }
  
  renderRowData(data){
    return [
      this.renderCell(data.tag.toUpperCase()),
      this.renderCell(data.note),
      this.renderCell(data.user_name),
      this.renderCell(data.created),
    ];
  }
}

export default withRouter(History);