import React, { Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Dashboard from "./Dashboard";
import Hr from "./hr";
import My from "./my";
import Todo from "./todo";
import Account from "./account";
import CRM from "./crm";
import Billing from "./billing";
import System from "./system";
import { LayoutSplashScreen } from "../../../_metronic";
import Payroll from "./payroll";

import AlertX from "../../components/alertx";

export default function HomePage(props) {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  // const { isAuthorized, menuConfig, userLastLocation, access } = useSelector(
  //   ({ authm builder: { menuConfig } }) => ({
  //     access : auth.user && auth.user.access ? auth.user.access : {},
  //     menuConfig,
  //     isAuthorized: auth.user != null,
  //     userLastLocation: routerHelpers.getLastLocation(),
  //   }),
  //   shallowEqual
  // );

  const AlertMessage = withRouter((props) => {
    return (
      <>
      { props.location.state && 
      <>
      { "message" in props.location.state &&
      <AlertX message={props.location.state.message} variant={props.location.state.message_variant}/>
      }
      </>
      }
      </>
    )
  });
  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <AlertMessage/>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/hr" render={() => <Hr {...props} />}/>
        <Route path="/my" render={() => <My {...props} />} />
        <Route path="/todo" render={() => <Todo {...props} />} />
        <Route path="/crm" render={() => <CRM {...props} />} />
        <Route path="/billing" render={() => <Billing {...props} />} />
        {/* <Route path="/todo/projects/:projectId?" exact={false} render={() => <Todo {...props} />} /> */}
        <Route path="/payroll" render={() => <Payroll {...props} />} />
        <Route path="/account" render={() => <Account {...props} />}/>
        <Route path="/system" render={() => <System {...props} />}/>
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
