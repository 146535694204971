import React from "react";

import { withRouter } from "react-router-dom";

import Listx from "../../../../../../components/listx";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'day', label: 'Day' },
      { id: 'start', label: 'Start' },
      { id: 'duration', label: 'Duration' },
    ];
  }


  renderRowData(data){
    return [
      this.renderCell(data.day_str),
      this.renderCell(data.start),
      this.renderCell(data.duration_str),
      this.renderCell(
        <>
        {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);