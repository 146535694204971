import React, { useState, useEffect } from "react";
import { Form, Spinner } from 'react-bootstrap';
import {Portlet, PortletHeader, PortletBody } from "app/partials/content/Portlet";
import FormX from "app/components/formx";
import GlobalCrud from "app/crud/global.crud.js";

function SystemSettings(props){
  
  const { group, sub_group } = props;

  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  
  const baseApi = "app/system";
  const target_endpoint = group + "/" + sub_group;
  
  useEffect(() => {
    const fetchData = async () => {
      const crudUrl = new GlobalCrud(baseApi);
      const result = await crudUrl.getRecord("view/"+target_endpoint);
      if(result.status === 200){
        console.log(result.data);
        setCurrentData(result.data);
      }
    };

    fetchData();
  }, [baseApi, target_endpoint]);
  
  const handleSubmit = (event) =>{
    const form = event.currentTarget;
    setLoading(true);

    form.classList.add('was-validated');

    event.preventDefault();
    event.stopPropagation();
    
    if (form.checkValidity() === false) {  
      //not valid
      setLoading(false);
    }else{
      const data = new FormData(form);
      const computeAPI = new GlobalCrud(baseApi+"/update/"+target_endpoint);
      computeAPI.create(data).then(({ data }) => {
        setCurrentData(data);
      })
      .catch((error) => {
        console.log(error);
      }).then(() => {
        setLoading(false);
      });
    }
  }

  const forms = () => {
    let fields = <></>;
    switch(group){
      case "app":
        switch(sub_group){
          case "company":
            fields = (
              <div className='col-md-6'>
                <FormX 
                  fields={[
                    [
                      [
                        { 
                          name : 'name', 
                          label : 'Company Name',
                          type : 'text', 
                          required : true,
                          size : 12,
                          value : currentData && currentData['name'] ? currentData['name'] : '',
                        },
                        { 
                          name : 'address_1', 
                          label : 'Address 1',
                          type : 'textarea', 
                          required : true,
                          size : 12,
                          value : currentData && currentData['address_1'] ? currentData['address_1'] : '',
                        },
                        { 
                          name : 'address_2', 
                          label : 'Address 2',
                          type : 'textarea', 
                          required : false,
                          size : 12,
                          value : currentData && currentData['address_2'] ? currentData['address_2'] : '',
                        },
                        {
                          name : 'submit',
                          label : 'Save',
                          text : 'Save',
                          type : 'button',
                          icon : 'fa fa-save',
                          variant : 'primary',
                          className : 'btn-sm btn-block',
                          size : 12,
                          loading : loading,
                        },
                      ]
                    ]
                  ]}
                />
              </div>
            )
            break;
          case "email":
            fields = (
              <div className='col-md-6'>
                <FormX 
                  fields={[
                    [
                      [
                        { 
                          name : 'email', 
                          label : 'Email',
                          type : 'email', 
                          required : true,
                          size : 6,
                          value : currentData && currentData['email'] ? currentData['email'] : 'no-reply@3wcorner.com',
                        },
                        { 
                          name : 'name', 
                          label : 'Name',
                          type : 'text', 
                          required : true,
                          size : 6,
                          value : currentData && currentData['name'] ? currentData['name'] : '3w App',
                        },
                        { 
                          name : 'host', 
                          label : 'Host',
                          type : 'text', 
                          required : true,
                          size : 6,
                          value : currentData && currentData['host'] ? currentData['host'] : '3wcorner.com',
                        },
                        { 
                          name : 'port', 
                          label : 'Port',
                          type : 'text', 
                          required : true,
                          size : 12,
                          value : currentData && currentData['port'] ? currentData['port'] : '587',
                        },
                        { 
                          name : 'user', 
                          label : 'User',
                          type : 'text', 
                          required : true,
                          size : 6,
                          value : currentData && currentData['user'] ? currentData['user'] : 'testing@3wcorner.com',
                        },
                        { 
                          name : 'password', 
                          label : 'Password',
                          type : 'password', 
                          required : false,
                          size : 6,
                          value : '',
                        },
                        { 
                          name : 'use_tls', 
                          label : 'Use TLS', 
                          type : 'checkbox', 
                          required : false, 
                          size : 4,
                          value : currentData ? (currentData['use_tls'] && currentData['use_tls'] !== "0") : false
                        },
                        {
                          name : 'submit',
                          label : 'Save',
                          text : 'Save',
                          type : 'button',
                          icon : 'fa fa-save',
                          variant : 'primary',
                          className : 'btn-sm btn-block',
                          size : 12,
                          loading : loading,
                        },
                      ]
                    ]
                  ]}
                />
              </div>
            )
            break;
          default:
            break;
        }
        break;
        default:
          break;
    }
    return fields;
  }
  return (
    <Portlet>
      <PortletHeader title="Company Settings"/>
      <PortletBody>
        { loading ? (
          <div className="text-center">
            <Spinner animation="border"/>
          </div>
        ) : (
          <Form
            noValidate
            onSubmit={e => handleSubmit(e)}
          >
            <div className='row'>
              {forms()}
            </div>
          </Form>
        )}
      </PortletBody>
    </Portlet>
  )
}

export default SystemSettings;