import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Reload: "Reload Data",
};

const initialState = {
  module : '',
  reload : false,
};

export const reducer = persistReducer(
    { storage, key: "page-duck", whitelist: ["load", "unload"] },
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.Reload: {
          const { module } = action.payload;

          let s = { reload : !state.reload, module : module };
          return {...state, ...s};
        }

        default:
          return state;
      }
    }
);

export const actions = {
  reload: ( module ) => ({ type: actionTypes.Reload, payload : { module } }),
};

// export function* saga() {
//   yield takeLatest(actionTypes.Reload, function* reload() {
//     console.log('asd222asdasdasdasd');
//   });
  
// }
