import React from "react";
import { withRouter } from "react-router-dom";

import { Badge } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';

import { toAbsoluteUrl } from "../../../../../../_metronic/utils/utils";
import Listx from "../../../../../components/listx";
import ButtonX from "../../../../../components/buttonx";
import GlobalCrud from "../../../../../crud/global.crud";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'photo', label: 'Photo', sort : false },
      { id: 'username', label: 'Username' },
      { id: 'name', label: 'Name' },
      { id: 'nick_name', label: 'Nick Name' },
      { id: 'gender', label: 'Gender' },
      { id: 'birthdate', label: 'Birthdate' },
      { id: 'maritalstatus', label: 'Marital Status' },
      { id: 'status', label: 'Status' },
    ];

    this.api = new GlobalCrud("/account/users");

    this.setFilterBar(this.filter);

    this.state = {
      ...this.state,
      action_loading : false,
    }
  }

  filter(props, handlers){

    return [
      [
        [
          { 
            name : 'wildcard', 
            label : 'Type in keyword',
            type : 'text', 
            size : 3,
            value : props.search && props.search['wildcard'] ? props.search['wildcard'] : '',
            onChange : handlers.handleChange
          },
          { 
            name : 'roles', 
            label : 'Roles',
            type : 'select', 
            size : 2,
            selections : props.selections ? props.selections['roles'] : [],
            value : props.search && props.search['roles'] ? props.search['roles'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'gender', 
            label : 'Gender',
            type : 'select', 
            size : 2,
            selections : props.selections ? props.selections['genders'] : [],
            value : props.search && props.search['gender'] ? props.search['gender'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'maritalstatus', 
            label : 'Marital Status',
            type : 'select', 
            size : 2,
            selections : props.selections ? props.selections['maritalstatuses'] : [],
            value : props.search && props.search['maritalstatus'] ? props.search['maritalstatus'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'status', 
            label : 'Status',
            type : 'select', 
            size : 2,
            selections : [{ key : 1, value : 'Active' }, { key : 0, value : 'Inactive' }],
            value : props.search && !isNaN(parseInt(props.search['status'])) ? props.search['status'] : 3,
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            size : 1,
          },
        ],
      ],
    ];
  }

  updateStatus(id, mode){
    this.setState({ action_loading : true });
    this.api.postRecord(id+"/"+mode).then(({ data }) => {
      //this.updatePage();
      let newData = [...this.state.data];
      //find
      let index = newData.findIndex(d => d.id === id);
      let prevData = newData[index];
      //update
      prevData.is_active = (mode === 'activate')
      //reset
      newData[index] = prevData;
      this.setState({ data : newData});
    })
    .catch((error) => {
      console.log(error);
    })
    .then(() => {
      this.setState({ action_loading : false });
    });
  }

  renderRowData(data){
    let default_img = (data.gender === 'male' ? 21 : 11);

    return [
      this.renderCell(<Avatar variant="square" alt={data.first_name} src={ data.avatar ? data.avatar : toAbsoluteUrl("/media/users/300_"+default_img+".jpg")} />),
      this.renderCell(data.email),
      this.renderCell(data.fullname),
      this.renderCell(data.nick_name),
      this.renderCell(data.gender.toUpperCase()),
      this.renderCell(data.birthdate),
      this.renderCell(data.maritalstatus.toUpperCase()),
      this.renderCell(<Badge variant={data.is_active ? 'success' : 'danger'}>{data.is_active ? 'Active' : 'Inactive'}</Badge>),
      this.renderCell(
        <>
          { this.props.canUpdate &&
            <span className="pr-2">
              { data.is_active ? (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'deactivate')} 
                icon="flaticon2-cross" 
                className="btn btn-warning btn-xs btn-icon" 
                title="Deactivate" 
                loading={this.state.action_loading}
                />
              ) : (
                <ButtonX 
                onClick={this.updateStatus.bind(this, data.id, 'activate')} 
                icon="flaticon2-checkmark" 
                className="btn btn-success btn-xs btn-icon" 
                title="Activate" 
                loading={this.state.action_loading}
                />
              )}
            </span>
          }
          {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);