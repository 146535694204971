import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";

export default function QuickStatsBar({ 
  value,
  desc,
  // array of numbers
  data,
  // chart line color
  color,
  // chart line size
  labels,
}) {
  const ref = useRef();

  const bardata = useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          // label: 'dataset 1',
          backgroundColor: color,
          data: data
        }
      ]
    }),
    [color, data, labels]
  );

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data: bardata,
      type: "bar",
      options: {
        title: { display: false },
        tooltips: {
          intersect: false,
          mode: "nearest",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          xAxes: [{ display: false, gridLines: false, stacked: true }],
          yAxes: [{ display: false, stacked: true, gridLines: false }]
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [bardata]);

  return (
    <div className="kt-widget14">
      <div className="kt-widget14__header kt-margin-b-30">
        <h3 className="kt-widget14__title">{value}</h3>
        <span className="kt-widget14__desc">{desc}</span>
      </div>
      <div className="kt-widget14__chart" style={{ height: "120px" }}>
        <canvas ref={ref} />
      </div>
    </div>
  );
}
