import GlobalCrud from "./global.crud.js";

const api = new GlobalCrud("account");

export const LOGIN_URL = "auth/login";
export const LOGOUT_URL = "auth/logout";
export const REGISTER_URL = "auth/register";
export const ME_URL = "auth/me";
export const REQUEST_PASSWORD_URL = "send-reset-password-link/";
export const REQUEST_RESETPASSWORD_URL = "reset-password/";
export const VERIFYUSER_URL = "verify-registration/";

export function login(username, password) {
  return api.postRecord(LOGIN_URL, { username, password });
}

export function logmeout() {
  return api.postRecord(LOGOUT_URL);
}

export function register(email, fullname, username, password) {
  return api.postRecord(REGISTER_URL, { email, fullname, username, password });
}

export function verifyUser(password, user_id, timestamp, signature) {
  return api.postRecord(VERIFYUSER_URL, { password: password, user_id : user_id, timestamp : timestamp, signature : signature });
}

export function requestPassword(email) {
  return api.postRecord(REQUEST_PASSWORD_URL, { login : email });
}

export function resetPassword(password, user_id, timestamp, signature) {
  return api.postRecord(REQUEST_RESETPASSWORD_URL, { password: password, user_id : user_id, timestamp : timestamp, signature : signature });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return api.getRecord(ME_URL);
}
