/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom"
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";

import GlobalCrud from "../crud/global.crud";
import { ProgressBar } from "react-bootstrap";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function TodoActivities({ task_timer }) {
  
  const myApi = new GlobalCrud('todo/my');

  const [activitiesData, setActivitiesData] = useState([]);
  const [reset, setReset] = useState(true);

  useEffect(() => {
    const fetchTimesheetData = async () => {
      const result = await myApi.getRecord("activities");
      if(result.status === 200){
        setActivitiesData(result.data);
      }
    };

    if(reset){
      fetchTimesheetData();
      setReset(false);
    }
  }, [reset, myApi]);


  useEffect(() => {
    if(task_timer && task_timer.started){
      setReset(true);      
    }
  }, [task_timer]);
  
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Latest Tasks</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <Link to={'/my/todo'} className="btn btn-label-success btn-sm btn-bold">
              Go to Todo
            </Link>
          </div>
        </div>
        <div className="kt-portlet__body">
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ maxHeight: "30vh", position: "relative", paddingRight : 10, paddingLeft: 10 }}
          >
          <div className="kt-widget5">
            { activitiesData.map( (activity, index) => (
              <div key={index} className="kt-widget5__item ">
                <div className="kt-widget5__content">
                  <div className="kt-widget5__section">
                    <Link to={'/my/todo/'+activity.project_id} className="kt-widget5__title">
                      { activity.task }
                    </Link>
                    <p className="kt-widget5__desc">{ activity.description }</p>
                    <div className="kt-widget5__info">
                      <span>Project : </span>
                      <span className="kt-font-info">{ activity.project }</span>
                      <span>Onwer : </span>
                      <span className="kt-font-info">{ activity.owner }</span>
                    </div>
                  </div>
                </div>
                <div className="kt-widget5__content" style={{flexGrow:10, paddingLeft: '1rem'}}>
                  <ProgressBar striped variant="success" now={activity.progress} label={`${activity.progress}%`} style={{width:'100%'}}/>
                </div>
                <div className="kt-widget5__content">
                  <div className="kt-widget5__stats">
                    <span className="kt-widget5__number">{ activity.total_hours }</span>
                    <span className="kt-widget5__sales">Time</span>
                  </div>
                  <div className="kt-widget5__stats">
                    <span className="kt-widget5__number">{ activity.total_logs }</span>
                    <span className="kt-widget5__votes">Logs</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    task_timer : state.ws.task_timer,
  }
}

export default connect(
  mapStateToProps,
)(TodoActivities)