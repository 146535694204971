import React from "react";
import {
  withRouter,
} from "react-router-dom";
import { Spinner } from "react-bootstrap";

import Crud from "app/components/crud";
import FormX from "app/components/formx";
import GlobalCrud from "app/crud/global.crud.js";
import {Portlet, PortletHeader, PortletBody } from "app/partials/content/Portlet";

class Personal extends React.Component {

  fields(props, state){
    let avatar = {};
    if((props.initialValues && props.initialValues['avatar'])){
      avatar.src = props.initialValues['avatar'];
      avatar.value = props.initialValues['avatar_name'];
    }
    if(state && state.delete_avatar){
      avatar.value = 'delete';
      avatar.src = '';
    }
    let fields = [
      [
        [
          { 
            name : 'nick_name', 
            label : 'Nick Name',
            type : 'text', 
            required : true,
            size : 4,
            value : props.initialValues && props.initialValues['nick_name'] ? props.initialValues['nick_name'] : ''
          },
          { 
            name : 'first_name', 
            label : 'First Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['first_name'] ? props.initialValues['first_name'] : ''
          },
          { 
            name : 'last_name', 
            label : 'Last Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['last_name'] ? props.initialValues['last_name'] : ''
          },
          { 
            name : 'address', 
            label : 'Address',
            type : 'textarea', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['address'] ? props.initialValues['address'] : ''
          },
          { 
            name : 'country', 
            type : 'select', 
            size : 3,
            selections : props.selections ? props.selections['countries'] : [],
            value : props.initialValues && props.initialValues['country'] ? props.initialValues['country'] : ''
          },
          { 
            name : 'city', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['city'] ? props.initialValues['city'] : ''
          },
          { 
            name : 'state', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['state'] ? props.initialValues['state'] : ''
          },
          { 
            name : 'zipcode', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['zipcode'] ? props.initialValues['zipcode'] : ''
          },
          { 
            name : 'personal_email', 
            label : 'Personal Email',
            type : 'email', 
            size : 3,
            value : props.initialValues && props.initialValues['personal_email'] ? props.initialValues['personal_email'] : ''
          },
          { 
            name : 'home_number', 
            label : 'Home Telephone',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : props.initialValues && props.initialValues['home_number'] ? props.initialValues['home_number'] : ''
          },
          { 
            name : 'mobile_number', 
            label : 'Mobile',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : props.initialValues && props.initialValues['mobile_number'] ? props.initialValues['mobile_number'] : ''
          },
          { 
            name : 'work_number', 
            label : 'Work Telephone',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : props.initialValues && props.initialValues['work_number'] ? props.initialValues['work_number'] : ''
          },
          { 
            name : 'birthdate', 
            label : 'Birthdate',
            required : true, 
            type : 'date', 
            size : 4,
            value : props.initialValues && props.initialValues['birthdate'] ? props.initialValues['birthdate'] : ''
          },
          { 
            name : 'gender', 
            label : 'Gender',
            type : 'radio', 
            required : true, 
            size : 4,
            selections : props.selections ? props.selections['genders'] : [],
            value : props.initialValues && props.initialValues['gender'] ? props.initialValues['gender'] : ''
          },
          { 
            name : 'maritalstatus', 
            label : 'Marital Status',
            type : 'select', 
            required : true, 
            size : 4,
            selections : props.selections ? props.selections['maritalstatuses'] : [],
            value : props.initialValues && props.initialValues['maritalstatus'] ? props.initialValues['maritalstatus'] : ''
          },
          { 
            name : 'avatar', 
            label : 'Avatar',
            onDelete : () => {
              this.setState({
                delete_avatar : true,
              });
            },
            type : 'file', 
            size : 4,
            value : avatar
          },
        ]
      ]
    ]
    return fields;
  }
  
  form(props){
    return <FormX {...this.props} validated={props.validated}
      fields={this.fields(this.props, this.state)}
    />
  }

  render(){ 
    const { selections, initialValues, updateParent, updateParentState, page_title, loading } = this.props;
    const { url } = this.props.match;

    this.crudUrl = new GlobalCrud(this.props.baseUrl);
    
    return (
      <Portlet>
        <PortletHeader title={ page_title}/>
        <PortletBody>
          { loading ? (
            <div className="text-center">
              <Spinner animation="border"/>
            </div>
          ) : (
            <Crud 
                colmd="12"
                baseUrl={url}
                crudUrl={this.crudUrl} 
                selections={selections}
                initialValues={initialValues}
                isUpdate={true}
                CustomForm={this.form.bind(this)}
                form_only={true}
                no_redirect={true}
                updateParentState={updateParentState}
                updateParent={updateParent}
                enctype="multipart/form-data"
            />
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export default withRouter(Personal);