import React, { useState, useEffect } from "react";
import { Route, Switch} from "react-router-dom";
import List from "./list";
import CrudPageX from "../../../../../components/crudpageX";
import GlobalCrud from "../../../../../crud/global.crud";

export default function AccountRecords(props) {

  const [selections, setSelections] = useState();
  
  useEffect(() => {
    const myApi = new GlobalCrud("/account");
    const fetchSelectionData = async () => {
      const result = await myApi.getData();
      if(result.status === 200){
        setSelections(result.data);
      }
    };

    fetchSelectionData();
  }, []);

  const fields = (props) => {
    let avatar = {};
    if(props.initialValues && props.initialValues['avatar']){
      avatar.src = props.initialValues['avatar'];
      avatar.value = props.initialValues['avatar_name'];
    }
    let fields = [
      [
        [
          { 
            name : 'email', 
            label : 'Email',
            type : 'email', 
            required : true, 
            size : 6,
            value : props.initialValues && props.initialValues['email'] ? props.initialValues['email'] : ''
          },
          { 
            name : 'new_roles', 
            label : 'Roles',
            type : 'multiselect', 
            required : true, 
            size : 6,
            selections : props.selections ? props.selections['roles'] : [],
            value : props.initialValues && props.initialValues['roles'] ? props.initialValues['roles'] : []
          },
        ],
      ],
      [
        [
          { 
            name : 'nick_name', 
            label : 'Nick Name',
            type : 'text', 
            required : true,
            size : 4,
            value : props.initialValues && props.initialValues['nick_name'] ? props.initialValues['nick_name'] : ''
          },
          { 
            name : 'first_name', 
            label : 'First Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['first_name'] ? props.initialValues['first_name'] : ''
          },
          { 
            name : 'last_name', 
            label : 'Last Name',
            type : 'text', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['last_name'] ? props.initialValues['last_name'] : ''
          },
          { 
            name : 'address', 
            label : 'Address',
            type : 'textarea', 
            required : true, 
            size : 12,
            value : props.initialValues && props.initialValues['address'] ? props.initialValues['address'] : ''
          },
          { 
            name : 'country', 
            type : 'select', 
            size : 3,
            selections : props.selections ? props.selections['countries'] : [],
            value : props.initialValues && props.initialValues['country'] ? props.initialValues['country'] : ''
          },
          { 
            name : 'city', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['city'] ? props.initialValues['city'] : ''
          },
          { 
            name : 'state', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['state'] ? props.initialValues['state'] : ''
          },
          { 
            name : 'zipcode', 
            type : 'text', 
            size : 3,
            value : props.initialValues && props.initialValues['zipcode'] ? props.initialValues['zipcode'] : ''
          },
          { 
            name : 'personal_email', 
            label : 'Personal Email',
            type : 'email', 
            size : 3,
            value : props.initialValues && props.initialValues['personal_email'] ? props.initialValues['personal_email'] : ''
          },
          { 
            name : 'home_number', 
            label : 'Home Telephone',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : props.initialValues && props.initialValues['home_number'] ? props.initialValues['home_number'] : ''
          },
          { 
            name : 'mobile_number', 
            label : 'Mobile',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : props.initialValues && props.initialValues['mobile_number'] ? props.initialValues['mobile_number'] : ''
          },
          { 
            name : 'work_number', 
            label : 'Work Telephone',
            type : 'inputmask',
            mask : '999-999-9999', 
            size : 3,
            value : props.initialValues && props.initialValues['work_number'] ? props.initialValues['work_number'] : ''
          },
          { 
            name : 'birthdate', 
            label : 'Birthdate',
            type : 'date', 
            required : true, 
            size : 4,
            value : props.initialValues && props.initialValues['birthdate'] ? props.initialValues['birthdate'] : ''
          },
          { 
            name : 'gender', 
            label : 'Gender',
            type : 'radio', 
            required : true, 
            size : 4,
            selections : props.selections ? props.selections['genders'] : [],
            value : props.initialValues && props.initialValues['gender'] ? props.initialValues['gender'] : ''
          },
          { 
            name : 'maritalstatus', 
            label : 'Marital Status',
            type : 'select', 
            required : true, 
            size : 4,
            selections : props.selections ? props.selections['maritalstatuses'] : [],
            value : props.initialValues && props.initialValues['maritalstatus'] ? props.initialValues['maritalstatus'] : ''
          },
          { 
            name : 'avatar', 
            label : 'Avatar',
            type : 'file', 
            size : 4,
            value : avatar
          },
          { 
            name : 'is_active', 
            type : 'checkbox', 
            label : 'Status',
            required : false, 
            size : 4,
            value : props.initialValues && props.initialValues['is_active'] ? props.initialValues['is_active'] : false
          },
        ]
      ]
    ]
    return fields;
  }
  
  const list = (props) =>{
    return <List {...props}/>
  }

  const { path } = props.match;
  const { access } = props;

  return (
    <Switch>
      { selections &&
      <Route 
        path={path}
        children={<CrudPageX 
        List={list}
        path={path} 
        form_size='8'
        fields={fields}
        selections={selections}
        title='User Accounts'
        {...access}
        canDelete={false}
        />} 
      />}
    </Switch>
  )
}