import React from "react";
import QuickStatsChart from "./QuickStatsChart";
import QuickStatsBar from "./QuickStatsBar";

export default function TimesheetQuickStats({ data, field, title, color, border = 3, type = '', suffix = '', conversion='time', total_override = null}) {

  let xdata = [], ydata = [],
  total = 0;
  let divider = 1;
  if(conversion === 'time'){
    divider = 3600;
  }  

  if(Object.keys(data).length > 0){
    const data_keys = Object.keys(data);
    data_keys.sort();
    data_keys.map( (date) => {
      let v = 0;
      if(conversion === 'percent'){
        v = (data[date][field]/divider).toFixed(2);
      }else{
        v = Math.floor(data[date][field]/divider);
      }
      if(type === 'bar'){
        xdata.push(v);
      }else{
        xdata.push({
          x : date,
          y : v,
        });
      }

      ydata.push(date);

      total += data[date][field];
      return <></>;
    })
  }

  total = total/divider;
  
  if(total_override !== null){
    total = total_override;
  }

  if(type === 'bar'){
    return (
      <QuickStatsBar 
      value={total.toFixed(2) + suffix}
      desc={title}
      data={xdata}
      labels={ydata}
      color={color}
      border={border}
      />
    )
  }
  
  return (
    <QuickStatsChart 
    value={total.toFixed(2) + " HR"+(total > 1 ? 'S' : '')}
    desc={title}
    data={xdata}
    labels={ydata}
    color={color}
    border={border}
    />
  )

}