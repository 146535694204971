import React from "react";
import {
  Link
} from "react-router-dom";
import {Portlet, PortletHeader, PortletBody } from "../../../../partials/content/Portlet";
import { ListGroup } from "react-bootstrap";

export default function Events(props) {

  const { basePath, selected_event, events } = props;
  
  return (
    <>
      <div className='row'>
        <div className='col-xl-12 col-md-6'>
          <Portlet>
            <PortletHeader title="EVENTS" />
            <PortletBody>
              <div className="row">
                <div className="col-md-12 text-center">
                  <ListGroup >
                    { events.map((d, index) => {
                      return (
                        <Link key={index} to={{pathname:basePath+'/'+d.id, state : { title : 'Event', description : d.title } }} className={selected_event && selected_event.id === d.id ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}>
                          {d.title}
                        </Link>
                      )
                    })}
                  </ListGroup>
                </div>
              </div>
            </PortletBody>
          </Portlet>
        </div>
    </div>
    </>
  )
}