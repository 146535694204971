import React from "react";
import { withRouter } from "react-router-dom";
import EmployeeRequests from "../../hr/employee/requests";

function Requests(props) {

  const { access, ...others } = props;
  let new_access = {
    ...access,
    isAdmin : false,
  };
  
  return (
    <EmployeeRequests 
    access={new_access}
    {...others}
    path={props.match.path}
    baseUrl='hr/my/timekeeping/requests'
    />
  )
}
export default withRouter(Requests);