const API_PATH = String(process.env.REACT_APP_API_ENDPOINT + 'ws/app').replace('https', 'wss').replace('http', 'ws');
//console.log(process.env.REACT_APP_API_ENDPOINT, API_PATH);
class WebSocketService{
    static instance = null; 
    callbacks = {};
    
    static getInstance(){
        if (!WebSocketService.instance){
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    constructor(){
        this.socketRef = null;
        this.token = '';
        this.onMessage = null;
    }

    connect(token, onMessage){
        this.token = token;
        this.onMessage = onMessage;

        const path = API_PATH;
        this.socketRef = new WebSocket(path);
        
        this.socketRef.onmessage = e => {
          this.onMessage(e.data);
        };

        this.socketRef.onopen = () => {
            //console.log("WebSocket open");
            //register token
            this.register();
        };
        
        this.socketRef.onerror = e => {
            console.log(e.message);
        };

        this.socketRef.onclose = e => {
            if(e.code === 1011 || e.code === 1006){
              //do not reconnect
              console.log("WebSocket closed, stopping..", e.code);
            }else{
              console.log("WebSocket closed, restarting..", e.code);
              this.connect(this.token, this.onMessage);
            }
        };   
    }

    disconnect(){
      if(this.socketRef){
        this.socketRef.close(); 
      }
    }

    register(){
        this.sendMessage({command : 'register', token : this.token});
    }

    sendMessage(data){
        try{
            //console.log({...data})
            this.socketRef.send(JSON.stringify({...data}))
        }
        catch(err){
            console.log(err.message);
        }
    }
    state(){
        return this.socketRef.readyState;
    }
    waitForSocketConnection(callback){
        const socket = this.socketRef;
        const recursion = this.waitForSocketConnection;
        setTimeout(
            function(){
                if(socket.readyState === 1){
                    console.log("Connection is made");
                    if(callback != null){
                        callback();
                    }
                    return;
                }
                else{
                    console.log("Wait for connection..");
                    recursion(callback);
                }
            }, 1);
    }
}

let WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
