import React from "react";
import { Form } from "react-bootstrap";
import ModalX from './modalx';

class BaseCrud extends React.Component {
  constructor(props) {
    super(props);

    this.post_status_fields = [];
    this.state = {
      data : [],
      action_loading : false,
      data_loading : true,
      modalShow : false,
      modalTitle : '',
      modalBody : null,
      modalSubmit : null,
      modalSubmitTitle : '',
      modalSubmitVariant : '',
    };

    this.renderModal = this.renderModal.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  updatePage() {
    const { crudUrl, apiPath, updateParent } = this.props;
    crudUrl.getRecord(apiPath).then(({ data }) => {
      this.setState({
        data : data,
        data_loading : false,
      }, function(){
        if(updateParent){
          updateParent(this.state.data);
        }
      });
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        data : [],
        data_loading : false,
      }, function(){
        if(updateParent){
          updateParent([]);
        }
      });
    });
  }

  componentDidMount(){
    this.updatePage();
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    this.setState({ action_loading : true });

    form.classList.add('was-validated');
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {  
      //not valid
      this.setState({ action_loading: false });
    }else{
      const data = new FormData(form);
      const { crudUrl, apiPath, updateParent } = this.props;
      //create
      crudUrl.postRecord(apiPath, data)
      .then(( {data} ) => {
        let newData = [], doNotUpdate = false;
        this.post_status_fields.map( f => {
          doNotUpdate = (data[f] !== undefined && data[f] === false);
          return <></>;
        });
        if(this.state.data.some(item => data.id === item.id)){
          //update
          newData = [...this.state.data];  
          let index = newData.findIndex(d => d.id === data.id);
          if(doNotUpdate){
            newData.splice(index, 1);
          }else{
            newData[index] = data;
          }
        }else{
          newData = this.state.data;
          if(!doNotUpdate){
            newData = [...newData, data];
          }
        }
        //this.updatePage();
        this.setState({ 
          action_loading : false,
          modalShow : false,
          data: newData
        }, function(){
          if(updateParent){
            updateParent(this.state.data);
          }
        });
      })
      .catch(error => {
        let msg = [];
        if(error.status === 404){
          msg.push("Error 404");
        }else{
          Object.keys(error.data).map( (da, i) => {
            error.data[da].map( (d, i) => {
              msg.push(da.toUpperCase()+' : ' + d);
              return <></>;
            });
            return <></>;
          });
        }
        alert(msg.join("\r\n"));
        this.setState({ 
          action_loading : false,
          //modalShow : false
        });
      });
    }
  }

  executeDelete(d, e){
    this.setState({ action_loading : true });
    const { crudUrl, apiPath, updateParent } = this.props;
    let id = d['id'];
    crudUrl.deleteRecord(apiPath, id).then(( {data} ) => {

      let newData = this.state.data.filter((d) => d.id !== id);
      //console.log(newData);
      //this.updatePage();
      this.setState({
        action_loading : false,
        modalShow : false,
        data : newData,
      }, function(){
        if(updateParent){
          updateParent(newData);
        }
      });
    })
    .catch(error => {
      let msg = [];
      Object.keys(error.data).map( (da, i) => error.data[da].map( (d, i) => msg.push(da.toUpperCase()+' : ' + d)));
      alert(msg.join("\r\n"));
      this.setState({ 
        action_loading : false,
        //modalShow : false
      });
    });
  }

  showform(d, e){
    let f = (<Form
      noValidate
      onSubmit={e => this.handleSubmit(e)}
      validated={false}
      id="modal_form"
    >
    {this.form(d)}
    </Form>);
    
    this.setState({ 
      modalShow: true, 
      modalTitle : (d ? 'Edit ' : 'Add ' ) + this.title, 
      modalBody : f,
      modalSubmitTitle : 'Submit',
      modalSubmitVariant : 'primary',
      modalSubmit : 'modal_form',
    })
  }

  showdelete(d, e){

    this.setState({ 
      modalShow: true,
      modalTitle : 'Confirmation', 
      modalBody : 'Are you sure you want to delete this ' + this.title + '?',
      modalSubmitTitle : 'Delete',
      modalSubmitVariant : 'danger',
      modalSubmit : this.executeDelete.bind(this, d),
    })
  }

  modalClose(){
    this.setState({ modalShow: false });
  }

  renderModal(){
    return <ModalX
      show={this.state.modalShow}
      title={this.state.modalTitle}
      onHide={this.modalClose}
      body={ this.state.modalBody }
      submit={ this.state.modalSubmit }
      submit_title={ this.state.modalSubmitTitle }
      submit_variant={ this.state.modalSubmitVariant }
      action_loading={this.state.action_loading}
    />
  }
}

// Set default props
BaseCrud.defaultProps = {
  id: 0,
  apiPath : '',
  crudUrl : null,
  updateParent : null,
};

export default BaseCrud;