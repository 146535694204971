import React from "react";
import {
  Link,
} from "react-router-dom";

import { withRouter } from "react-router-dom";

import Listx from "../../../../../components/listx";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'name', label: 'Group' },
      { id: 'description', label: 'Description', sort : false },
      { id: 'default', label: 'Default' },
    ];

  }

  renderRowData(data){
    return [
      this.renderCell(data.name),
      this.renderCell(data.description),
      this.renderCell(data.default ? 'Yes' : ''),
      this.renderCell(
        <>
        <span className='pr-2'>
        <Link to={'/hr/settings/shifts/mg/'+data.id+'/schedules'} className="btn btn-outline-brand btn-icon btn-xs" title="Manage Schedule">
          <i className="flaticon2-calendar-9"></i>
        </Link>
        <span className='pr-2'>
        </span>
        <Link to={'/hr/settings/shifts/mg/'+data.id+'/breaks'} className="btn btn-outline-brand btn-icon btn-xs" title="Manage Breaks">
          <i className="flaticon-tea-cup"></i>
        </Link>
        </span>
        {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

export default withRouter(List);