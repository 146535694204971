import React from "react";

import { Badge } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Listx from "../../../../../components/listx";
import * as page from "../../../../../store/ducks/page.duck";

class List extends Listx {

  constructor(props) {
    super(props);

    this.headings = [
      { id: '#', label: '#' },
      { id: 'name', label: 'Employee' },
      { id: 'type', label: 'Type' },
      { id: 'coverage', label: 'Coverage' },
      { id: 'daus', label: 'Days' },
      { id: 'status', label: 'Status' },
      { id: 'created', label: 'Date/Time' },
    ];
    
    this.setFilterBar(this.filter);

  }

  filter(props, handlers){
    return [
      [
        [
          { 
            name : 'from_date', 
            label : 'From Date',
            type : 'date', 
            size : 2,
            value : props.search && props.search['from_date'] ? props.search['from_date'] : '',
            onChange : handlers.handleDateTimeChange
          },
          { 
            name : 'to_date', 
            label : 'To Date',
            type : 'date', 
            size : 2,
            value : props.search && props.search['to_date'] ? props.search['to_date'] : '',
            onChange : handlers.handleDateTimeChange
          },
          { 
            name : 'request', 
            label : 'Type',
            type : 'select', 
            size : 2,
            idField: 'id',
            valueField: 'name',
            selections : props.selections ? props.selections['request_types'] : [],
            value : props.search && props.search['request'] ? props.search['request'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'employee', 
            label : 'Employee',
            type : 'select', 
            size : 2,
            idField: 'id',
            valueField: 'name',
            selections : props.selections ? props.selections['employees'] : [],
            value : props.search && props.search['employee'] ? props.search['employee'] : [],
            onChange : handlers.handleSelectChange,
            ignore : !props.isAdmin,
          },
          { 
            name : 'status', 
            label : 'Status',
            type : 'select', 
            size : 2,
            idField: 'key',
            valueField: 'value',
            selections : props.selections ? props.selections['request_status'] : [],
            value : props.search && props.search['status'] ? props.search['status'] : [],
            onChange : handlers.handleSelectChange
          },
          { 
            name : 'submit', 
            label : 'Search',
            type : 'button',
            icon : 'flaticon-search',
            variant : 'secondary',
            className : 'btn-sm',
            containerStyle : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            size : 1,
          },
        ],
      ],
    ];
  }

  renderRowData(data){
    let status = 'success';
    switch(data.status){
      case 'denied':
        status = 'danger';
        break;
      case 'approved':
        status = 'success';
        break;
      default:
        status = 'warning';
    }

    return [
      this.renderCell(data.employee_info.name),
      this.renderCell(data.request_info.name),
      this.renderCell(
        <>
        {data.start} - {data.end}
        {data.note ? 
        (<><br/><strong>Note : </strong>{data.note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(data.days),
      this.renderCell(
        <>
        <Badge variant={status}>
          {data.statusText}
        </Badge>
        {data.admin_note ? 
        (<><br/><strong>Admin Note : </strong>{data.admin_note}</>) : (<></>)
        }
        </>
      ),
      this.renderCell(data.created),
      this.renderCell(
        <>
        {this.defaultActions(data.id)}
        </>
      , null, true),
    ];
  }

}

// Set default props
List.defaultProps = {
  isAdmin: true,
  canUpdate: true,
  canDelete: true,
  canCreate : true,
  CustomFilter : null,
};

const mapStateToProps = (state) => {
  return {
    module: state.page.module,
    redraw: state.page.reload,
  }
}

export default withRouter(connect(
  mapStateToProps,
  page.actions
)(List));