
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import SubdirectoryArrowRight from '@material-ui/icons/SubdirectoryArrowRight';
import {Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "../../partials/content/Portlet";
import {
  Link,
} from "react-router-dom";
import ButtonX from '../buttonx';
import { capitalize } from '../../../_metronic/utils/utils';

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headings, showRowCheckbox, hasActions } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        { showRowCheckbox &&
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'Select all desserts' }}
            />
          </TableCell>
        }
        {headings.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding !== false ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            { row.sort !== false && row.id !== '#' ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label ? row.label : capitalize(row.id)}
              </TableSortLabel>
            ):(
              <>{row.label ? row.label : capitalize(row.id)}</>
            )}
          </TableCell>
        ))}
        { hasActions &&
          <TableCell align='right'>Actions</TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = props => {
  const { numSelected, baseUrl, canCreate, backLink, title, onNewClick } = props;

  let t = title ? ( typeof title === 'object' ? (<>{title['name']} {title['description'] ? <small>{title['description']}</small> : ''}</>) : title) : 'Records';
  if(numSelected > 0){
    t = numSelected + ' selected';
  }
  return (
    <PortletHeader title={ t } toolbar={canCreate &&
      <PortletHeaderToolbar>
        {numSelected > 0 ? (
          <ButtonX text="Delete" icon="flaticon-delete" className="btn btn-danger btn-sm ng-star-inserted" title={'Delete '+t}/>
        ) : (
          <>
          { backLink &&
            <>
            <Link
              to={backLink}
              className="btn btn-sm btn-outline-dark">
              <i className="fa fa-arrow-left" /> Back
            </Link>
            &nbsp;
            </>
          }
          { onNewClick ? (
              <ButtonX 
                className="btn btn-sm btn-primary"
                text="New"
                onClick={onNewClick}
                icon="fa fa-plus"
              />
          ) : (
            <Link
              to={baseUrl+"/create"}
              className="btn btn-primary btn-sm ng-star-inserted">
              <i className="fa fa-plus" /> New
            </Link>
          )}
          </>
        )}
      </PortletHeaderToolbar>}
    />
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

const ExpandableRow = props => {
  const [open, setOpen] = React.useState(false)
  const { isItemSelected, showRowCheckbox, labelId, index, row, base, has_no } = props;

  return (
    <>
      <TableRow key={'trow_'+index} hover>
        { showRowCheckbox &&
        <TableCell padding="checkbox">
          <Checkbox
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        }
        { has_no &&
          <TableCell key={'tdrow_#'} component="th" id={labelId} scope="row" align={row.sub_rows && row.sub_rows.length > 0 ? 'left' : 'inherit'} padding={row.sub_rows && row.sub_rows.length > 0 ? 'none' : 'normal'} >
            { row.sub_rows &&
              <>
              { row.sub_rows.length > 0 &&
                <>
                {open ? (
                  <KeyboardArrowDown />
                ):(
                  <KeyboardArrowRight />
                )}
                </>
              }
              </>
            }
            {index + 1 + base}
          </TableCell>
        }
        { props.renderRowData &&
          props.renderRowData(row).map((c, ci) => {
            if(c.isAction){
              return <TableCell key={'tdrow_'+ci} {...c.props} align="right" >{c.content}</TableCell>
            }
            
            return (
              <TableCell key={'tdrow_'+ci} {...c.props} style={{padding:5}}>
                { ci === 0 && !has_no ? (
                  <>
                  { row.sub_rows && row.sub_rows.length > 0 ? (
                    <>
                      {open ? (
                        <KeyboardArrowDown style={{ cursor:'pointer' }} onClick={row.sub_rows ? () => { setOpen(!open) } : null}/>
                      ):(
                        <KeyboardArrowRight style={{ cursor:'pointer' }} onClick={row.sub_rows ? () => { setOpen(!open) } : null}/>
                      )}
                      {c.content}
                    </>
                  ) : (
                    <>{c.content}</>
                  )}
                  </>
                ) : (
                  <>{c.content}</>
                )}
              </TableCell>
            )
          })
        }
      </TableRow>

      {row.sub_rows &&
        <>
        {row.sub_rows.map((sub_row, index) => {
        return (
            <TableRow key={'trow_sub_'+index} style={{display : (open ? 'table-row' : 'none')}}>
            { props.renderSubRowData &&
              <>
              {props.renderSubRowData(sub_row).map((c, ci) => {
                if(c.isAction){
                  return <TableCell key={'tdrow_sub_'+ci} {...c.props} align="right" >{c.content}</TableCell>
                }
                return <TableCell key={'tdrow_sub_'+ci} {...c.props} style={{padding:5}}>{c.content}</TableCell>
              })}
              </>
            }
            </TableRow>
        )
        })}
        </>
      }

      {row.related_rows &&
        <>
        {row.related_rows.map((sub_row, index) => {
        return (
            <TableRow key={'trow_related_'+index}>
            { props.renderRelatedRowData &&
              <>
              {props.renderRelatedRowData(sub_row).map((c, ci) => {
                if(c.isAction){
                  return <TableCell key={'tdrow_related_'+ci} {...c.props} align="right" >{c.content}</TableCell>
                }
                return (
                  <TableCell key={'tdrow_related_'+ci} {...c.props} style={{padding:5}}>
                    { ci === 0 && 
                      <SubdirectoryArrowRight></SubdirectoryArrowRight>
                    }
                    {c.content}
                  </TableCell>
                )
              })}
              </>
            }
            </TableRow>
        )
        })}
        </>
      }
    </>
  );
}


export default function DataTable(props) {
  const {headings, CustomFilter, loading, pagination, totals, rows, showRowCheckbox, onSortChanged, orderField, orderDirection, renderTotalRow } = props;

  let _rp = parseInt(props.per_page);
  if(isNaN(_rp)){
    _rp = pagination.per_page;
  }
  const _p = props.page - 1;
  const _dir = orderDirection ? orderDirection : 'desc';
  const _dirfield = orderField ? orderField : '';

  const classes = useStyles();
  const [order, setOrder] = React.useState(_dir);
  const [orderBy, setOrderBy] = React.useState(_dirfield);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(_p);
  const [rowsPerPage, setRowsPerPage] = React.useState(_rp);

  useEffect(() => {
    // action on update of movies
    setOrder(_dir);
    setOrderBy(_dirfield);
    setPage(_p);
    setRowsPerPage(_rp);
  }, [_rp, _p, _dir, _dirfield]);
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);

    if(onSortChanged){
      onSortChanged({ orderby : property, orderdir : isDesc ? 'asc' : 'desc'});
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    //setPage(newPage);
    
    const { onPageChanged } = props;
    if(onPageChanged){
      onPageChanged(newPage+1, rowsPerPage);
    }
  }

  function handleChangeRowsPerPage(event) {
    //setRowsPerPage(+event.target.value);

    const { onPageChanged } = props;
    if(onPageChanged){
      onPageChanged(page+1, +event.target.value);
    }
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  const base = (rowsPerPage * page);
  const has_no = headings.find((heading) => heading.id === '#');
  const has_actions = (rows.length > 0 ? props.renderRowData(rows[0]).length > (headings.length - (has_no ? 1 : 0)) : false);
  const total_count = pagination && pagination.total_count ? pagination.total_count : 0;
  
  const totalRows = renderTotalRow(totals);
  //console.log('reloading datatable');
  return (
    <Portlet>
      <EnhancedTableToolbar numSelected={selected.length} {...props} />
      <PortletBody>
      <>
          { CustomFilter && 
            <CustomFilter />
          }
          <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            //size={dense ? 'small' : 'medium'}
            size={'small'}
          >
            <EnhancedTableHead
              hasActions={has_actions}
              showRowCheckbox={showRowCheckbox}
              headings={headings}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return <ExpandableRow 
                  key={row.name+'_'+index}
                  isItemSelected={isItemSelected} 
                  labelId={labelId} 
                  row={row}
                  index={index}
                  onClick={showRowCheckbox ? event => handleClick(event, row.name) : null}
                  role={showRowCheckbox ? "checkbox" : ''}
                  tabIndex={-1}
                  has_no={has_no}
                  base={base}
                  renderRowData={props.renderRowData}
                  renderSubRowData={props.renderSubRowData}
                  renderRelatedRowData={props.renderRelatedRowData}
                />
                // return (
                //   <TableRow
                //     hover
                //     onClick={showRowCheckbox ? event => handleClick(event, row.name) : null}
                //     role="checkbox"
                //     aria-checked={isItemSelected}
                //     tabIndex={-1}
                //     key={row.name+'_'+index}
                //     selected={isItemSelected}
                //   >
                //     { showRowCheckbox &&
                //       <TableCell padding="checkbox">
                //         <Checkbox
                //             checked={isItemSelected}
                //             inputProps={{ 'aria-labelledby': labelId }}
                //           />
                //         </TableCell>
                //       }
                //       { has_no &&
                //         <TableCell key={'trow_#'} component="th" id={labelId} scope="row" >{index + 1 + base}</TableCell>
                //       }
                //       { props.renderRowData &&
                //         props.renderRowData(row).map((c, ci) => {
                //           if(c.isAction){
                //             return <TableCell key={'trow_'+ci} {...c.props} align="right" >{c.content}</TableCell>
                //           }
                //           return <TableCell key={'trow_'+ci} {...c.props} style={{padding:5}}>{c.content}</TableCell>
                //         })
                //       }
                //     </TableRow>
                //   );
              })}
              { loading && 
                <>
                {[...Array(rowsPerPage)].map((e, i) => {
                  return (
                    <TableRow key={i} >
                    {headings.map((col, j) => (
                      <TableCell style={{padding:13}} key={'empty_'+j}>
                        <p style={{background: '#eeeeee', height: 18, marginBottom : 0}}></p>
                      </TableCell>
                    ))}
                    </TableRow>
                  )
                })}
                </>
              }
              { totalRows &&
                <>
                {totalRows.map((tr, i) => (
                  <TableRow key={"ttrow_"+i}>
                    {tr.map((t, ti) => (
                      <TableCell key={'ttcell_'+ti} {...t.props} style={{padding:5}}>{t.content}</TableCell>
                    ))}
                  </TableRow>
                ))}
                </> 
              }
            </TableBody>
          </Table>
          </div>
          { !loading &&
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={total_count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          }
          </>
        </PortletBody>
      </Portlet>
  );
}
