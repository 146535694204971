
import React from "react";
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  inputroot:{
    marginTop : '8px !important',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: '1rem',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: '1rem',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        classes: { root : classes.inputroot},
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export default function SelectX(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [options, setOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const [hiddenvalue, setHiddenvalue] = React.useState(props.value || "");

  
  const filterOptions = (inputValue) => {
    return options.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    ).slice(0, 10);
  };

  const promiseOptions = (inputValue) => {
    return new Promise(resolve => {
      resolve(filterOptions(inputValue));
    });
  }
    
  const onChange = (value, actionMeta) => {
    props.onChange(value, actionMeta);
    setSelectedValue(value);
    setHiddenvalue(value);
  };

  const getValue = () => {
    return hiddenvalue || "";
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  const { 
    defaultOption,
    required,
    selections,
    idField,
    valueField,
    name, 
    value,
    label,
    error,
    helperText,
    isDisabled,
    apiOptions,
  } = props;

  const enableRequired = !isDisabled;

  React.useEffect(() => {

    let opts = [];
    if(selections){
      let vfield = valueField;
      if(vfield.includes(".")){
        vfield = vfield.split(".");
      }
      selections.map( (option, index) => {
        let v = "N/A";
        if(Array.isArray(vfield)){
          v = option[vfield[0]][vfield[1]];
        }else{
          v = option[vfield];
        }
        let opt = { label : v, value : option[idField]};

        if( value.toString() === option[idField].toString()){
          setSelectedValue(opt);
          setHiddenvalue(opt);
        }
        
        opts.push(opt)
        return <></>;
      });
      
    }
    setOptions(opts);
  }, [selections, idField, value, valueField]);

  return (
    <div className={classes.root}>
      <NoSsr>
        <AsyncSelect 
          cacheOptions
          loadOptions={apiOptions ? apiOptions : promiseOptions}
          classes={classes}
          styles={selectStyles}
          components={components}
          name={name}
          required={required}
          value={selectedValue}
          onChange={onChange}
          defaultOptions={options.slice(0, 20)}
          isClearable={true}
          inputId={'react-select-'+name}
          isDisabled={isDisabled}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: 'react-select-'+name,
              shrink: true,
            },
            placeholder: 'Select multiple countries',
          }}
        >
        <option key="empty_option" value="">{defaultOption}</option>
        
        </AsyncSelect>
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: "100%",
              height: 0,
              position: "absolute"
            }}
            value={getValue()}
            required={required}
            onChange={() => {
              // no operation (do nothing real quick)
            }}
          />
        )}
        { error && 
        <FormHelperText error={error}>{helperText}</FormHelperText>
        }
        </NoSsr>
    </div>
  );
}
// Set default props
// SelectX.defaultProps = {
//   defaultOption : 'Please Select',
//   required : false,
//   required_text : 'Required',
//   defaultValue : '',
//   selections : [],
//   idField : '',
//   valueField : '',
//   name : '',
//   onChange : null,
// };